var treeData = {
    parentId: 0,
    name: 'Arustu Tech',
    exportValue: 'AT',
    showChildren: true,
    editMode: false,
    children: [
        {
            parentId: 1,
            name: 'React',
            exportValue: '1',
            showChildren: true,
            editMode: false,
            children: [
                {
                    parentId: 2,
                    name: 'Banti',
                    exportValue: '101',
                    showChildren: false,
                    editMode: false,
                    children: []
                },
                {
                    parentId: 3,
                    name: 'Bajrang ji',
                    exportValue: '102',
                    showChildren: true,
                    editMode: false,
                    children: [
                        {
                            parentId: 4,
                            name: 'metali',
                            exportValue: '20201',
                            showChildren: false,
                            editMode: false,
                            children: []
                        }
                    ]
                },
                {
                    parentId: 5,
                    name: 'Dinesh',
                    exportValue: '103',
                    showChildren: false,
                    editMode: false,
                    children: []
                },
                {
                    parentId: 6,
                    name: '.Net',
                    exportValue: '204',
                    showChildren: true,
                    editMode: false,
                    children: [
                        {
                            parentId: 7,
                            name: 'Lokesh',
                            exportValue: '20401',
                            showChildren: true,
                            editMode: false,
                            children: [
                                {
                                    parentId: 8,
                                    name: 'Manish',
                                    exportValue: '2040101',
                                    showChildren: false,
                                    editMode: false,
                                    children: []
                                }
                            ]
                        },
                        {
                            parentId: 9,
                            name: 'Charul',
                            exportValue: '12098',
                            showChildren: false,
                            editMode: false,
                            children: []
                        }
                    ]
                },
                {
                    parentId: 10,
                    name: 'Kishan',
                    exportValue: '205',
                    showChildren: false,
                    editMode: false,
                    children: []
                }
            ]
        },
        {
            parentId: 11,
            name: 'Manish',
            exportValue: '3',
            showChildren: false,
            editMode: false,
            children: []
        }
    ]
}

export default treeData;