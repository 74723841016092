export const phoneTypes = [
  {
    value: 1,
    label: "Home",
  },
  {
    value: 2,
    label: "Office",
  },
  {
    value: 3,
    label: "Business",
  },
  {
    value: 4,
    label: "Cellular",
  },
  {
    value: 5,
    label: "Fax",
  },
];

export const alarmLevelData = [
  {
    value: 1,
    label: "Test 1",
  },
  {
    value: 2,
    label: "Test 2",
  }
];

export const priorityCodeData = [
  {
    value: 1,
    label: "Test 1",
  },
  {
    value: 2,
    label: "Test 2",
  }
];

export const zoneDropDownData = [
  {
    value: 1,
    label: "Zone 1",
  },
  {
    value: 2,
    label: "Zone 2",
  },
  {
    value: 3,
    label: "Zone 3",
  },
  {
    value: 4,
    label: "Zone 4",
  }
];
