import React from 'react'
import { Link } from 'react-router-dom'


const MobileDashboard = () => {

  return (
  
      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="card">
              <div className="main-dashboard col-12 mb-2">
                <div className="box-container-incident ">
                  <div className="box-Incident">
                    <Link to={'/incident-main'}>
                      <i className="fa fa-stack-exchange size-fa-32px" aria-hidden="true"></i>
                      <p>My Incident</p>
                    </Link>
                  </div>
                  <div className="box-Incident">
                    <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                    <p>Approved Reports</p>
                  </div>
                  <div className="box-Incident">
                    <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                    <p>Reject Reports</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    
  )
}

export default MobileDashboard