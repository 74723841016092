import React, { useContext, useEffect, useState } from 'react'
import { tableCustomStyles } from '../../../Common/Utility'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import MobileTab from '../../MobileUtility/MobileTab'
import { fetchPostData } from '../../../hooks/Api'
import { AgencyContext } from '../../../../Context/Agency/Index'

const MobileName = () => {

    const { updateCount, setUpdateCount } = useContext(AgencyContext)

    const [effectiveScreenPermission, setEffectiveScreenPermission] = useState([]);
    const [nameDataFRW, setNameDataFRW] = useState([]);

    useEffect(() => {
        get_Incident_List();
    }, []);

    const get_Incident_List = () => {
        const val = {
            'IncidentIDFRW': '',
        }
        fetchPostData('Name_FRW/GetData_Name_FRW', val).then((res) => {
            if (res) {
                setNameDataFRW(res);
            }
            else { setNameDataFRW(); }
        })
    }

    const columns = [
        {
            name: 'Action',
            cell: row => <>
                <div className="div">
                    <Link to={'/mobile-name'} onClick={() => { set_FRW_IncidentId(row) }} className="btn btn-sm bg-green text-white" data-toggle="modal" data-target="" >
                        <i className="fa fa-edit"></i>
                    </Link>
                </div>
            </>,
            grow: 0,
        },
        {
            name: 'LastName',
            selector: (row) => row.LastName,
            sortable: true
        },
        {
            name: 'SSN',
            selector: (row) => row.SSN,
            sortable: true
        },
        {
            name: 'Address',
            selector: (row) => <>{row?.Address ? row?.Address.substring(0, 50) : ''}{row?.Address?.length > 40 ? '  . . .' : null} </>,
            sortable: true
        },
    ]

    const set_FRW_IncidentId = (row) => {
        console.log(row)
        setUpdateCount(updateCount + 1)
    }

    return (
        <>
            <div className="section-body view_page_design ">
                <div className="row clearfix">
                    <div className="col-12 col-sm-12 px-2">
                        <div className="card Agency " style={{ borderRight: 'none', borderLeft: 'none', borderRadius: '0' }}>
                            <div className="card-body ">
                                <div className="row  ">
                                    <div className={`col-12 col-md-12`}>
                                        <div className="row">
                                            <div className="col-12  mobile__tabs" style={{ marginTop: '-18px', marginBottom: '-20px' }}>
                                                <MobileTab />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 px-3">

                        <div className="bg-line text-white py-1  px-0 d-flex justify-content-between align-items-center" >
                            <p className="p-0 m-0 pl-3 py-1 col-4" style={{ fontSize: '18px', }}>
                                Name
                            </p>
                            <p className="p-0 m-0">
                                <Link to={'/mobile-name'} className="btn btn-sm bg-green text-white px-2 py-0 mr-2" style={{ fontSize: '18px' }}>
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </p>
                        </div>
                        <div className="col-12 mt-2 px-3" >
                            <DataTable
                                columns={columns}
                                data={nameDataFRW}
                                dense
                                pagination
                                paginationPerPage={'5'}
                                paginationRowsPerPageOptions={[5]}
                                highlightOnHover
                                responsive
                                customStyles={tableCustomStyles}
                                className='mobile-datatable'
                                showPaginationBottom={5}
                                subHeaderComponent={
                                    <>
                                    </>
                                }
                                subHeaderAlign='left'
                                noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* </div>
            </div> */}
        </>
    )
}

export default MobileName