export const Unique_ID = "Unique_ID";

export const Local_Store_Data = "Local_Store_Data";
export const Incident_ReportDate = "Incident_ReportDate";
export const Incident_ID = "Incident_ID";
export const Incident_Number = "Incident_Number";
export const Screen_Permissions = "Screen_Permissions";










//----------------------------------------Property-----------------------------------
export const Master_Property_Status = "Master_Property_Status";
export const Property_ID = "Property_ID";
export const MasterProperty_ID = "MasterProperty_ID";
export const PropertyType_Data = "PropertyType_Data";
export const PropertyMainModule_Data = "PropertyMainModule_Data";
export const Property_Search_Data = "Property_Search_Data";
export const PropertySearch_Type = "PropertySearch_Type";
export const PropertySearch_Modal_Status = "PropertySearch_Modal_Status";
















//----------------------------------------Name---------------------------------------
export const Name_Update_Status = "Name_Update_Status";
export const NameType_Data = "NameType_Data";

















//----------------------------------------Vehicle-----------------------------------
export const Vehicle_ID = "Vehicle_ID";
export const MasterVehicle_ID = "MasterVehicle_ID";
























//----------------------------------------Name----------------------------
export const Name_Status = "Name_Status";







//----------------------------MissingPerson--------------------------

export const Missing_Person_All_Data = "Missing_Person_All_Data";

export const Arrestee_NameMissing_DrpData = "Arrestee_NameMissing_DrpData";
export const Arrestee_Vehicle_DrpData = "Arrestee_Vehicle_DrpData";










//----------------------------------------DropDownData----------------------------
export const Agency_OfficerDrp_Data = "Agency_OfficerDrp_Data";
export const DL_State_DrpData = "DL_State_DrpData";
export const State_DrpData = "State_DrpData";
export const Arrestee_Name_DrpData = "Arrestee_Name_DrpData";
export const Property_LossCode_Drp_Data = "Property_LossCode_Drp_Data";
export const Color_Drp_Data = "Color_Drp_Data";
export const Material_Drp_Data = "Material_Drp_Data";
export const Make_Drp_Data = "Make_Drp_Data";
export const Propulusion_Drp_Data = "Propulusion_Drp_Data";
export const WeaponMake_Drp_Data = "WeaponMake_Drp_Data";
export const DrugManufactured_Drp_Data = "DrugManufactured_Drp_Data";
export const TypeMarijuana_Drp_Data = "TypeMarijuana_Drp_Data";
export const MeasureType_Drp_Data = "MeasureType_Drp_Data";
export const SuspectedDrug_Drp_Data = "SuspectedDrug_Drp_Data";
export const WeaponModel_Drp_Data = "WeaponModel_Drp_Data";
export const BoatModel_Drp_Data = "BoatModel_Drp_Data";
export const VOD_Drp_Data = "VOD_Drp_Data";
export const PropertySourceDrug_Drp_Data = "PropertySourceDrug_Drp_Data";
export const Country_Drp_Data = "Country_Drp_Data";
export const States_Drp_Data = "States_Drp_Data";
export const DL_States_Drp_Data = "DL_States_Drp_Data";
export const City_Drp_Data = "City_Drp_Data";
export const HowVerify_Drp_Data = "HowVerify_Drp_Data";
export const Resident_Drp_Data = "Resident_Drp_Data";
export const Marital_Drp_Data = "Marital_Drp_Data";
export const Face_Color_Drp_Data = "Face_Color_Drp_Data";
export const ComplexionType_Drp_Data = "ComplexionType_Drp_Data";
export const Hair_Style_Drp_Data = "Hair_Style_Drp_Data";
export const Facial_Hair_Type_Drp_Data = "Facial_Hair_Type_Drp_Data";
export const NameDistinct_Features_Drp_Data = "NameDistinct_Features_Drp_Data";
export const Hair_Length_Drp_Data = "Hair_Length_Drp_Data";
export const FacialHair_Drp_Data = "FacialHair_Drp_Data";
export const Hair_Shades_Drp_Data = "Hair_Shades_Drp_Data";
export const Facial_Oddity_Drp_Data = "Facial_Oddity_Drp_Data";
export const Body_Build_Drp_Data = "Body_Build_Drp_Data";
export const Speach_Codes_Drp_Data = "Speach_Codes_Drp_Data";
export const Teeth_Codes_Drp_Data = "Teeth_Codes_Drp_Data";
export const Glasses_Type_Drp_Data = "Glasses_Type_Drp_Data";
export const Name_Handedness_Drp_Data = "Name_Handedness_Drp_Data";
export const Contact_Type_Drp_Data = "Contact_Type_Drp_Data";
export const Masters_Name_Drp_Data = "Masters_Name_Drp_Data";
export const Message_Key_Drp_Data = "Message_Key_Drp_Data";
export const Missing_Person_Drp_Data = "Missing_Person_Drp_Data";
export const Ever_DonatedBlood_Drp_Data = "Ever_DonatedBlood_Drp_Data";
export const Circumstances_Drp_Data = "Circumstances_Drp_Data";
export const Body_XRay_Drp_Data = "Body_XRay_Drp_Data";
export const Corrected_Vision_Drp_Data = "Corrected_Vision_Drp_Data";
export const Fingerprinted_Drp_Data = "Fingerprinted_Drp_Data";
export const Missing_CMC_Drp_Data = "Missing_CMC_Drp_Data";
export const Blood_Type_Drp_Data = "Blood_Type_Drp_Data";
export const Circumcision_Drp_Data = "Circumcision_Drp_Data";
export const Jwellery_Drp_Data = 'Jwellery_Drp_Data';
export const PictureType_Drp_Data = 'PictureType_Drp_Data';
export const ImageView_Type_Drp_Data = 'ImageView_Type_Drp_Data';
export const Bottom_Color_Drp_Data = 'Bottom_Color_Drp_Data';
export const Top_Color_Drp_Data = 'Top_Color_Drp_Data';
export const Eye_Color_Drp_Data = 'Eye_Color_Drp_Data';
export const Hair_Color_Drp_Data = 'Hair_Color_Drp_Data';
export const IsPrimary_Color_Drp_Data = 'IsPrimary_Color_Drp_Data';
export const IsSecondary_Color_Drp_Data = 'IsSecondary_Color_Drp_Data';
export const Alert_Drp_Data = 'Alert_Drp_Data';



//----------------------------------------Incident-----------------------------------

export const ReceiveSource_Drp_Data = 'ReceiveSource_Drp_Data';
export const FBI_Codes_Drp_Data = 'FBI_Codes_Drp_Data';
export const CadCfsCode_Drp_Data = 'CadCfsCode_Drp_Data';
export const Rms_Disposition_Drp_Data = 'Rms_Disposition_Drp_Data';
export const Cad_Disposition_Drp_Data = 'Cad_Disposition_Drp_Data';
export const Narrative_Type_Drp_Data = 'Narrative_Type_Drp_Data';





























//--------------------------------ArrestHome---------------------------------------------
export const ArrestType_DrData = "ArrestType_DrData";
export const Arresting_DrpData = "Arresting_DrpData";
export const ArrestJuvenileDis_DrpData = "ArrestJuvenileDisDrpData";
export const NIBRS_Drp_Data = "NIBRS_Drp_Data";
export const UCRClearID_Drp_Data = "UCRClearID_Drp_Data";







//------------------------------------Vehicle----------------------------
export const Vehicle_LossCode_Drp_Data = "Vehicle_LossCode_Drp_Data";
export const PlateID_Drp_Data = "PlateID_Drp_Data";
export const Classification_Drp_Data = "Classification_Drp_Data";
export const StyleID_Drp_Data = "StyleID_Drp_Data";
export const MakeID_Drp_Data = "MakeID_Drp_Data";
export const ModalID_Drp_Data = "ModalID_Drp_Data";
export const VehicleColor_Drp_Data = "VehicleColor_Drp_Data";
export const VODID_Drp_Data = "VODID_Drp_Data";
export const Vehicle_Search_Data = "Vehicle_Search_Data";
export const VehicleSearch_Type = "VehicleSearch_Type";
export const VehicleSearch_Modal_Status = "VehicleSearch_Modal_Status";



//--------------------FieldInterview------------------------------------------
export const FieldInterview_All_Data = 'FieldInterview_All_Data'
export const FISuspectActivity_Drp_Data = 'FISuspectActivity_Drp_Data:'
export const FIContactType_Drp_Data = 'FIContactType_Drp_Data:'

