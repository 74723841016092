import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import Dropdown from 'react-bootstrap/Dropdown';
import { useQuery } from 'react-query';
import { tableCustomStyles } from '../../../Components/Common/Utility';
import { coloredStyle_Select } from '../../Utility/CustomStylesForReact';
import '../section.css';
import useObjState from '../../../CADHook/useObjState';
import MasterTableListServices from '../../../CADServices/APIs/masterTableList'
import { isEmpty, compareStrings } from '../../../CADUtils/functions/common';
import { toastifySuccess } from '../../../Components/Common/AlertMsg';
import { SearchFilter, SendIcon } from '../../Common/SearchFilter'
import CADConfirmModal from '../../Common/CADConfirmModal';
import { useSelector } from 'react-redux';

const MiscellaneousStatusSection = () => {
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [filterTypeDescOption, setFilterTypeDescOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");
  const [filterListData, setFilterListData] = useState([]);
  const [listData, setListData] = useState([]);
  const [searchValue1, setSearchValue1] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [isActive, setIsActive] = useState('');
  const [confirmType, setConfirmType] = useState('');
  const [activeInactiveData, setActiveInactiveData] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [agencyCodeDropDown, setAgencyCodeDropDown] = useState([])
  const [loginPinID, setLoginPinID] = useState('');
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [
    miscellaneousStatusCode,
    setMiscellaneousStatusCode,
    handleMiscellaneousStatusCode,
    clearStateMiscellaneousStatusCode
    ,
  ] = useObjState({
    AgencyCode: "",
    lstMiscellaneousID: "",
    MiscellaneousStatusCode: "",
    Description: "",
    ResourceAvailableStatus: false,
    IsActive: 1,
    AgencyType1: "",
    AgencyType2: "",
  })
  const [
    errorMiscellaneousStatusCode,
    _setErrorMiscellaneousStatusCode,
    handlErrorMiscellaneousStatusCode,
    clearErrorMiscellaneousStatusCode,
  ] = useObjState({
    AgencyCode: false,
    Description: false,
  });

  const validateMiscellaneousStatusCodeForm = () => {
    let isError = false;
    const keys = Object.keys(errorMiscellaneousStatusCode);
    keys.map((field) => {
      if (
        field === "AgencyCode" &&
        isEmpty(miscellaneousStatusCode[field])) {
        handlErrorMiscellaneousStatusCode(field, true);
        isError = true;
      } else if (field === "Description" && isEmpty(miscellaneousStatusCode[field])) {
        handlErrorMiscellaneousStatusCode(field, true);
        isError = true;
      } else {
        handlErrorMiscellaneousStatusCode(field, false);
      }
    });
    return !isError;
  };

  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(localStoreData?.PINID);
      setLoginAgencyID(localStoreData?.AgencyID);
    }
  }, [localStoreData]);

  const getAgencyCode = `/MasterAgency/MasterAgencyCode`;
  const { data: agencyCodeData, isSuccess: isFetchAgencyCode } = useQuery(
    [getAgencyCode],
    MasterTableListServices.getAgencyCode,
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (isFetchAgencyCode && agencyCodeData) {
      const data = JSON.parse(agencyCodeData?.data?.data);
      setAgencyCodeDropDown(data?.Table || [])
    }
  }, [isFetchAgencyCode, agencyCodeData]);

  const getMiscellaneousStatusListKey = `/CAD/MasterMiscellaneous/GetMiscellaneousstatus`;
  const { data: miscellaneousStatusList, isSuccess: isFetchMiscellaneousStatusList, refetch: miscellaneousStatusListRefetch, isError: isNoData } = useQuery(
    [getMiscellaneousStatusListKey, {
      IsActive: parseInt(pageStatus)
      // lstMiscellaneousID:""
    }],
    MasterTableListServices.getMiscellaneousStatus,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  useEffect(() => {
    if (isFetchMiscellaneousStatusList && miscellaneousStatusList) {
      const res = JSON.parse(miscellaneousStatusList?.data?.data);
      const data = res?.Table
      setFilterListData(data || [])
      setListData(data || [])
    } else {
      setFilterListData([])
      setListData([])
    }
  }, [isFetchMiscellaneousStatusList, miscellaneousStatusList])

  function handleClose() {
    clearStateMiscellaneousStatusCode();
    clearErrorMiscellaneousStatusCode();
  }

  const onSave = async () => {
    if (!validateMiscellaneousStatusCodeForm()) return
    const isUpdate = !!miscellaneousStatusCode?.lstMiscellaneousID;

    const data = {
      Action: isUpdate ? "UPDATE" : "ADD",
      lstMiscellaneousID: isUpdate ? miscellaneousStatusCode?.lstMiscellaneousID : undefined,
      MiscellaneousStatusCode: miscellaneousStatusCode?.MiscellaneousStatusCode,
      Description: miscellaneousStatusCode?.Description,
      ResourceAvailableStatus: miscellaneousStatusCode?.ResourceAvailableStatus,
      AgencyID: miscellaneousStatusCode?.AgencyCode,
      ModifiedByUserFK: loginPinID
    }

    const response = await MasterTableListServices.UpsertMiscellaneous(data);

    if (response?.status === 200) {
      toastifySuccess(isUpdate ? "Data Updated Successfully" : "Data Saved Successfully");
      handleClose()
      miscellaneousStatusListRefetch();
    }
  };

  async function handelActiveInactive() {
    const data = {
      lstMiscellaneousID: activeInactiveData?.lstMiscellaneousID,
    }
    const response = await MasterTableListServices.changeMiscellaneousStatus(data);
    if (response?.status === 200) {
      const data = JSON.parse(response?.data?.data)?.Table?.[0];
      toastifySuccess(data?.Message);
      miscellaneousStatusListRefetch();
    }
    setShowModal(false);
  }

  const setEditValue = (row) => {
    setMiscellaneousStatusCode({
      ...row,
      AgencyCode: parseInt(row.AgencyID),
      IsActive: row.IsActive ? 1 : 0
    });
  }

  const columns = [
    {
      name: 'Miscellaneous Status Code',
      selector: row => row.MiscellaneousStatusCode,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.MiscellaneousStatusCode, rowB.MiscellaneousStatusCode),
      style: {
        position: "static",
      },
    },

    {
      name: 'Description',
      selector: row => row.Description,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Description, rowB.Description),
      style: {
        position: "static",
      },
    },
    {
      name: 'Resource Available',
      selector: row => row.ResourceAvailableStatus ? "Yes" : "No",
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ResourceAvailableStatus, rowB.ResourceAvailableStatus),
      style: {
        position: "static",
      },
    },
    {
      name: 'Agency Code',
      selector: row => agencyCodeDropDown.find(item => item.AgencyID === row.AgencyID)?.ShortName,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ZoneDescription, rowB.ZoneDescription),
      style: {
        position: "static",
      },
    },
    {
      name:
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {'Status'}
        </div>,
      cell: (row, index) =>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <span
            className="btn btn-sm text-white px-1 py-0 mr-1"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            {row?.IsActive ? (
              <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(0); setConfirmType("InActive") }}></i>
            ) : (
              <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(1); setConfirmType("Active"); }}></i>
            )}
          </span>
        </div>,
      width: "70px",
      style: {
        position: "static",
      },
    },

  ];



  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 incident-tab ">
              <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); setSearchValue1(""); setSearchValue2(""); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); setSearchValue1(""); setSearchValue2(""); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
              </ul>
            </div>
            {
              pageStatus === '1' ?
                <>
                  <div className='utilities-tab-content-form-main'>
                    {/* line 1 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Agency Code{errorMiscellaneousStatusCode.AgencyCode && isEmpty(miscellaneousStatusCode.AgencyCode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Agency Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-3 d-flex align-self-center justify-content-end">
                        <Select
                          styles={coloredStyle_Select}
                          placeholder="Select"
                          options={agencyCodeDropDown}
                          value={
                            !isEmpty(miscellaneousStatusCode.AgencyCode)
                              ? agencyCodeDropDown.find(
                                (option) => option.AgencyID === miscellaneousStatusCode.AgencyCode
                              )
                              : []
                          }
                          getOptionLabel={(v) => v?.ShortName}
                          getOptionValue={(v) => v?.AgencyID}
                          onChange={(e) => {
                            handleMiscellaneousStatusCode
                              ("AgencyCode", e?.AgencyID);
                          }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Agency Type{errorMiscellaneousStatusCode.ZoneDescription && isEmpty(miscellaneousStatusCode.ZoneDescription) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Description"}</p>
                          )}
                        </label>
                      </div>
                        <div className="col-1">
                          <input
                            type="text"
                            className="form-control py-1 new-input"
                            placeholder="Code"
                            name="AgencyType1"
                            value={miscellaneousStatusCode.AgencyType1}
                            onChange={(e) => handleMiscellaneousStatusCode("AgencyType1", e.target.value)}
                            style={{ flex: '1' }}
                          />
                        </div>
                        <div className="col-2">
                          <input
                            type="text"
                            className="form-control py-1 ml-2 new-input"
                            placeholder="Description"
                            name="AgencyType2"
                            value={miscellaneousStatusCode.AgencyType2}
                            onChange={(e) => handleMiscellaneousStatusCode("AgencyType2", e.target.value)}
                            style={{ flex: '1' }}
                          />
                      </div>

                    </div>
                    {/* line 2 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label htmlFor="" className="tab-form-label text-end">
                          Miscellaneous Status Code
                          {errorMiscellaneousStatusCode.MiscellaneousStatusCode && isEmpty(miscellaneousStatusCode.MiscellaneousStatusCode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>
                              {"Select Miscellaneous Status Code"}
                            </p>
                          )}
                        </label>
                      </div>
                      <div className="col-3 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input"
                          placeholder="Miscellaneous Status Code"
                          name="cfsCodeDesc"
                          value={miscellaneousStatusCode.MiscellaneousStatusCode}
                          onChange={(e) => {
                            handleMiscellaneousStatusCode
                              ("MiscellaneousStatusCode", e.target.value)
                          }}
                          style={{ flex: '1' }}

                        />
                      </div>

                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Description{errorMiscellaneousStatusCode.Description && isEmpty(miscellaneousStatusCode.Description) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Description"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-4 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Description'
                          value={miscellaneousStatusCode.Description}
                          onChange={(e) => {
                            handleMiscellaneousStatusCode
                              ("Description", e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    {/* line 3 */}
                    <div className="row">
                      <div className="col-5 offset-2 agency-checkbox-item">
                        <input
                          type="checkbox"
                          name="ResourceAvailableStatus"
                          checked={miscellaneousStatusCode.ResourceAvailableStatus}
                          onChange={(e) => { handleMiscellaneousStatusCode("ResourceAvailableStatus", e.target.checked) }}
                        />
                        <div className="agency-checkbox-text-container tab-form-label">
                          <span>Resource Available Status</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                </>
            }
          </div>

        </div>

        <div className='utilities-tab-content-table-container'>
          <div className="row">
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                value={searchValue1}
                placeholder='Search By Code...'
                onChange={(e) => {
                  setSearchValue1(e.target.value);
                  const result = SearchFilter(listData, e.target.value, searchValue2, filterTypeIdOption, 'MiscellaneousStatusCode', 'Description')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '2', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Description...'
                value={searchValue2}
                onChange={(e) => {
                  setSearchValue2(e.target.value);
                  const result = SearchFilter(listData, searchValue1, e.target.value, filterTypeDescOption, 'MiscellaneousStatusCode', 'Description')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '1', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeDescOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="table-responsive">
            <DataTable
              dense
              columns={columns}
              data={filterListData}
              customStyles={tableCustomStyles}
              pagination
              responsive
              striped
              highlightOnHover
              noDataComponent={isNoData ? "There are no data to display" : 'There are no data to display'}
              fixedHeader
              fixedHeaderScrollHeight="360px"
              onRowClicked={(row) => {
                setEditValue(row);
              }}
              selectableRowsHighlight
              persistTableHead={true}
            />
          </div>

          {parseInt(pageStatus) === 1 && <div className="utilities-tab-content-button-container" >
            <button type="button" className="btn btn-sm btn-success" onClick={() => handleClose()}>New</button>
            <button type="button" className="btn btn-sm btn-success" onClick={() => onSave()}>{!!miscellaneousStatusCode?.lstMiscellaneousID ? "Update" : "Save"}</button>
          </div>}
        </div>
      </div>
      <CADConfirmModal showModal={showModal} setShowModal={setShowModal} handleConfirm={handelActiveInactive} confirmType={confirmType} />
    </>
  );
};

export default MiscellaneousStatusSection;