import React, { useState, useRef, useCallback } from 'react'
import Select from "react-select";
import DatePicker from "react-datepicker";
import { base64ToString, filterPassedTime, getShowingMonthDateYear, stringToBase64, tableCustomStyles } from '../../../../Common/Utility';
import DataTable from 'react-data-table-component';
import { Checkbox } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useContext } from 'react'
import { get_AgencyOfficer_Data, get_ArresteeName_Data, get_Arresting_DropDown, get_Masters_Name_Drp_Data, get_PropertyTypeData } from '../../../../../redux/actions/DropDownsData';
import { useLocation, useNavigate } from "react-router-dom";
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { RequiredField, RequiredFieldIncident } from '../../../Utility/Personnel/Validation';
import { Comman_changeArrayFormat, sixColArray } from '../../../../Common/ChangeArrayFormat';
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import MasterNameModel from '../../../MasterNameModel/MasterNameModel';
import TreeComponent from '../TreeComponent/TreeComponent';
import TreeModel from './TreeModel';
import PropertyReportRoom from '../../PropertyReportRoom/PropertyReportRoom';
import { useReactToPrint } from 'react-to-print';
import ChainOfModel from '../../PropertyReportRoom/ChainOfModel';
import { debounce } from 'lodash';




const Home = (props) => {

    const { setStatus, showIncPage, DecPropID, DecMPropID, DecProRomId, ProRoomStatus, SelectedCategory, CallStatus, ProType, ProNumber, ProTransfer,CheckboxStatus } = props

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);
    const arresteeNameData = useSelector((state) => state.DropDown.arresteeNameData);
    const mastersNameDrpData = useSelector((state) => state.DropDown.mastersNameDrpData);
    const propertyTypeData = useSelector((state) => state.DropDown.propertyTypeData);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    // let DecPropID = 0, DecMPropID = 0
    const query = useQuery();
    var IncID = query?.get("IncId");
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");
    // var ProId = query?.get("ProId");
    var MProId = query?.get('MProId');
    var ProSta = query?.get('ProSta');
    let MstPage = query?.get('page');

    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));
    // if (!ProId) ProId = 0;
    // else DecPropID = parseInt(base64ToString(ProId));
    // if (!MProId) ProId = 0;
    // else DecMPropID = parseInt(base64ToString(MProId));


    const [ownerNameData, setOwnerNameData] = useState([]);
    const [possenSinglData, setPossenSinglData] = useState([]);
    // date 
    const [expecteddate, setExpecteddate] = useState();
    const [courtdate, setCourtdate] = useState('');
    const [releasedate, setreleasedate] = useState('');
    const [destroydate, setdestroydate] = useState('');
    // dropdown
    const [loginAgencyID, setloginAgencyID] = useState('');
    const [loginPinID, setloginPinID,] = useState('');
    const [activitydate, setactivitydate] = useState();

    const [clickedRow, setClickedRow] = useState(null);
    const [reasonIdDrp, setReasonIdDrp] = useState([]);
    const [editval, setEditval] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [propertyId, setPropertyId] = useState('');
    const [masterpropertyId, setMasterPropertyId] = useState('');
    const [possessionID, setPossessionID] = useState('');
    // checkbox states
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('');
    // functionality states
    const [propertyNumber, setPropertyNumber] = useState('');
    const [rowClicked, setRowClicked] = useState(false);
    const [nameModalStatus, setNameModalStatus] = useState(false);
    const [mainIncidentID, setMainIncidentID] = useState('');
    const [description, setDescription] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleClear, setToggleClear] = useState(false);
    const [locationPath, setLocationPath] = useState();
    const [StorageLocationID, setStorageLocationID] = useState();
    const [locationStatus, setlocationStatus] = useState(false);
    const [proRoom, setProRoom] = useState('PropertyRoom');
    const [searchStoStatus, setSearchStoStatus] = useState();
    const [searchStoragepath, setSearchStoragePath] = useState();
    const [searchStorageLocId, setsearchStorageLocId] = useState();
    const [releasestatus, setReleaseStatus] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [chainreport, setChainReport] = useState();
    const [insertcall, setInsertcall] = useState();
    const [selectedRowsObjects, setselectedRowsObjects] = useState([]);
    const [isClearing, setIsClearing] = useState(false);
    const [type, setType] = useState("PropertyRoom");
    const [functiondone, setfunctiondone] = useState(false);



    const AddType = [
        { value: 'PropertyNumber', label: 'Property Number' },
        { value: 'StorageLocationID', label: 'Location' },
        { value: 3, label: 'Barcode' },
        { value: 'IncidentNumber', label: 'Transaction Number' },
        { value: 'PropertyTypeID', label: 'Property Type' },
        { value: 'PropertyTag', label: 'Property Tag' },
    ]

    const AddTransfer = [
        { value: 1, label: 'CheckIn' },
        { value: 2, label: 'CheckOut' },
        { value: 3, label: 'Release' },
        { value: 4, label: 'Destroy' },
    ]

    const [selectedOptions, setSelectedOptions] = useState(AddType[0]);
    const [transfer, settransfer] = useState('');

    const [value, setValue] = useState({
        'PropertyID': '', 'MasterPropertyId': '', 'ActivityType': '', 'ActivityReasonID': '', 'ExpectedDate': '', 'ActivityComments': '', 'OtherPersonNameID': '', 'PropertyRoomPersonNameID': '', 'ChainDate': '', 'DestroyDate': '',
        'CourtDate': '', 'ReleaseDate': '', 'PropertyTag': '', 'RecoveryNumber': '', 'StorageLocationID': '', 'ReceiveDate': '', 'OfficerNameID': '', 'InvestigatorID': '', 'location': '', 'activityid': '', 'EventId': '',
        'IsCheckIn': false, 'IsCheckOut': false, 'IsRelease': false, 'IsDestroy': false, 'IsTransferLocation': false, 'IsUpdate': false, 'CreatedByUserFK': '',
        'PropertyTypeID': '',
    })

    const [errors, setErrors] = useState({
        'ReasonError': '', 'ActivityDateError': '', 'InvestigatorError': '', 'LocationError': '', 'PropertyError': '', 'ExpectedDateError': '', 'OfficerNameError': '', 'NameError': '', 'CourtDateError': '', 'ReleaseDateError': '', 'DestroyDateError': '', 'TypeError': '', 'TransferError': '', 'SearchError': '',
    })

    const [searcherror, setsearcherror] = useState({
        'SearchError': '',
    })

    useEffect(() => {
        if (localStoreData) {
            setloginAgencyID(localStoreData?.AgencyID);
            setloginPinID(localStoreData?.PINID);
            setPropertyId(DecPropID);
            setMasterPropertyId(DecPropID);
            if(!CheckboxStatus){
                sessionStorage.removeItem('selectedRows'); 
            }

            if (CallStatus === 'true') {
                GetData_Propertyroom(DecPropID, SelectedCategory);
            } else if (CallStatus === 'false' && (ProType && ProNumber)) {
                SearchButtons(ProType, ProNumber, localStoreData?.AgencyID);
                const matchedOption = AddType.find(option => option.value === ProType);
                if (matchedOption) {
                    setSelectedOptions(matchedOption);
                    // setValue({...value,'PropertyTypeID': ProType})
                }

            }
            setValue({ ...value, 'PropertyTypeID': parseInt(ProNumber), 'ActivityType': ProTransfer })
            settransfer(ProTransfer);
            setPropertyNumber(ProNumber)

        }
    }, [localStoreData, ProType, ProNumber, CallStatus, DecPropID, SelectedCategory, propertyTypeData, ProTransfer]);

    useEffect(() => {
        //call

        dispatch(get_Masters_Name_Drp_Data(possessionID, 0, 0, IncID));
        setValue({ ...value, ['PropertyRoomPersonNameID']: parseInt(possessionID), })
    }, [possessionID, loginPinID]);
    // useEffect(()=>{
    //        setValue({...value,'PropertyTypeID': ProNumber})
    // },[propertyTypeData])

   

    useEffect(() => {
        if (CallStatus === 'true') {
            GetData_Propertyroom(DecPropID, SelectedCategory);
        } else if (CallStatus === 'false' && (ProType || ProNumber)) {
            // SearchButtons(ProType, ProNumber, loginAgencyID);
        }
    }, [CallStatus]);

    // useEffect(() => {
    //     // console.log('call useEffect')
    //     const savedSelectedRows = sessionStorage.getItem('selectedRows');
    //     // console.log(savedSelectedRows)
    //     if (savedSelectedRows) {
    //         setSelectedRows(JSON.parse(savedSelectedRows));
    //         getSelectedRows(JSON.parse(savedSelectedRows), searchData)
    //     }
    // }, [searchData]);

    // useEffect(() => {
    //     if (selectedRows.length > 0) {
    //         sessionStorage.setItem('selectedRows', JSON.stringify(selectedRows));
    //     } else {
    //         sessionStorage.setItem('selectedRows', '');
    //     }
    // }, [selectedRows]);

    useEffect(() => {
        if (loginAgencyID && selectedOption) {
            GetActivityReasonDrp(loginAgencyID);
        }
        dispatch(get_PropertyTypeData(loginAgencyID));
    }, [loginAgencyID, selectedOption]);

    useEffect(() => {
        if (IncID) {
            setMainIncidentID(IncID);
            get_Arrestee_Drp_Data(IncID);
        }
    }, [IncID, nameModalStatus, possessionID]);

    useEffect(() => {
        dispatch(get_Masters_Name_Drp_Data(possessionID, 0, 0));

        if (possessionID) { setValue({ ...value, ['PropertyRoomPersonNameID']: parseInt(possessionID) }) }
    }, [possessionID]);

    const get_Arrestee_Drp_Data = (IncidentID) => {
        const val = { 'MasterNameID': 0, 'IncidentID': IncidentID, }
        fetchPostData('Arrest/GetDataDropDown_Arrestee', val).then((data) => {
            if (data) {
                setOwnerNameData(sixColArray(data, 'NameID', 'Arrestee_Name', 'LastName', 'DateOfBirth', 'Gendre_Description', 'Race_Description', 'NameID', 'MasterNameID'));
            } else {
                setOwnerNameData([])
            }
        })
    };

    const GetSingleDataPassion = (nameID, masterNameID) => {
        const val = { 'NameID': nameID, 'MasterNameID': masterNameID }
        fetchPostData('MasterName/GetSingleData_MasterName', val).then((res) => {
            if (res) {
                setPossenSinglData(res);
            } else { setPossenSinglData([]); }
        })
    }

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
        const { value: selectedOption } = event.target;

        setValue(prevState => ({
            ...prevState,
            IsCheckIn: selectedOption === 'CheckIn',
            IsCheckOut: selectedOption === 'CheckOut',
            IsRelease: selectedOption === 'Release',
            IsDestroy: selectedOption === 'Destroy',
            IsTransferLocation: selectedOption === 'TransferLocation',
            IsUpdate: selectedOption === 'Update',
        }));
    };

    const isCheckInSelected = selectedOption === 'CheckIn';

    const handleInputChange = (e) => {
        setPropertyNumber(e.target.value);
        setsearcherror(prevValues => {
            return { ...prevValues, 'SearchError': '', }
        })
    };

    const check_Validation_Error = (e) => {
        const ReasonError = !rowClicked || selectedOption === null ? true : RequiredFieldIncident(value.ActivityReasonID);
        const PropertyError = !rowClicked || selectedOption === null ? true : RequiredFieldIncident(value.OtherPersonNameID);
        const OfficerNameError = !rowClicked || selectedOption === null ? true : RequiredFieldIncident(value.OfficerNameID);
        const NameError = value.IsCheckIn || value.IsCheckOut || value.IsDestroy || value.IsTransferLocation || value.IsUpdate || selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy' ? 'true' : RequiredFieldIncident(value.PropertyRoomPersonNameID);
        const CourtDateError = RequiredFieldIncident(value.CourtDate);
        const ReleaseDateError = RequiredFieldIncident(value.ReleaseDate);
        const DestroyDateError = RequiredFieldIncident(value.DestroyDate);
        const LocationError = value.IsCheckIn || value.IsTransferLocation ? RequiredFieldIncident(value.location) : 'true';

        setErrors(prevValues => {
            return {
                ...prevValues,
                ['ReasonError']: ReasonError || prevValues['ReasonError'],
                ['PropertyError']: PropertyError || prevValues['PropertyError'],
                ['OfficerNameError']: OfficerNameError || prevValues['OfficerNameError'],
                ['NameError']: NameError || prevValues['NameError'],
                ['LocationError']: LocationError || prevValues['LocationError'],
            }
        })
    }

    const { ReasonError, LocationError, PropertyError, ExpectedDateError, OfficerNameError, NameError } = errors

    useEffect(() => {
        if (ReasonError === 'true' && LocationError === 'true' && PropertyError === 'true' && OfficerNameError === 'true' && NameError === 'true') {
            Add_Type()
        }
    }, [ReasonError, PropertyError, LocationError, OfficerNameError, NameError])

    const check_Validation_Errorr = (e) => {

        const SearchError = RequiredFieldIncident(propertyNumber);
        setsearcherror(prevValues => {
            return {
                ...prevValues,
                ['SearchError']: SearchError,
            }
        })
    }

    const { SearchError } = searcherror

    useEffect(() => {
        if (SearchError === 'true' || SearchError === null) {
            SearchButton()
        }
    }, [searcherror])

    const GetActivityReasonDrp = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, 'EvidenceReasonType': selectedOption };
        fetchPostData("PropertyEvidenceReason/GetDataDropDown_PropertyEvidenceReason", val).then((data) => {
            if (data) {
                setReasonIdDrp(Comman_changeArrayFormat(data, 'EvidenceReasonID', 'Description'))
            } else {
                setReasonIdDrp([]);
            }
        });
    };

    useEffect(() => {
        if (loginAgencyID) {
            setValue({
                ...value,
                'IncidentID': propertyId, 'CreatedByUserFK': loginPinID, 'AgencyID': loginAgencyID, 'OtherPersonNameID': loginPinID
            });
            if (agencyOfficerDrpData?.length === 0) { dispatch(get_AgencyOfficer_Data(loginAgencyID)); }
        }
    }, [selectedOption]);

    const GetData_Propertyroom = (DecPropID, category) => {
        const val = {
            'PropertyID': DecPropID,
            'PropertyCategoryCode': category,
            'MasterPropertyID': 0,
            'AgencyId': loginAgencyID,
        };
        AddDeleteUpadate('Propertyroom/GetData_Propertyroom', val).then((res) => {
            const parsedData = JSON.parse(res.data);
            setSearchData(parsedData.Table);
            if (parsedData.Table && parsedData.Table.length > 0) {
                setEditval(parsedData.Table[0]);
            } else {
                toastifyError('No Data Available')
            }
        }).catch((error) => {
            // toastifyError('No Data Available');
        });
    };

    const GetChainCustodyReport = () => {
        const val = {
            'PropertyID': propertyId,
            'PropertyCategoryCode': selectedCategory,
            'MasterPropertyID': 0,
            'AgencyId': loginAgencyID,
        };
        AddDeleteUpadate('Propertyroom/Report_ChainOfCustody', val).then((res) => {
            const parsedData = JSON.parse(res.data);


            if (parsedData.Table && parsedData.Table.length > 0) {
                setChainReport(parsedData.Table[0]);
                // chainForm()
            }
            else {
                toastifyError('No Data Available')
            }
        }).catch((error) => {
            toastifyError('No Data Available');
        });
    };

    useEffect(() => {
        if (chainreport) {
            chainForm();
        }
    }, [chainreport]);

    const SearchButtons = (ProType, ProNumber, loginAgencyID) => {
        const val = { 'AgencyID': loginAgencyID, [ProType]: ProNumber, 'ActivityType': transfer, };
        AddDeleteUpadate('Propertyroom/SearchPropertyRoom', val).then((res) => {
            const parsedData = JSON.parse(res.data);
            setSearchData(parsedData.Table);
            if (parsedData.Table && parsedData.Table.length > 0) {
                const propertyId = parsedData.Table[0].PropertyID;
                const masterpropertyId = parsedData.Table[0].MasterPropertyID;
                setDescription('');
            } else {
                toastifyError('No Data Available')
            }
        }).catch((error) => {
            console.log(error)
            // toastifyError('No Data Available');
        });
    };

    const SearchButton = () => {
        const val = { 'AgencyID': loginAgencyID, [selectedOptions.value]: propertyNumber, 'ActivityType': transfer, };
        AddDeleteUpadate('Propertyroom/SearchPropertyRoom', val).then((res) => {
            const parsedData = JSON.parse(res.data);
            setSearchData(parsedData.Table);
            if (parsedData.Table && parsedData.Table.length > 0) {
                setDescription('');
                setPropertyId('');
                navigate(`/Property-room?&ProId=${stringToBase64('')}&MProId=${stringToBase64('')}&ProRomId=${stringToBase64('')}&ProRoomStatus=${true}&selectedCategory=${selectedCategory}&ProType=${selectedOptions.value}&ProNumber=${propertyNumber}&ProTransfer=${transfer}&CallStatus=${false}&CheckboxStatus=${true}`)
            } else {
                toastifyError('No Data Available')
            }
        }).catch((error) => {
            console.log(error)
            // toastifyError('No Data Available');
        });
    };

    const Add_Type = () => {
        const PropertyID = propertyId;
        const MasterPropertyId = masterpropertyId;
        const ActivityType = selectedOption;
        const CreatedByUserFK = loginPinID;

        const { ActivityReasonID, ExpectedDate, ActivityComments, OtherPersonNameID, PropertyRoomPersonNameID, ChainDate, DestroyDate, CourtDate,
            ReleaseDate, PropertyTag, RecoveryNumber, StorageLocationID, ReceiveDate, OfficerNameID, InvestigatorID, location, activityid, EventId, IsCheckIn,
            IsCheckOut, IsRelease, IsDestroy, IsTransferLocation, IsUpdate,
        } = value;

        const valuesArray = PropertyID.map(id => ({
            PropertyID: id, ActivityType, ActivityReasonID, ExpectedDate, ActivityComments, OtherPersonNameID, PropertyRoomPersonNameID, ChainDate, DestroyDate,
            CourtDate, ReleaseDate, PropertyTag, RecoveryNumber, StorageLocationID, ReceiveDate, OfficerNameID, InvestigatorID, location, activityid, EventId,
            MasterPropertyId, IsCheckIn, IsCheckOut, IsRelease, IsDestroy, IsTransferLocation, IsUpdate, CreatedByUserFK,
        }));

        const valuesArrayString = JSON.stringify(valuesArray);


        AddDeleteUpadate('Propertyroom/PropertyroomInsert', { 'Data': valuesArray }).then((res) => {
            reset();
            GetData_Propertyroom(propertyId, selectedCategory);
            navigate(`/Property-room?&ProId=${stringToBase64(propertyId)}&MProId=${stringToBase64(masterpropertyId)}&ProRomId=${stringToBase64(res?.PropertyroomID)}&ProRoomStatus=${true}&selectedCategory=${''}&ProType=${''}&ProNumber=${''}&ProTransfer=${''}&CallStatus=${true}&CheckboxStatus=${true}`);

            setInsertcall(true);
            setReleaseStatus(selectedOption === 'Release' ? true : false);
            if (selectedOption === 'Release') {
                printForm();
            }
            toastifySuccess(res.Message);
        });
    };


    const set_Edit_Value = (row) => {
        setSelectedStatus(row.Status);
        setPropertyId(row.PropertyID);
        // console.log(row ,'row');
        navigate(`/Property-room?&ProId=${stringToBase64(row.PropertyID)}&MProId=${stringToBase64(masterpropertyId)}&ProRomId=${stringToBase64(row?.PropertyroomID)}&ProRoomStatus=${true}&selectedCategory=${row.PropertyCategoryCode}&ProType=${selectedOptions.value}&ProNumber=${propertyNumber}&ProTransfer=${transfer}&CallStatus=${CallStatus}`)
        setSelectedCategory(row.PropertyCategoryCode)
        if (row.Status) { setStatus(true); }
        else { setStatus(''); }
        setRowClicked(true);
        setValue({
            IsCheckIn: false,
            IsCheckOut: false,
            IsRelease: false,
            IsDestroy: false,
            IsTransferLocation: false,
            IsUpdate: false
        });
        setSelectedOption(null);
    }

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 32,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const columns = [
        {
            name: 'Property Number',
            selector: (row) => row.PropertyNumber,
            sortable: true
        },
        {
            name: 'Type',
            selector: (row) => row.Type,
            sortable: true
        },
        {
            name: 'Description',
            selector: (row) => row.Description,
            sortable: true

        },
        {
            name: 'Classification',
            selector: (row) => row.Classification,
            sortable: true

        },
        {
            name: 'Status',
            selector: (row) => row.Status,
            sortable: true

        },


        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 30 }}>Action</p>,
            cell: row =>
                <div style={{ position: 'absolute', top: 4, right: 30 }}>
                    {/* {
                effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
                  <span onClick={(e) => setCrimeId(row.CrimeID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                    <i className="fa fa-trash"></i>
                  </span>
                  : <></>
                  : <span onClick={(e) => setCrimeId(row.CrimeID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                    <i className="fa fa-trash"></i>
                  </span>
              }
        */}
                </div>
        }

    ]

    const ChangeDropDowns = (e, name) => {
        console.log(e.value);
        if (e) {
            setValue({
                ...value,
                [name]: e.value
            })
            setPropertyNumber(e.value)
            setsearcherror(prevValues => {
                return {
                    ...prevValues, 'SearchError': '',
                }
            })
            // setPossessionID(e.value);
        } else {
            setValue({
                ...value,
                [name]: null
            });
            setsearcherror(prevValues => {
                return {
                    ...prevValues, 'SearchError': '',
                }
            })
            setPossessionID('');
            setPropertyNumber('')
        }
    };

    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({
                ...value,
                [name]: e.value
            })
            // setPossessionID(e.value);
        } else {
            setValue({
                ...value,
                [name]: null
            });
            setPossessionID('');

        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (event) {
            setValue((prevState) => ({ ...prevState, [name]: value, }));
        }
        else {
            setValue((prevState) => ({ ...prevState, [name]: null, }));
        }
    };

    const setStatusFalse = (e) => {
        setIsClearing(true);
        setSelectedRows([]);
        navigate(`/Property-room?&ProId=${0}&MProId=${0}&ProRomId=${0}&ProRoomStatus=${true}&selectedCategory=${''}&ProType=${''}&ProNumber=${''}&ProTransfer=${''}&CallStatus=${false}`)
        reset();
        setPropertyId('');
        setClickedRow(null);
        setPropertyNumber('');
        setSelectedRows([]);
        setSearchStoragePath('');
        setSelectedOptions(AddType[0]);
        // setSelectedOptions('')
        setToggleClear(!toggleClear);
        sessionStorage.setItem('selectedRows', '');
    }

    const handleCheckboxChange = useCallback(
        debounce(({ selectedRows }) => {
            console.log(selectedRows);
            if (selectedRows.length > 0) {
                const { Status, Description, PropertyID, PropertyCategoryCode } = selectedRows[0];

                const propertyIds = selectedRows.map(row => row.PropertyID);

                setSelectedCategory(PropertyCategoryCode);
                setDescription(Description);
                setSelectedStatus(Status);
                setRowClicked(true);
                sessionStorage.setItem('selectedRows', JSON.stringify(selectedRows));
                setSelectedRows(selectedRows);
                setPropertyId(propertyIds);

                setStatus(!!Status); 
            } else {
                
                setSelectedStatus(null);
                setRowClicked(false);
                setStatus(false);
            }
        }, 1000),
        []
    );

    useEffect(() => {
        const savedSelectedRows = JSON.parse(sessionStorage.getItem('selectedRows')) || [];
        console.log(savedSelectedRows);
        setSelectedRows(savedSelectedRows);
    }, []);

    
    useEffect(() => {
        if (!isClearing && selectedRows.length > 0) {
            sessionStorage.setItem('selectedRows', JSON.stringify(selectedRows));
        } else if (isClearing) {
            sessionStorage.removeItem('selectedRows'); 
            setIsClearing(false); 
        }
    }, [selectedRows, isClearing]);


    const printForm = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data',
        onAfterPrint: () => { '' }
    })

    const chainForm = useReactToPrint({
        content: () => componentRefnew.current,
        documentTitle: 'Data',
        onAfterPrint: () => { console.log(chainreport) }

    })

    const reset = () => {
        setValue({
            ...value,
            'PropertyID': '', 'ActivityType': '', 'ActivityReasonID': '', 'ExpectedDate': '', 'ActivityComments': '', 'PropertyRoomPersonNameID': '', 'ChainDate': '', 'DestroyDate': '',
            'CourtDate': '', 'ReleaseDate': '', 'PropertyTag': '', 'RecoveryNumber': '', 'StorageLocationID': '', 'ReceiveDate': '', 'OfficerNameID': '', 'InvestigatorID': '', 'location': '', 'activityid': '', 'EventId': '',
            'MasterPropertyId': '', 'IsCheckIn': false, 'IsCheckOut': false, 'IsRelease': '', 'IsDestroy': '', 'IsTransferLocation': '', 'IsUpdate': '', 'CreatedByUserFK': '', 'PropertyTypeID': '',
            'OtherPersonNameID': ''
        });
        setErrors({
            ...errors,
            'ReasonError': '', 'ActivityDateError': '', 'InvestigatorError': '', 'PropertyError': '', 'ExpectedDateError': '', 'OfficerNameError': '', 'NameError': '', 'CourtDateError': '', 'ReleaseDateError': '', 'DestroyDateError': '', 'TypeError': '', 'TransferError': '', 'LocationError': '', 'SearchError': '',
        })
        setsearcherror(prevValues => {
            return { ...prevValues, 'SearchError': '', }
        })
        setCourtdate(''); setreleasedate(''); setdestroydate(''); setExpecteddate('');
        setSelectedStatus(''); setRowClicked(''); setSelectedOption('');
        setactivitydate('');
        setReasonIdDrp([]);
        setLocationPath('');
        setDescription(''); setSelectedStatus(''); setRowClicked('');
        setSearchData([]);
        setSelectedRows([]);
        setToggleClear(!toggleClear);
        setStatus('');
        settransfer(null);
        setEditval([]);


        // setSelectedOptions('');
        // setPossessionID(''); setPossenSinglData([]);
    }

    const conditionalRowStyles = [
        {
            when: row => row.PropertyID === propertyId,
            style: {
                backgroundColor: '#001f3fbd',
                color: 'white',
                cursor: 'pointer',
            },
        },
    ];

    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 31,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const onDashboardClose = () => {
        navigate('/dashboard-page');
    }

    const componentRef = useRef();
    const componentRefnew = useRef();

    // const printForm = useReactToPrint({
    //     content: () => componentRef.current,
    //     documentTitle: 'Data',
    //     onAfterPrint: () => { '' }
    // })

    const clearSelectedRows = () => {
        localStorage.removeItem('selectedRows'); // Remove selected rows from localStorage
        setSelectedRows([]); // Clear the selected rows state
    };

    const getSelectedRows = (selectedRows, searchData) => {
        const totalIds = searchData.map(item => item.PropertyID);
        const selectedIds = selectedRows.map(item => item.PropertyID);
        const selectedRowsData = searchData.filter(item => selectedIds.includes(item.PropertyID));

        console.log(totalIds)
        console.log(selectedIds)
        console.log(selectedRowsData)
        setselectedRowsObjects(selectedRowsData);
    }


    function handleClickedCleared() {
        setValue({
            ...value,
            'location': '',
        });
    }


    // console.log(searchData)
    return (
        <>
            <div className="col-12">
                <div className="row">
                    <div className="col-3 col-md-2 col-lg-1 pt-1">
                        <label htmlFor="" className='new-label'>Activity Type</label>
                    </div>
                    <div className="col-12 col-md-4 col-lg-2  pt-1 ">
                        <div className="form-check  ">
                            <input className="form-check-input" type="radio" value="CheckIn" name="AttemptComplete" checked={value?.IsCheckIn}
                                disabled={!rowClicked || selectedStatus === 'TransferLocation' || selectedStatus === 'Update' || selectedStatus === 'CheckIn' || selectedStatus === 'Release' || selectedStatus === 'Destroy'} id="flexRadioDefault" onChange={handleRadioChange} />
                            <label style={{ fontWeight: value?.IsCheckIn ? 'bold' : 'normal' }} className="form-check-label" htmlFor="flexRadioDefault">
                                Check In
                            </label>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-2  pt-1 ">
                        <div className="form-check  ">
                            <input className="form-check-input" type="radio" value="CheckOut" name="AttemptComplete" checked={value?.IsCheckOut}
                                disabled={!rowClicked || selectedStatus === 'CheckOut' || selectedStatus === null || selectedStatus === 'Release' || selectedStatus === 'Destroy'} id="flexRadioDefault1" onChange={handleRadioChange} />
                            <label style={{ fontWeight: value?.IsCheckOut ? 'bold' : 'normal' }} className="form-check-label" htmlFor="flexRadioDefault1">
                                Check Out
                            </label>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-2  pt-1 ">
                        <div className="form-check  ">
                            <input className="form-check-input" type="radio" value="Release" name="AttemptComplete" checked={value?.IsRelease}
                                disabled={!rowClicked || selectedStatus === 'Release' || selectedStatus === null || selectedStatus === 'Release' || selectedStatus === 'Destroy'} id="flexRadioDefault2" onChange={handleRadioChange} />
                            <label style={{ fontWeight: value?.IsRelease ? 'bold' : 'normal' }} className="form-check-label" htmlFor="flexRadioDefault2 ">
                                Release
                            </label>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-2  pt-1 ">
                        <div className="form-check  ">
                            <input className="form-check-input" type="radio" value="Destroy" name="AttemptComplete" checked={value?.IsDestroy}
                                disabled={!rowClicked || selectedStatus === 'Destroy' || selectedStatus === null || selectedStatus === 'Release' || selectedStatus === 'Destroy'} id="flexRadioDefault3" onChange={handleRadioChange} />
                            <label style={{ fontWeight: value?.IsDestroy ? 'bold' : 'normal' }} className="form-check-label" htmlFor="flexRadioDefault3">
                                Destroy
                            </label>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-2  pt-1 ">
                        <div className="form-check  ">
                            <input className="form-check-input" type="radio" value="TransferLocation" name="AttemptComplete" checked={value?.IsTransferLocation}
                                disabled={!rowClicked || selectedStatus === 'TransferLocation' || selectedStatus === null || selectedStatus === 'Release' || selectedStatus === 'Destroy'} id="flexRadioDefault4" onChange={handleRadioChange} />
                            <label style={{ fontWeight: value?.IsTransferLocation ? 'bold' : 'normal' }} className="form-check-label" htmlFor="flexRadioDefault4">
                                Transfer Location
                            </label>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-1  pt-1 ">
                        <div className="form-check  ">
                            <input className="form-check-input" type="radio" value="Update" name="AttemptComplete" checked={value?.IsUpdate}
                                disabled={!rowClicked || selectedStatus === 'Update' || selectedStatus === null || selectedStatus === 'Release' || selectedStatus === 'Destroy'} id="flexRadioDefault5" onChange={handleRadioChange} />
                            <label style={{ fontWeight: value?.IsUpdate ? 'bold' : 'normal' }} className="form-check-label" htmlFor="flexRadioDefault5">
                                Update
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 col-md-3 col-lg-1 mt-2 px-1">
                        <label htmlFor="" className='new-label'>Reason{errors.ReasonError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ReasonError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 mt-1">
                        <Select
                            name='ActivityReasonID'
                            value={reasonIdDrp?.filter((obj) => obj.value === value?.ActivityReasonID)}
                            isClearable
                            options={reasonIdDrp}
                            onChange={(e) => ChangeDropDown(e, 'ActivityReasonID')}
                            placeholder="Select..."
                            styles={selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy' ? 'readonlyColor' : colourStyles}
                            isDisabled={selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy'}
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-2 px-1">
                        <label htmlFor="" className='new-label'>Activity Date/Time{errors.ActivityDateError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ActivityDateError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 ">
                        <DatePicker
                            name='activitydate'
                            id='activitydate'
                            onChange={(date) => {
                                setactivitydate(date); setValue({ ...value, ['LastSeenDtTm']: date ? getShowingMonthDateYear(date) : null, });

                            }}
                            isClearable={activitydate ? true : false}
                            selected={activitydate}
                            placeholderText={activitydate ? activitydate : 'Select...'}
                            dateFormat="MM/dd/yyyy HH:mm"
                            timeFormat="HH:mm "
                            is24Hour
                            filterTime={filterPassedTime}
                            timeInputLabel
                            showTimeSelect
                            timeIntervals={1}
                            timeCaption="Time"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            showDisabledMonthNavigation
                            autoComplete='off'
                            // maxDate={new Date()}
                            // className='requiredColor'
                            disabled={selectedOption === null || selectedOption === ''}
                            className={selectedOption === null || selectedOption === '' ? 'readonlyColor' : colourStyles}
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-1 mt-2 px-1">
                        <label htmlFor="" className='new-label'>Investigator{errors.InvestigatorError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.InvestigatorError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 mt-1">
                        <Select
                            name='InvestigatorID'
                            value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.InvestigatorID)}
                            isClearable
                            options={agencyOfficerDrpData}
                            onChange={(e) => ChangeDropDown(e, 'InvestigatorID')}
                            placeholder="Select..."
                            isDisabled={selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy'}
                        // styles={colourStyles}
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-1 mt-2">
                        <label htmlFor="" className='new-label'>Property&nbsp;Person{errors.PropertyError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.PropertyError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 mt-1">
                        <Select
                            name='OtherPersonNameID'
                            value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.OtherPersonNameID)}
                            isClearable
                            options={agencyOfficerDrpData}
                            onChange={(e) => ChangeDropDown(e, 'OtherPersonNameID')}
                            placeholder="Select..."
                            styles={selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy' ? 'readonlyColor' : colourStyles}
                            isDisabled={selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy'}
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-2 px-1">
                        <label htmlFor="" className='new-label'>Expected Return Date{errors.ExpectedDateError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ExpectedDateError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 ">
                        <DatePicker
                            name='ExpectedDate'
                            id='ExpectedDate'
                            onKeyDown={(e) => {
                                if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ' || e.key === 'F5')) {
                                    e?.preventDefault();
                                }
                            }}
                            onChange={(date) => {
                                setExpecteddate(date); setValue({ ...value, ['ExpectedDate']: date ? getShowingMonthDateYear(date) : null, });

                            }}
                            isClearable={expecteddate ? true : false}
                            selected={expecteddate}
                            placeholderText={expecteddate ? expecteddate : 'Select...'}
                            dateFormat="MM/dd/yyyy"
                            filterTime={filterPassedTime}
                            // timeInputLabel
                            // showTimeSelect
                            timeIntervals={1}
                            timeCaption="Time"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            showDisabledMonthNavigation
                            autoComplete='off'
                            // maxDate={new Date()}
                            className={value.IsCheckIn || value.IsRelease || value.IsDestroy || value.IsTransferLocation || value.IsUpdate || selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy' ? 'readonlyColor' : ''}
                            disabled={value.IsCheckIn || value.IsRelease || value.IsDestroy || value.IsTransferLocation || value.IsUpdate || selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy'}

                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-1 mt-2 px-1">
                        <label htmlFor="" className='new-label'>Officer Name{errors.OfficerNameError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.OfficerNameError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 mt-1">
                        <Select
                            name='OfficerNameID'
                            value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.OfficerNameID)}
                            isClearable
                            options={agencyOfficerDrpData}
                            onChange={(e) => ChangeDropDown(e, 'OfficerNameID')}
                            placeholder="Select..."
                            styles={selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy' ? 'readonlyColor' : colourStyles}
                            isDisabled={selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy'}
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                        <label htmlFor="" className='new-label'>Location{errors.LocationError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.LocationError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-4 col-md-8 col-lg-6 text-field mt-1">
                        <input type="text" name="location" style={{ position: 'relative' }} id="StorageLocationID" value={locationStatus ? '' : value.location} disabled className={(value.IsCheckIn || value.IsTransferLocation)
                            ? 'requiredColor'
                            : (selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy')
                                ? 'readonlyColor'
                                : ''} />

                        {value.location ? (
                            <span style={{ position: 'absolute', top: '40%', right: '10px', transform: 'translateY(-50%)' }} className='select-cancel' onClick={handleClickedCleared}>
                                <i className='fa fa-times'></i>
                            </span>
                        ) : (null)}
                    </div>
                    <div className="col-1 pt-1" >
                        <button disabled={!rowClicked || selectedOption === null}
                            className=" btn btn-sm bg-green text-white" data-toggle="modal" data-target="#PropertyRoomTreeModal" style={{ cursor: 'pointer' }} onClick={() => {
                                setlocationStatus(true);
                            }}>
                            <i className="fa fa-plus" > </i>
                        </button>
                    </div>
                    <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                        <label htmlFor="" className='new-label'>Name{errors.NameError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.NameError}</p>
                        ) : null}</label>
                    </div>
                    <div className='d-flex col-3 col-md-8 col-lg-3'>
                        <div className="col-4 col-md-12 col-lg-11  mt-1">
                            <Select
                                name='OwnerNameID'
                                options={mastersNameDrpData}
                                value={mastersNameDrpData?.filter((obj) => obj.value === value?.PropertyRoomPersonNameID)}
                                isClearable={value?.OwnerNameID ? true : false}
                                onChange={(e) => ChangeDropDown(e, 'PropertyRoomPersonNameID')}
                                placeholder="Select..."
                                isDisabled={value.IsCheckIn || value.IsCheckOut || value.IsDestroy || value.IsUpdate || value.IsTransferLocation || selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy'}
                                styles={value.IsCheckIn || value.IsCheckOut || value.IsDestroy || value.IsUpdate || value.IsTransferLocation || selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy' ? 'readonlyColor' : colourStyles}

                            />
                            {/* <Select
                                name='Investigator'

                                isClearable
                                placeholder="Select..."
                                // isDisabled={isCheckInSelected}
                                isDisabled={value.IsCheckIn || value.IsCheckOut || value.IsDestroy || value.IsUpdate || value.IsTransferLocation}
                                styles={value.IsCheckIn || value.IsCheckOut || value.IsDestroy || value.IsUpdate || value.IsTransferLocation ? 'readonlyColor' : 'requiredColor'}
                            /> */}
                        </div>
                        <div className="col-1 pt-1" data-toggle="modal" data-target="#MasterModal" style={{ cursor: 'pointer' }}>
                            <button disabled={!rowClicked || selectedOption === null} onClick={() => {
                                if (possessionID) { GetSingleDataPassion(possessionID); } setNameModalStatus(true);
                            }}
                                className=" btn btn-sm bg-green text-white py-1"   >
                                <i className="fa fa-plus" > </i>
                            </button>
                        </div>
                    </div>
                    <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                        <label htmlFor="" className='new-label'>Comments</label>
                    </div>
                    <div className="col-9 col-md-9 col-lg-11 text-field mt-1">
                        <input type="text" name="ActivityComments" disabled={!rowClicked} className={selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy' ? 'readonlyColor' : ''} value={value.ActivityComments} onChange={(e) => { handleChange(e) }} />
                    </div>
                    <div className="col-3 col-md-3 col-lg-1 mt-2 px-0">
                        <label htmlFor="" className='new-label px-0'>Misc&nbsp;Description</label>
                    </div>
                    <div className="col-9 col-md-9 col-lg-11 text-field mt-1">
                        <input type="text" name="ActivityComments" value={description} disabled className='readonlyColor' />
                    </div>
                </div>
            </div >
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-0 pp" >
                <fieldset>
                    <legend>Schedule</legend>
                    <div className="row px-0">
                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                            <label htmlFor="" className='new-label'>Court Date{errors.CourtDateError !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.CourtDateError}</p>
                            ) : null}</label>
                        </div>
                        <div className="col-3 col-md-3 col-lg-3 px-0">
                            <DatePicker
                                name='CourtDate'
                                id='CourtDate'
                                onKeyDown={(e) => {
                                    if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ' || e.key === 'F5')) {
                                        e?.preventDefault();
                                    }
                                }}
                                onChange={(date) => {
                                    setCourtdate(date);
                                    setValue({
                                        ...value,
                                        ['CourtDate']: date ? getShowingMonthDateYear(date) : null,
                                    });
                                    if (destroydate && new Date(destroydate) < new Date(date)) {
                                        setdestroydate(null);
                                        setValue({
                                            ...value,
                                            ['DestroyDate']: null,
                                        });
                                    }
                                }}
                                isClearable={!!courtdate}
                                selected={courtdate}
                                placeholderText={courtdate ? courtdate : 'Select...'}
                                dateFormat="MM/dd/yyyy"
                                filterTime={filterPassedTime}
                                // timeInputLabel
                                // showTimeSelect
                                timeIntervals={1}
                                timeCaption="Time"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                showDisabledMonthNavigation
                                autoComplete='off'
                                minDate={new Date()}
                                maxDate={value.ReleaseDate ? new Date(value?.ReleaseDate) : ''}

                                // className='requiredColor'
                                disabled={value.IsCheckOut || value.IsDestroy || value.IsTransferLocation || selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy'}
                                className={value.IsCheckOut || value.IsDestroy || value.IsTransferLocation || selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy' ? 'readonlyColor' : ''}

                            />
                        </div>
                        <div className="col-3 col-md-3 col-lg-2 mt-2 px-1">
                            <label htmlFor="" className='new-label'>Release Date{errors.ReleaseDateError !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ReleaseDateError}</p>
                            ) : null}</label>
                        </div>
                        <div className="col-3 col-md-3 col-lg-2 ">
                            <DatePicker
                                name='ReleaseDate'
                                id='ReleaseDate'
                                onKeyDown={(e) => {
                                    if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ' || e.key === 'F5')) {
                                        e?.preventDefault();
                                    }
                                }}
                                onChange={(date) => {
                                    setreleasedate(date); setValue({ ...value, ['ReleaseDate']: date ? getShowingMonthDateYear(date) : null, });

                                }}
                                isClearable={releasedate ? true : false}
                                selected={releasedate}
                                placeholderText={releasedate ? releasedate : 'Select...'}
                                dateFormat="MM/dd/yyyy"
                                filterTime={filterPassedTime}
                                // timeInputLabel
                                // showTimeSelect
                                timeIntervals={1}
                                timeCaption="Time"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                showDisabledMonthNavigation
                                autoComplete='off'
                                minDate={courtdate ? new Date(courtdate) : new Date()}
                                disabled={value.IsCheckOut || value.IsDestroy || value.IsTransferLocation || selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy'}
                                className={value.IsCheckOut || value.IsDestroy || value.IsTransferLocation || selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy' ? 'readonlyColor' : ''}

                            />
                        </div>
                        <div className="col-3 col-md-3 col-lg-1 mt-2 px-1">
                            <label htmlFor="" className='new-label'>Destroy&nbsp;Date{errors.DestroyDateError !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DestroyDateError}</p>
                            ) : null}</label>
                        </div>
                        <div className="col-3 col-md-3 col-lg-3 ">
                            <DatePicker
                                name='DestroyDate'
                                id='DestroyDate'
                                onKeyDown={(e) => {
                                    if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ' || e.key === 'F5')) {
                                        e?.preventDefault();
                                    }
                                }}
                                onChange={(date) => {
                                    setdestroydate(date);
                                    setValue({
                                        ...value,
                                        ['DestroyDate']: date ? getShowingMonthDateYear(date) : null,
                                    });
                                }}
                                isClearable={!!destroydate}
                                selected={destroydate}
                                placeholderText={destroydate ? destroydate : 'Select...'}
                                dateFormat="MM/dd/yyyy"
                                filterTime={filterPassedTime}
                                // timeInputLabel
                                // showTimeSelect
                                timeIntervals={1}
                                timeCaption="Time"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                showDisabledMonthNavigation
                                autoComplete='off'
                                minDate={courtdate ? new Date(courtdate) : new Date()}
                                disabled={value.IsCheckOut || value.IsRelease || value.IsTransferLocation || selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy'}
                                className={value.IsCheckOut || value.IsRelease || value.IsTransferLocation || selectedOption === null || selectedOption === '' || selectedStatus === 'Release' || selectedStatus === 'Destroy' ? 'readonlyColor' : ''}

                            />
                        </div>
                    </div >
                </fieldset>
            </div>
            <div className="col-12 col-md-12 col-lg-12    mt-1">
                <div className="row mb-1 px-0">
                    <div className="col-3 col-md-2 col-lg-1 mt-2">
                        <label htmlFor="" className='new-label'>Type</label>
                    </div>
                    <div className="col-4 col-md-3 col-lg-3 mt-1">
                        <Select
                            name='AddType'
                            value={selectedOptions}
                            // value={AddType.find(option => option.label === selectedOptions)}
                            onChange={(selectedOption) => {
                                setSelectedOptions(selectedOption);
                                setPropertyNumber('');
                                setsearcherror(prevValues => {
                                    return { ...prevValues, 'SearchError': '', }
                                })
                            }}
                            defaultValue={AddType[0]}
                            placeholder="Select..."
                            options={AddType}
                            menuPlacement='top'
                        />
                    </div>
                    {selectedOptions?.value === 'PropertyNumber' && (
                        <>
                            <div className="col-3 col-md-2 col-lg-2 mt-2 px-1">
                                <label htmlFor="" className='new-label'>Property No.{searcherror.SearchError !== 'true' ? (
                                    <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{searcherror.SearchError}</p>
                                ) : null}</label>
                            </div>
                            <div className="col-4 col-md-3 col-lg-2">
                                <div className="text-field mt-1">
                                    <input type="text" value={propertyNumber} onChange={handleInputChange} />
                                </div>
                            </div>
                        </>
                    )}
                    {selectedOptions?.value === 'StorageLocationID' && (
                        <>
                            <div className="col-3 col-md-2 col-lg-2 mt-2 px-1">
                                <label htmlFor="" className='new-label'>Location{searcherror.SearchError !== 'true' ? (
                                    <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{searcherror.SearchError}</p>
                                ) : null}</label>
                            </div>
                            <div className="col-4 col-md-3 col-lg-2 ">
                                <div className="text-field mt-1 " data-toggle="modal" data-target="#PropertyRoomTreeModal">
                                    <input type="text" value={searchStoragepath} onClick={() => {
                                        // setlocationStatus(true);
                                        setSearchStoStatus(true);
                                    }} />
                                </div>
                            </div>
                        </>
                    )}
                    {selectedOptions?.label === 'Barcode' && (
                        <>
                            <div className="col-3 col-md-2 col-lg-2 mt-2 px-1">
                                <label htmlFor="" className='new-label'>Barcode{searcherror.SearchError !== 'true' ? (
                                    <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{searcherror.SearchError}</p>
                                ) : null}</label>
                            </div>
                            <div className="col-4 col-md-3 col-lg-2">
                                <div className="text-field mt-1">
                                    <input type="text" value={propertyNumber} onChange={handleInputChange} />
                                </div>
                            </div>
                        </>
                    )}
                    {selectedOptions?.value === 'IncidentNumber' && (
                        <>
                            <div className="col-3 col-md-2 col-lg-2 mt-2 px-1">
                                <label htmlFor="" className='new-label'>Transaction Number{searcherror.SearchError !== 'true' ? (
                                    <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{searcherror.SearchError}</p>
                                ) : null}</label>
                            </div>
                            <div className="col-4 col-md-3 col-lg-2">
                                <div className="text-field mt-1">
                                    <input type="text" value={propertyNumber} onChange={handleInputChange} />
                                </div>
                            </div>
                        </>
                    )}


                    {selectedOptions?.value === 'PropertyTypeID' && (
                        <>
                            <div className="col-3 col-md-2 col-lg-2 mt-2 px-1">
                                <label htmlFor="" className='new-label'>Property Type{searcherror.SearchError !== 'true' ? (
                                    <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{searcherror.SearchError}</p>
                                ) : null}</label>
                            </div>
                            <div className="col-4 col-md-3 col-lg-2">
                                <div className=" mt-1">
                                    <Select

                                        name='PropertyTypeID'
                                        value={propertyTypeData?.filter((obj) => obj.value === value?.PropertyTypeID)}
                                        options={propertyTypeData}
                                        onChange={(e) => ChangeDropDowns(e, 'PropertyTypeID')}
                                        isClearable
                                        placeholder="Select..."
                                    // isDisabled={propertyID || masterPropertyID ? true : false}
                                    />
                                </div>
                            </div>
                        </>

                    )}

                    {selectedOptions?.value === 'PropertyTag' && (
                        <>
                            <div className="col-3 col-md-2 col-lg-2 mt-2 px-1">
                                <label htmlFor="" className='new-label'>Property Tag{searcherror.SearchError !== 'true' ? (
                                    <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{searcherror.SearchError}</p>
                                ) : null}</label>
                            </div>
                            <div className="col-4 col-md-3 col-lg-2">
                                <div className="text-field mt-1">
                                    <input type="text" value={propertyNumber} onChange={handleInputChange} />
                                </div>
                            </div>
                        </>
                    )}

                    <div className="col-1 pt-2" data-toggle="modal" data-target="#MasterModal" style={{ cursor: 'pointer' }}>
                        <button
                            className=" btn btn-sm bg-green text-white py-0 px-1" onClick={(e) => { check_Validation_Errorr(); }}>
                            <i className="fa fa-search"> </i>
                        </button>
                    </div>
                    <div className="col-3 col-md-2 col-lg-1 mt-2">
                        <label htmlFor="" className='new-label'>Activity Type</label>
                    </div>
                    <div className="col-4 col-md-3 col-lg-2 mt-1">
                        <Select
                            name='AddType'
                            value={transfer ? AddTransfer.find(option => option.label === transfer) : null}
                            onChange={(selectedOption) => {
                                // settransfer(selectedOption.label);
                                settransfer(selectedOption ? selectedOption.label : null);

                            }}
                            // defaultValue={AddTransfer[0]}
                            placeholder="Select..."
                            options={AddTransfer}
                            isClearable
                            styles={{
                                control: (provided, { isFocused }) => ({
                                    ...provided,
                                    fontWeight: transfer ? 'bold' : 'normal',
                                    color: transfer ? 'black' : 'black',

                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    fontWeight: 'bold',
                                    color: 'black',
                                }),
                                option: (provided, { isSelected, isFocused }) => ({
                                    ...provided,
                                    fontWeight: isSelected ? 'bold' : isFocused ? 'bold' : 'normal',
                                    color: isSelected ? 'black' : isFocused ? 'black' : 'black',
                                }),
                            }}
                        />
                    </div>

                </div>
            </div>
            <div className="col-12 px-0 mt-2" >
                <DataTable
                    showHeader={true}
                    persistTableHead={true}
                    dense
                    columns={columns}
                    data={searchData}
                    highlightOnHover
                    responsive
                    customStyles={tableCustomStyles}
                    fixedHeader
                    fixedHeaderScrollHeight='100px'
                    pagination
                    paginationPerPage={'100'}
                    paginationRowsPerPageOptions={[100, 150, 200, 500]}
                    showPaginationBottom={100}
                    selectableRowsHighlight

                    selectableRows


                    // selectableRowSelected={row => selectedRows?.some(selected => selected.PropertyID === row.PropertyID)}

                    selectableRowSelected={row => (selectedRows || [])?.some(selected => selected?.PropertyID === row?.PropertyID)}
                    onSelectedRowsChange={(row) => { handleCheckboxChange(row); }}

                    // onRowClicked={(row) => { console.log(row) }}
                    // handleSelectedRows={(row) => { console.log(row) }}

                    noDataComponent={searchData ? "" : 'There are no data to display'}
                // clearSelectedRows={toggleClear}
                // selectedRows={selectedRows}

                />


            </div>
            <div className="div float-right">
                <div className=" col-12  mt-2 btn-box" >
                    <div className="row propertyroom-button" style={{ position: 'fixed', bottom: 5, right: '30px' }}>
                        <div className='mr-1 mt-1 ' style={{ width: '150px' }}>
                            <Select
                                name='Investigator'
                                styles={customStylesWithOutColor}
                                isClearable
                                placeholder="Export..."
                                menuPlacement='top'
                            />
                        </div>
                        <button type="button" className="btn btn-sm btn-success mr-2 mb-2 mt-1">
                            Email Report
                        </button>
                        <button type="button" className="btn btn-sm btn-success mr-2 mb-2 mt-1">
                            Print Barcode
                        </button>
                        <button type="button" className="btn btn-sm btn-success mr-2 mb-2 mt-1" onClick={GetChainCustodyReport} disabled={!selectedStatus}>
                            Chain Of Custody Report
                        </button>
                        <button type="button" className="btn btn-sm btn-success mr-2 mb-2 mt-1" onClick={printForm} disabled={selectedStatus !== 'Release'}>
                            Display Property Released Receipt
                        </button>
                        <button type="button" className="btn btn-sm btn-success mr-2 mb-2 mt-1">
                            Export
                        </button>
                        <button type="button" className="btn btn-sm btn-success mr-2 mb-2 mt-1 " onClick={onDashboardClose}>
                            Close
                        </button>
                        <button type="button" className="btn btn-sm btn-success mr-2 mb-2 mt-1" onClick={(e) => { check_Validation_Error(); }}>
                            Save
                        </button>
                        <button type="button" className="btn btn-sm btn-success mr-2 mb-2 mt-1" onClick={() => { setStatusFalse(); conditionalRowStyles(''); }}>
                            Clear
                        </button>
                    </div>
                </div>
            </div>
            <TreeModel {...{ proRoom, locationStatus, setlocationStatus, locationPath,setfunctiondone, setLocationPath, setSearchStoragePath, searchStoStatus, setSearchStoStatus, setStorageLocationID,value, setValue, setPropertyNumber }} />
            {/* <TreeComponent {...{ proRoom }} /> */}
            <MasterNameModel {...{ value, setValue, nameModalStatus, setNameModalStatus, loginPinID, loginAgencyID,type, possessionID, setPossessionID, possenSinglData, setPossenSinglData, GetSingleDataPassion }} />

            <PropertyReportRoom {...{ releasestatus, setReleaseStatus, editval, componentRef }} />
            <ChainOfModel {...{ componentRefnew, chainreport }} />
        </>
    )
}

export default Home