import React, { useContext, useEffect, useState } from 'react'
import { AgencyContext } from '../../../../../Context/Agency/Index';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Decrypt_Id_Name, DecryptedList, base64ToString, decryptBase64, getShowingMonthDateYear, getShowingWithOutTime } from '../../../../Common/Utility';
import { Link, useLocation } from 'react-router-dom';
import { AddDeleteUpadate, ScreenPermision, fetchPostData } from '../../../../hooks/Api';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';
import IdentifyFieldColor from '../../../../Common/IdentifyFieldColor';
import PropListng from '../../../ShowAllList/PropListng';
import ChangesModal from '../../../../Common/ChangesModal';
import { get_ScreenPermissions_Data } from '../../../../../redux/actions/IncidentAction';


const MiscellaneousInformation = (props) => {

    const { ListData, DecPropID, DecMPropID, DecIncID ,propertystatus, setPropertyStatus } = props
    const { setChangesStatus } = useContext(AgencyContext);

    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    let MstPage = query?.get('page');
    const [mainIncidentID, setMainIncidentID] = useState('');
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID,] = useState('');
    const [editval, setEditval] = useState([]);
    const [updateCount, setUpdateCount] = useState(0);
    const [statesChangeStatus, setStatesChangeStatus] = useState(false);

    const [value, setValue] = useState({
        'MasterPropertyID': '', 'PropertyID': '', 'AgencyID': '', 'IncidentID': '', 'DestroyDtTm': '', 'PropertyTag': '', 'NICB': '', 'Description': '',
        'IsSendToPropertyRoom': '', 'ModifiedByUserFK': '', 'IsMaster': MstPage === "MST-Property-Dash" ? true : false,
    });

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginPinID(localStoreData?.PINID); setLoginAgencyID(localStoreData?.AgencyID);
            // dispatch(get_ScreenPermissions_Data("", localStoreData?.AgencyID, localStoreData?.PINID));
        }
    }, [localStoreData]);

    useEffect(() => {
        if (DecPropID || DecMPropID) {
            setMainIncidentID(DecIncID); GetSingleData(DecPropID, DecMPropID, loginPinID)
            setValue({
                ...value,
                'MasterPropertyID': DecMPropID, 'PropertyID': DecPropID, 'IncidentID': DecIncID, 'OfficerID': loginPinID,
                'CreatedByUserFK': loginPinID, 'AgencyID': loginAgencyID,
            });
        }
    }, [DecPropID]);

    const GetSingleData = (propertyID, masterPropertyID, loginPinID) => {
        const val = { PropertyID: propertyID, MasterPropertyID: masterPropertyID, 'PINID': loginPinID, 'IsMaster': MstPage === "MST-Property-Dash" ? true : false, }
        fetchPostData('Property/GetSingleData_Property', val).then((res) => {
            if (res) {
      
                setEditval(res);
            }
            else { setEditval([]); }
        })
    }

    const Update_Name = () => {
        const { MasterPropertyID, MProId, PropertyID, ProId, AgencyID, IncidentID, IncID,
            DestroyDtTm, PropertyTag, NICB, Description, IsSendToPropertyRoom, ModifiedByUserFK, IsMaster } = value;
        const val = {
            'CreatedByUserFK': loginPinID, 'MasterPropertyID': MasterPropertyID, 'PropertyID': PropertyID, 'AgencyID': loginAgencyID, 'IsMaster': IsMaster,
            'IncidentID': IncidentID, 'IsSendToPropertyRoom': IsSendToPropertyRoom, 'ModifiedByUserFK': loginPinID,
            'DestroyDtTm': DestroyDtTm, 'PropertyTag': PropertyTag, 'NICB': NICB, 'Description': Description,
        }
        AddDeleteUpadate('Property/Update_MiscellaneousInformation', val).then((res) => {
            if (res?.success) {
                const parsedData = JSON.parse(res.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message);
                GetSingleData(PropertyID, MasterPropertyID, loginPinID);
                setChangesStatus(false); setStatesChangeStatus(false);
            }
        })
    }

    useEffect(() => {
        if (editval?.length > 0) {
         
            setValue({
                ...value,
                'MasterPropertyID': DecMPropID, 'PropertyID': DecPropID,
                'DestroyDtTm': editval[0]?.DestroyDtTm ? getShowingWithOutTime(editval[0]?.DestroyDtTm) : null,
                'Description': editval[0]?.Description, 'NICB': editval[0]?.NICB, 'PropertyTag': editval[0]?.PropertyTag,
                'IsSendToPropertyRoom': editval[0]?.IsSendToPropertyRoom,
            })
            if (editval[0]?.IsSendToPropertyRoom) {
                setPropertyStatus(true);
            }
            else{
                setPropertyStatus(false);
            }
        }
    }, [editval, updateCount])
    
    const HandleChanges = (e) => {
        setStatesChangeStatus(true)
        if (e.target.name === 'IsSendToPropertyRoom') {
            setChangesStatus(true)
            setValue({
                ...value,
                [e.target.name]: e.target.checked
            })
        } else {
            setChangesStatus(true)
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
           
        }
    }

    const Reset = (e) => {
        setValue({ ...value, 'DestroyDtTm': '', 'PropertyTag': '', 'NICB': '', 'Description': '', 'IsSendToPropertyRoom': '', });
        setStatesChangeStatus(false);
        if (editval[0]?.Description?.length > 0) {
            setUpdateCount(updateCount + 1)
        }
    }

    return (
        <>
            <PropListng {...{ ListData }} />
            <div className="col-12 " id="display-not-form">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 pt-1 p-0">
                        <fieldset>
                            <legend>Miscellaneous Information</legend>
                            <div className="row">
                                <div className="col-2 col-md-2 col-lg-2  mt-3">
                                    <label htmlFor="" className='new-label'>Tag #</label>
                                </div>
                                <div className="col-10 col-md-10 col-lg-3 text-field mt-2">
                                    <input type='text' name='PropertyTag' id='PropertyTag' value={value?.PropertyTag} onChange={HandleChanges} className='readonlyColor' required readOnly />
                                </div>
                                <div className="col-2 col-md-2 col-lg-3  mt-3">
                                    <label htmlFor="" className='new-label'>NICB #</label>
                                </div>
                                <div className="col-10 col-md-10 col-lg-4 text-field mt-2">
                                    <input type='text' name='NICB' id='NICB' value={value?.NICB} onChange={HandleChanges} className='readonlyColor' required readOnly />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2 col-md-2 col-lg-2 mt-3">
                                    <label htmlFor="" className='new-label'>Destroy Date</label>
                                </div>
                                <div className="col-10 col-md-10 col-lg-3 ">
                                    <DatePicker
                                        id='DestroyDtTm'
                                        name='DestroyDtTm'
                                        // ref={startRef3}
                                        // onKeyDown={onKeyDown}
                                        className='readonlyColor'
                                        onChange={(date) => { setValue({ ...value, ['DestroyDtTm']: date ? getShowingMonthDateYear(date) : null }) }}
                                        dateFormat="MM/dd/yyyy HH:mm"
                                        timeInputLabel
                                        isClearable={value?.DestroyDtTm ? true : false}
                                        selected={value?.DestroyDtTm && new Date(value?.DestroyDtTm)}
                                        placeholderText={value?.DestroyDtTm ? value.DestroyDtTm : 'Select...'}
                                        showTimeSelect
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        readOnly
                                        required
                                        autoComplete="nope"
                                        showYearDropdown
                                        showMonthDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <div className="col-2 col-md-2 col-lg-3  mt-2">
                                    <label htmlFor="" className='new-label'>Description</label>
                                </div>
                                <div className="col-8 col-md-8 col-lg-4 mt-1">
                                    <textarea name='Description' id="Description" value={value?.Description} onChange={HandleChanges} className="form-control" cols={30} rows={1} maxLength={250}>
                                    </textarea>
                                </div>
                            </div>
                            <div className="row ml-5 pl-4">
                                <div className="col-12 col-md-12 col-lg-12  mt-3">
                                    <div className="form-check">
                                        <input className="form-check-input" name='IsSendToPropertyRoom' value={value?.IsSendToPropertyRoom} onChange={HandleChanges} checked={value?.IsSendToPropertyRoom} type="checkbox" id="flexCheckDefault" />
                                        <label className="form-check-label" for="flexCheckDefault1">
                                            Send To Property Room
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div className="col-12 col-md-12 col-lg-12 mt-2 mb-1 text-right">
                            {
                                effectiveScreenPermission ?
                                    effectiveScreenPermission[0]?.Changeok ?
                                        <button type="button" disabled={!statesChangeStatus} className="btn btn-md py-1 btn-success " onClick={Update_Name}>Update</button>
                                        :
                                        <>
                                        </>
                                    :
                                    <button type="button" disabled={!statesChangeStatus} className="btn btn-md py-1 btn-success " onClick={Update_Name}>Update</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <IdentifyFieldColor />
            <ChangesModal func={Update_Name} setToReset={Reset} />
        </>
    )
}

export default MiscellaneousInformation