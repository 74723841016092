export const NoneUnknownErrorStr = "Property Loss must be either 'None' or 'Unknown'";

export const ErrorTooltip = ({ ErrorStr }) => (<span className='hovertext' style={{ marginLeft: '15px' }} data-hover={ErrorStr} ><i className='fa fa-exclamation-circle'></i></span>);

export const ErrorShow = ({ ErrorStr }) => (<span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{ErrorStr}</span>);

export const NibrsErrorStyle = (status) => {
    const colour_Styles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: status ? "rgb(255 202 194)" : "#fce9bf",
            height: 20,
            minHeight: 32,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };
    return colour_Styles;
};

export const check_OffenceCode_NoneUnknown = (code) => {
    if (
        (
            code === '100' ||
            code === '35' ||
            code === '521' ||
            code === '522' ||
            code === '526' ||
            code === '26H'
        ) && process.env.REACT_APP_NIBRS_STATE === 'Rajasthan'
    ) { return true; }
    else return false;
}