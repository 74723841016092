import React, {useState } from 'react'
import { Link } from 'react-router-dom'

const MobileTab = () => {

    const [active, setActive] = useState(window.location.pathname);

    return (
        <>
            <div className="col-12 mobile__tabs" >
                <ul className="nav nav-tabs">
                    <li className="nav-item ">
                        <Link className={`nav-link    ${active === `/mobile-incident` && 'active'}`} data-toggle="pill" to={`/mobile-incident`}>Incident</Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link     ${active === `/offense-main` && 'active' || active === `/mobile-offense` && 'active'}`} data-toggle="pill" to={`/offense-main`}>Offense</Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link    ${active === `/name-main` && 'active' || active === `/mobile-name` && 'active'}`} data-toggle="pill" to={`/name-main`} >Name</Link>
                    </li>
                    <li className="nav-item ">
                        <Link className={`nav-link    ${active === `/property-main` && 'active' || active === `/mobile-property` && 'active'}`} data-toggle="pill" to={`/property-main`} >Property</Link>
                    </li>
                    <li className="nav-item ">
                        <Link className={`nav-link   ${active === '/vehicle-main' && 'active' || active === '/mobile-vehicle' && 'active'} `} data-toggle="pill" to={`/vehicle-main`} >Vehicle</Link>
                    </li>
                    {/* with  incident id  -- code cmment by Dk for use in fature when we use incidentID */}
                    {/* <li className="nav-item ">
                        <Link className={`nav-link  ${('IncidentId') ? '' : 'disabled'}   ${active === `/offense-main` ? 'active' : '' || active === `/mobile-offense`? 'active' : ''}`} data-toggle="pill" to={`/offense-main`}>Offense</Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link  ${('IncidentId') ? '' : 'disabled'}  ${active === `/name-main` ? 'active' : ''|| active === `/mobile-name`?   'active' : ''}`} data-toggle="pill" to={`/name-main`} >Name</Link>
                    </li>
                    <li className="nav-item ">
                        <Link className={`nav-link  ${('IncidentId') ? '' : 'disabled'}  ${active === `/property-main` ? 'active' : ''|| active === `/mobile-property`?   'active' : ''}`} data-toggle="pill" to={`/property-main`} >Property</Link>
                    </li>
                    <li className="nav-item ">
                        <Link className={`nav-link  ${('IncidentId') ? '' : 'disabled'} ${active === '/vehicle-main' ? 'active' :'' || active === '/mobile-vehicle'? 'active': ''} `} data-toggle="pill" to={`/vehicle-main`} >Vehicle</Link>
                    </li> */}
                </ul>
            </div>
        </>
    )
}

export default MobileTab