import React, { useRef } from 'react'
import img from '../../../../../src/img/images1.jpg'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { Decrypt_Id_Name, colourStyles, customStylesWithOutColor, getShowingDateText, getShowingWithOutTime } from '../../../Common/Utility';
import Select from "react-select";
import { useEffect } from 'react';
import { fetchData, fetchPostData } from '../../../hooks/Api';
import { useReactToPrint } from 'react-to-print';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';
import { toastifyError } from '../../../Common/AlertMsg';
import Loader from '../../../Common/Loader';
import ReportAddress from '../../ReportAddress/ReportAddress';
import DOMPurify from 'dompurify';


const ArrestMaster = () => {

    const dispatch = useDispatch();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);

    const [reportData, setReportData] = useState([]);
    const [verifyArrestMaster, setverifyArrestMaster] = useState(false);
    const [Arrestfromdate, setArrestfromdate] = useState('')
    const [ArrestTodate, setArrestTodate] = useState('')
    const [masterReportData, setMasterReportData] = useState([]);
    const [LoginAgencyID, setLoginAgencyID] = useState('');
    const [LoginPinID, setLoginPinID,] = useState('');
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [zipList, setZipList] = useState([]);
    const [multiImage, setMultiImage] = useState([]);
    const [loder, setLoder] = useState(false);

    const [value, setValue] = useState({
        AgencyID: '', ArrestNumber: '', ArrestDtTmTo: '', ArrestDtTm: '', IncidentNumber: '', NameIDNumber: '',
        LastName: '', FirstName: '', MiddleName: '', DateOfBirthFrom: '', DateOfBirthTo: '', DirectionPrefix: '', Street: '',
        DirectionSufix: '', TypeSufix: '', PremiseNo: '', ApartmentNo: '', CommonPlace: '',
        ApartmentType: '', Street_Parse: '', PremiseNo_Parse: '', DirectionPrefix_Parse: '', TypeSuffix_Parse: '',
        DirectionSuffix_Parse: '', IsUsLocation: '', point_of_interest: '', Location: '',
        neighborhood: '', subpremise: '', premise: '', Statefullname: '',
        CountryID: '', Country: '', State: '', City: '', CityID: '', ZipCodeID: '', ZipCode: '', SSN: '',
    });
    const [searchValue, setSearchValue] = useState({
        ArrestNumber: '',
        ArrestDtTmTo: '',
        ArrestDtTm: '',
        IncidentNumber: '',
        NameIDNumber: '',
        LastName: '',
        FirstName: '',
        MiddleName: '',
        DateOfBirthFrom: '',
        DateOfBirthTo: '',
        Location: '',
        SSN: '',

    });

    const [showFields, setShowFields] = useState({
        showArrestNumber: false,
        showArrestDtTm: false,
        showArrestDtTmTo: false,
        showIncidentNumber: false,
        showNameIDNumber: false,
        showLastName: false,
        showFirstName: false,
        showMiddleName: false,
        showDateOfBirthFrom: false,
        showDateOfBirthTo: false,
        showLocation: false,
        showSSN: false,
    });
    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);
    useEffect(() => {
        setShowFields({
            showArrestNumber: searchValue.ArrestNumber,
            showArrestDtTm: searchValue.ArrestDtTm,
            showArrestDtTmTo: searchValue.ArrestDtTmTo,
            showIncidentNumber: searchValue.IncidentNumber,
            showNameIDNumber: searchValue.NameIDNumber,
            showLastName: searchValue.LastName,
            showFirstName: searchValue.FirstName,
            showMiddleName: searchValue.MiddleName,
            showDateOfBirthFrom: searchValue.DateOfBirthFrom,
            showDateOfBirthTo: searchValue.DateOfBirthTo,
            showLocation: searchValue.Location,
            showSSN: searchValue.SSN,
        });
    }, [searchValue]);
    function stateChanges(e) {
        if (e) {
            setValue({
                ...value,
                ['State']: e.value,
                ['Statefullname']: e.label
            });
            getCity(e.value)
        } else {
            setValue({
                ...value,
                ['State']: null, ['ZipName']: '', ['CityName']: ''
            })
            setCityList([])
            setZipList([])
        }
    }

    function cityChanges(e) {
        if (e) {
            setValue({
                ...value,
                ['CityID']: e.value,
                ['City']: e.label,
                ['ZipCodeID']: '',
                ['ZipName']: '',
            });
            getZipCode(e.value)
        } else {
            setValue({
                ...value,
                ['CityID']: null, ['ZipCodeID']: '',
            });
            setZipList()
        }
    }

    function zipChanges(e) {
        if (e) {
            setValue({
                ...value,
                ['ZipCodeID']: e.value, ['ZipCode']: e.label, ['ZipName']: { value: e.value, label: e.label }
            });
        } else {
            setValue({
                ...value,
                ['ZipCodeID']: null
            });
        }
    }

    const getStateList = async () => {
        fetchData("State_City_ZipCode/GetData_State").then(res => {
            if (res) {
                setStateList(changeArrayFormat(res, 'state'))
            }
            else setStateList([])
        })
    }

    const getCity = async (stateID) => {
        fetchPostData('State_City_ZipCode/GetData_City', { StateID: stateID }).then((res) => {
            if (res) {
                setCityList(changeArrayFormat(res, 'city'))
            }
            else setCityList(changeArrayFormat(res, 'city'))
        }).catch((error) => {
            console.error('There was an error!', error);
        });
    }

    const getZipCode = async (cityID) => {
        fetchPostData('State_City_ZipCode/GetData_ZipCode', { CityId: cityID }).then((res) => {
            if (res) {
                setZipList(changeArrayFormat(res, 'zip'))
            }
            else setZipList(changeArrayFormat(res, 'zip'))
        }).catch((error) => {
            console.error('There was an error!', error);
        });
    }


    // const onChangeArrestNumber = (e) => {
    //     if (e) {
    //         if (e.target.name === 'ArrestNumber') {
    //             let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    //             if (ele[0]?.match(/[a-zA-Z\s]/g)) {
    //                 let subs = ele.toUpperCase().replace(/[^a-zA-Z\s]/g, '').substring(0, 4);
    //                 let subs2 = ele.replace(/[^0-9]/g, '');
    //                 if (ele?.length <= 4) {
    //                     const alphabet = ele.toUpperCase().replace(/[^a-zA-Z\s]/g, '') || '';
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: alphabet
    //                     })
    //                 } else if (ele.length >= 15) {
    //                     e?.preventDefault()
    //                 } else if (subs2?.length >= 11) {
    //                     // console.log(subs2, 'asdasdas')
    //                 } else {
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: subs + '-' + subs2
    //                     })
    //                 }
    //             } else {
    //                 let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    //                 if (ele[0]?.match(/[0-9]/g)) {
    //                     const digits = ele.replace(/[^0-9]/g, '');
    //                     if (ele?.length <= 2) {
    //                         setValue({
    //                             ...value,
    //                             [e.target.name]: digits
    //                         })
    //                     } else if (ele.length >= 9) {
    //                         e.preventDefault();
    //                     } else {
    //                         let subs = ele.substring(0, 2);
    //                         let subs2 = ele.substring(2).replace(/[^0-9]/g, '');
    //                         setValue({
    //                             ...value,
    //                             [e.target.name]: subs + '-' + subs2
    //                         })
    //                     }
    //                 } else {
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: e.target.value
    //                     })
    //                 }

    //             }
    //         }
    //     }
    // }

    // const onChangeArrestNumber = (e) => {
    //     if (e) {
    //         const { name, value } = e.target;
    //         if (name === 'ArrestNumber') {
    //             // Remove any characters that are not alphanumeric
    //             let sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
    //             // Extract the different parts of the input
    //             const alphaPart = sanitizedValue.substring(0, 4).toUpperCase();
    //             const numericPart = sanitizedValue.substring(4);

    //             let formattedValue = alphaPart;

    //             if (numericPart.length > 0) {
    //                 if (numericPart.length <= 2) {
    //                     // For up to 2 numeric characters, format as `ADUL-24`
    //                     formattedValue += '-' + numericPart;
    //                 } else if (numericPart.length <= 8) {
    //                     // For up to 8 numeric characters, format as `ADUL-24-000058`
    //                     const twoDigitPart = numericPart.substring(0, 2);
    //                     const sixDigitPart = numericPart.substring(2, 8);
    //                     formattedValue += '-' + twoDigitPart;
    //                     if (sixDigitPart.length > 0) {
    //                         formattedValue += '-' + sixDigitPart;
    //                     }
    //                 } else {
    //                     // For more than 8 numeric characters, handle the first 10 as `AJUV-2400000074`
    //                     const twoDigitPart = numericPart.substring(0, 2);
    //                     const eightDigitPart = numericPart.substring(2, 10);
    //                     formattedValue += '-' + twoDigitPart + eightDigitPart;
    //                 }
    //             }

    //             // Update the state with the formatted value
    //             setValue({
    //                 ...value,
    //                 [name]: formattedValue
    //             });
    //         }
    //     }
    // }

    const Handlechange = (e) => {
        if (e.target.name === 'SSN') {
            let ele = e.target.value.replace(/\D/g, '');
            if (ele.length === 9) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
                    })
                }
            } else {
                ele = e.target.value.split('-').join('').replace(/\D/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
            if (e.target.name === 'SSN') {
                return 'true';
            }
            if (e.target.name.length === 11) {
                return 'true'
            }
        }
        // else if (e.target.name === 'IncidentNumber') {
        //     let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
        //     if (ele.length === 8) {
        //         const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
        //         const match = cleaned.match(/^(\d{2})(\d{6})$/);
        //         if (match) { 
        //             setValue({
        //                 ...value,
        //                 [e.target.name]: match[1] + '-' + match[2]
        //             })
        //         }
        //     } else {
        //         ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
        //         setValue({
        //             ...value,
        //             [e.target.name]: ele
        //         })
        //     }
        // }
        else if (e.target.name === 'IncidentNumber') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Remove all non-alphanumeric characters
            if (ele.length === 8) {
                const match = ele.match(/^(\d{2})(\d{6})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2] // Format as needed
                    });
                }
            } else {
                ele = e.target.value.replace(/'/g, '').replace(/[^0-9]/g, ''); // Remove single quotes and non-numeric characters
                setValue({
                    ...value,
                    [e.target.name]: ele // Set cleaned value
                });
            }
        }
        else if (e.target.name === 'ArrestNumber' || e.target.name === 'ArrestNumberTo') {
            let ele = e.target.value.trim();
            setValue({
                ...value,
                [e.target.name]: ele
            });
            if (ele.length === 0) {
                e.target.name === 'ArrestNumber' && setValue({
                    ...value,
                    ['ArrestNumberTo']: "",
                    [e.target.name]: ele
                });
            }
        }

        else if (e.target.name === 'NameIDNumber') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
            if (ele.length <= 11) {
                const alphabet = ele[0]?.toUpperCase() || '';
                const digits = ele.slice(1).replace(/[^0-9]/g, '');
                // console.log(match)
                if (digits.length === 9) {
                    setValue({
                        ...value,
                        [e.target.name]: alphabet + '-' + digits
                    });
                } else {
                    setValue({
                        ...value,
                        [e.target.name]: alphabet + digits
                    });
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        }
        else if (e.target.name === 'Location' || e.target.name === 'LastName' || e.target.name === 'FirstName' || e.target.name === 'MiddleName') {
            // const checkInput = e.target.value.replace(/[^a-zA-Z0-9@#$%&*!.,]/g, "");
            const inputValue = e.target.value;
            const checkInput = inputValue.replace(/[^a-zA-Z0-9@#$%&*!.,\s]/g, "");
            const finalInput = inputValue.trim() === "" ? checkInput.replace(/\s/g, "") : checkInput;
            setValue({
                ...value,
                [e.target.name]: finalInput
            });
        }

        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
            setLoginPinID(localStoreData?.PINID);
            getStateList();
            getCity();
            getZipCode();
            getAgencyImg(localStoreData?.AgencyID);
        }
    }, [localStoreData])

    function hasValues(obj) {
        for (let key in obj) {
            // console.log(key)
            if (key != 'AgencyID' && key != 'PINID') {
                // console.log(obj[key])
                if (obj[key]) {
                    return true;
                }
            }
        }
        return false;
    }

    // const get_ArrestSearchData1 = async () => {
    //     setLoder(true);
    //     if (value?.ArrestDtTmTo?.trim()?.length > 0 || value?.SSN?.trim()?.length > 0 || value?.ArrestDtTm?.trim()?.length > 0 || value?.ArrestNumber?.trim()?.length > 0 || value?.IncidentNumber?.trim()?.length > 0 || value?.MiddleName?.trim()?.length > 0 || value?.LastName?.trim()?.length > 0 || value?.NameIDNumber?.trim()?.length > 0 || value?.DateOfBirthFrom?.trim()?.length > 0 || value?.DateOfBirthTo?.trim()?.length > 0 || value?.Location?.trim()?.length > 0 || value?.City?.trim()?.length > 0 || value?.ZipCodeID !== null || value?.CityID !== null || value?.ZipCode !== null) {
    //         const { AgencyID, ArrestNumber, ArrestDtTmTo, SSN, ArrestDtTm, IncidentNumber, NameIDNumber, LastName, FirstName, MiddleName, DateOfBirthFrom, DateOfBirthTo, DirectionPrefix, Street, DirectionSufix, TypeSufix, City, State, ZipCode, PremiseNo, ApartmentNo, CommonPlace, ApartmentType, Street_Parse, PremiseNo_Parse, DirectionPrefix_Parse, TypeSuffix_Parse, Address, DirectionSuffix_Parse, ZipCodeID, Location, CityID, IsUsLocation, CountryID, Country, point_of_interest, neighborhood, subpremise, premise, Statefullname
    //         } = value
    //         const val = {
    //             'AgencyID': LoginAgencyID, 'Location': Location, 'SSN': SSN, 'ArrestNumber': ArrestNumber, 'ArrestDtTmTo': ArrestDtTmTo, 'ArrestDtTm': ArrestDtTm, 'IncidentNumber': IncidentNumber, 'NameIDNumber': NameIDNumber?.trim(), 'LastName': LastName, 'FirstName': FirstName, 'MiddleName': MiddleName, 'DateOfBirthFrom': DateOfBirthFrom, 'DirectionPrefix': DirectionPrefix, 'Street': Street, 'DirectionSufix': DirectionSufix, 'TypeSufix': TypeSufix, 'City': City, 'State': State, 'ZipCode': ZipCode, 'ApartmentNo': ApartmentNo, 'CommonPlace': CommonPlace, 'ApartmentType': ApartmentType, 'Street_Parse': Street_Parse, 'PremiseNo_Parse': PremiseNo_Parse, 'DirectionPrefix_Parse': DirectionPrefix_Parse, 'TypeSuffix_Parse': TypeSuffix_Parse, 'DirectionSuffix_Parse': DirectionSuffix_Parse, 'ZipCodeID': ZipCodeID, 'CityID': CityID, 'IsUsLocation': IsUsLocation, 'CountryID': CountryID, 'Country': Country, 'point_of_interest': point_of_interest, 'neighborhood': neighborhood, 'premise': premise, 'Statefullname': Statefullname, 'DateOfBirthTo': DateOfBirthTo, 'Address': Address, 'PremiseNo': PremiseNo, 'subpremise': subpremise,
    //         }
    //         try {
    //             const res = await fetchPostData('ArrestReport/GetData_ArrestReport', val);
    //             if (res.length > 0) {
    //                 setReportData(res)
    //                 setArestReportData(res[0])
    //                 setverifyArrestMaster(true)
    //                 getAgencyImg(LoginAgencyID)
    //                 setSearchValue(value);
    //                 setLoder(false);
    //             }
    //             else {
    //                 toastifyError("Data Not Available"); setReportData([])
    //                 setArestReportData([]);
    //                 setverifyArrestMaster(false);
    //                 setLoder(false);

    //             }
    //         } catch (error) {
    //             toastifyError("Data Not Available");
    //             setverifyArrestMaster(false);
    //             setLoder(false);
    //         }

    //     } else {
    //         toastifyError("Please Enter Details");
    //         setLoder(false);

    //     }
    // }


    const get_ArrestSearchData = async () => {
        const {
            Location, SSN, ArrestNumber, ArrestDtTmTo, ArrestDtTm, IncidentNumber, NameIDNumber, LastName, FirstName, MiddleName, DateOfBirthFrom, DirectionPrefix, Street, DirectionSufix, TypeSufix, City, State, ZipCode, ApartmentNo, CommonPlace, ApartmentType, Street_Parse, PremiseNo_Parse, DirectionPrefix_Parse, TypeSuffix_Parse, DirectionSuffix_Parse, 'ZipCodeID': ZipCodeID, CityID, IsUsLocation, CountryID, Country, point_of_interest, neighborhood, premise, Statefullname, DateOfBirthTo, Address, PremiseNo, subpremise,
        } = value
        const val = {
            'AgencyID': LoginAgencyID, 'Location': Location?.trim(), 'SSN': SSN?.trim(), 'ArrestNumber': ArrestNumber?.trim(), 'ArrestDtTmTo': ArrestDtTmTo, 'ArrestDtTm': ArrestDtTm, 'IncidentNumber': IncidentNumber?.trim(), 'NameIDNumber': NameIDNumber, 'LastName': LastName?.trim(), 'FirstName': FirstName?.trim(), 'MiddleName': MiddleName?.trim(), 'DateOfBirthFrom': DateOfBirthFrom, 'DirectionPrefix': DirectionPrefix, 'Street': Street, 'DirectionSufix': DirectionSufix, 'TypeSufix': TypeSufix, 'City': City, 'State': State, 'ZipCode': ZipCode, 'ApartmentNo': ApartmentNo, 'CommonPlace': CommonPlace, 'ApartmentType': ApartmentType, 'Street_Parse': Street_Parse, 'PremiseNo_Parse': PremiseNo_Parse, 'DirectionPrefix_Parse': DirectionPrefix_Parse, 'TypeSuffix_Parse': TypeSuffix_Parse, 'DirectionSuffix_Parse': DirectionSuffix_Parse, 'ZipCodeID': ZipCodeID, 'CityID': CityID, 'IsUsLocation': IsUsLocation, 'CountryID': CountryID, 'Country': Country, 'point_of_interest': point_of_interest, 'neighborhood': neighborhood, 'premise': premise, 'Statefullname': Statefullname, 'DateOfBirthTo': DateOfBirthTo, 'Address': Address, 'PremiseNo': PremiseNo, 'subpremise': subpremise,
        }
        if (hasValues(val)) {
            fetchPostData('ArrestReport/GetData_ArrestReport', val).then((res) => {
                if (res.length > 0) {
                    console.log(res)
                    setReportData(res)
                    setMasterReportData(res[0])
                    setverifyArrestMaster(true)
                    getAgencyImg(LoginAgencyID)
                    setSearchValue(value);
                    setLoder(false);
                } else {
                    toastifyError("Data Not Available"); setReportData([])
                    setMasterReportData([]);
                    setverifyArrestMaster(false);
                    setLoder(false);
                }
            });
        }
        else {
            toastifyError("Please Enter Details");
        }
    }
    const getAgencyImg = (LoginAgencyID) => {
        const val = { 'AgencyID': LoginAgencyID }
        fetchPostData('Agency/GetDataAgencyPhoto', val).then((res) => {
            if (res) {
                let imgUrl = `data:image/png;base64,${res[0]?.Agency_Photo}`;
                setMultiImage(imgUrl);
            }
            else { console.log("errror") }
        })
    }


    useEffect(() => {
        if (masterReportData?.length > 0) {
            setverifyArrestMaster(true);
        } else {
            setverifyArrestMaster(false);
        }
    }, []);

    const resetFields = () => {
        setValue({
            ...value,
            AgencyID: '', ArrestNumber: '', ArrestDtTmTo: '', ArrestDtTm: '', IncidentNumber: '', NameIDNumber: '', Location: '',
            LastName: '', FirstName: '', MiddleName: '', DateOfBirthFrom: '', DateOfBirthTo: '', DirectionPrefix: '', Street: '',
            DirectionSufix: '', TypeSufix: '', City: '', State: '', ZipCode: '', PremiseNo: '', ApartmentNo: '', CommonPlace: '',
            ApartmentType: '', Street_Parse: '', PremiseNo_Parse: '', DirectionPrefix_Parse: '', TypeSuffix_Parse: '',
            DirectionSuffix_Parse: '', ZipCodeID: '', CityID: '', IsUsLocation: '', CountryID: '', Country: '', point_of_interest: '',
            neighborhood: '', subpremise: '', premise: '', Statefullname: ''
        })
        setverifyArrestMaster(false)
        setMasterReportData([]);
    }

    const componentRef = useRef();
    // const printForm = useReactToPrint({
    //     content: () => componentRef.current,
    //     documentTitle: 'Data',
    //     onAfterPrint: () => { '' }
    // })
    const printForm = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data',
        onBeforeGetContent: () => {
            setLoder(true);
        },
        onAfterPrint: () => {
            setLoder(false);
        }
    });
    return (
        <>
            <div class="section-body view_page_design pt-3">
                <div className="row clearfix">
                    <div className="col-12 col-sm-12">
                        <div className="card Agency">
                            <div className="card-body">
                                <fieldset>
                                    <legend>Arrest Master Report</legend>
                                    <div className="row">

                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Arrest From Date</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2">
                                            <DatePicker
                                                id='ArrestDtTm'
                                                name='ArrestDtTm'
                                                dateFormat="MM/dd/yyyy"
                                                isClearable={value?.ArrestDtTm ? true : false}
                                                // selected={ArrestDtTm}
                                                // peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                maxDate={new Date()}
                                                selected={value?.ArrestDtTm && new Date(value?.ArrestDtTm)}
                                                // ref={startRef}
                                                // onKeyDown={onKeyDown}
                                                onChange={(date) => { setValue({ ...value, ['ArrestDtTm']: date ? getShowingDateText(date) : null, ['ArrestDtTmTo']: null }) }}
                                                timeInputLabel
                                                placeholderText='Select...'
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Arrest To Date</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2">
                                            <DatePicker
                                                name='ArrestDtTmTo'
                                                id='ArrestDtTmTo'
                                                onChange={(date) => { setValue({ ...value, ['ArrestDtTmTo']: date ? getShowingDateText(date) : null }) }}
                                                selected={value?.ArrestDtTmTo && new Date(value?.ArrestDtTmTo)}
                                                dateFormat="MM/dd/yyyy"
                                                timeInputLabel
                                                // ref={startRef1}
                                                // onKeyDown={onKeyDown}
                                                isClearable={value?.ArrestDtTmTo ? true : false}
                                                // peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                maxDate={new Date()}
                                                placeholderText='Select...'
                                                minDate={new Date(value?.ArrestDtTm)}
                                                disabled={value?.ArrestDtTm ? false : true}
                                                className={!value?.ArrestDtTm && 'readonlyColor'}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Arrest #</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-2">
                                            <input type="text" name='ArrestNumber' id='ArrestNumber' className='' value={value?.ArrestNumber} onChange={Handlechange} />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Incident #</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='IncidentNumber' id='IncidentNumber' maxLength={9} className='' value={value?.IncidentNumber} onChange={Handlechange} />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Location</label>
                                        </div>
                                        <div className="col-9 col-md-9 col-lg-10 mt-1 text-field">
                                            <input type="text" name='Location' value={value?.Location} id='Location' onChange={Handlechange} className='' />

                                            {/* <Location {...{ setValue, value, locationStatus, setLocationStatus, updateStatus, setOnSelectLocation }} col='Location' locationID='crimelocationid' check={true} verify={true} /> */}
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend>Name Information</legend>
                                    <div className="row">
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>MNI</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='NameIDNumber' id='NameIDNumber' maxLength={11} className='' value={value?.NameIDNumber} onChange={Handlechange} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Last Name</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-2">
                                            <input type="text" name='LastName' id='LastName' value={value?.LastName}
                                                onChange={Handlechange} />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>First Name</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='FirstName' id='FirstName' value={value?.FirstName}
                                                onChange={Handlechange} />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Middle Name</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='MiddleName' id='MiddleName' value={value?.MiddleName} onChange={Handlechange} />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>SSN</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='SSN' id='SSN' maxLength={9} value={value?.SSN} onChange={Handlechange} />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>DOB From</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 ">
                                            <DatePicker
                                                id='DateOfBirthFrom'
                                                name='DateOfBirthFrom'
                                                dateFormat="MM/dd/yyyy"
                                                onChange={(date) => {
                                                    if (date) {
                                                        setValue({ ...value, ['DateOfBirthFrom']: date ? getShowingWithOutTime(date) : "" })
                                                    } else {
                                                        setValue({ ...value, ['DateOfBirthFrom']: null, ['DateOfBirthTo']: null })
                                                    }
                                                }}
                                                isClearable={value.DateOfBirthFrom ? true : false}
                                                selected={value?.DateOfBirthFrom && new Date(value.DateOfBirthFrom)}
                                                placeholderText={'Select...'}
                                                // peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                maxDate={new Date()}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>DOB To</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 ">
                                            <DatePicker
                                                id='DateOfBirthTo'
                                                name='DateOfBirthTo'
                                                dateFormat="MM/dd/yyyy"
                                                onChange={(date) => setValue({ ...value, ['DateOfBirthTo']: date ? getShowingWithOutTime(date) : "" })}
                                                isClearable={value.DateOfBirthTo ? true : false}
                                                selected={value?.DateOfBirthTo && new Date(value.DateOfBirthTo)}
                                                placeholderText={'Select...'}
                                                // peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                maxDate={new Date()}
                                                minDate={new Date(value.DateOfBirthFrom)}
                                                disabled={value?.DateOfBirthFrom ? false : true}
                                                className={!value?.DateOfBirthFrom && 'readonlyColor'}
                                            />
                                        </div>
                                    </div>

                                </fieldset>
                                <div className="col-12 col-md-12 col-lg-12 mt-2 pt-1 text-right">
                                    <button className="btn btn-sm bg-green text-white px-2 py-1" onClick={() => { get_ArrestSearchData(); }} >Show Report</button>
                                    <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { setverifyArrestMaster(false); resetFields(); }}>Clear</button>
                                    <Link to={'/Reports'}>
                                        <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" >Close</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                verifyArrestMaster &&
                <>
                    <div className="col-12 col-md-12 col-lg-12 pt-2  px-2" >
                        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                            <p className="p-0 m-0 d-flex align-items-center">Arrest Master Report</p>
                            <div style={{ marginLeft: 'auto' }}>
                                <Link to={''} className="btn btn-sm bg-green  mr-2 text-white px-2 py-0"  >
                                    <i className="fa fa-print" onClick={printForm}></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-1" ref={componentRef}>
                        <div className="row" style={{ border: '1px solid #80808085' }}>
                            <>
                                <ReportAddress {...{ multiImage, masterReportData }} />

                                {/* <div className="col-4 col-md-3 col-lg-2 pt-1 ml-3">
                                    <div className="main">
                                        <img src={multiImage} className="" alt='Agency_Photo' style={{ marginTop: '4px', width: '150px', height: '150px' }} />
                                    </div>
                                </div> */}
                                {/* <div className="col-7  col-md-7 col-lg-9 mt-2 pt-1 ml-5">
                                    <div className="main">
                                        <h5 className='text-dark text-bold'>{masterReportData?.Agency_Name} Test Test Test</h5>
                                        <p className='text-p'>Address: <span className=''>{masterReportData?.Agency_Address1}Test</span></p>
                                        <div className='d-flex '>
                                            <p className='text-p'>State: <span className='new-span '>{masterReportData?.StateName}</span>
                                            </p>
                                            <p className='text-p ml-5 pl-1'>City: <span className='new-span  '>{masterReportData?.CityName}</span>
                                            </p>
                                            <p className='text-p ml-2'>Zip: <span className='new-span  '>{masterReportData?.Agency_ZipId}</span>
                                            </p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='text-p'>Phone: <span className='new-span  '>{masterReportData?.Agency_Phone}</span></p>
                                            <p className='text-p ml-3 '>Fax: <span className='new-span  '> {masterReportData?.Agency_Fax}</span></p>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-7 col-md-7 col-lg-9 mt-2">
                                    <div className="main">
                                        <h5 className='text-dark font-weight-bold'>{masterReportData?.Agency_Name}</h5>
                                        <p className='text-p'>Address: <span className='text-address'>{masterReportData?.Agency_Address1}</span></p>
                                        <div className='d-flex justify-content-start flex-wrap'>
                                            <p className='text-p'>City: <span className='text-gray ml-2'>{masterReportData?.CityName}</span></p>
                                            <p className='text-p mb-1 ml-3'>State: <span className='text-gray'>{masterReportData?.StateName}</span></p>
                                            <p className='text-p mb-1 ml-3'>Zip: <span className='text-gray'>{masterReportData?.Zipcode}</span></p>
                                        </div>
                                        <div className='d-flex justify-content-start flex-wrap'>
                                            <p className='text-p mb-1'>Phone: <span className='text-gray ml-1'>{masterReportData?.Agency_Phone}</span></p>
                                            <p className='text-p mb-1 ml-4'>Fax: <span className='text-gray'>{masterReportData?.Agency_Fax}</span></p>
                                        </div>
                                    </div>
                                </div> */}
                            </>
                            <div className="col-12">
                                <hr style={{ border: '1px solid rgb(3, 105, 184)' }} />
                                <h5 className="text-center text-white text-bold bg-green  py-1" >Arrest Master Report</h5>

                            </div>
                            <div className="col-12">

                                <fieldset>
                                    <legend>Search Criteria</legend>

                                    <div className="row">
                                        {showFields.showArrestNumber && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Arrest Number From</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.ArrestNumber || ''} readOnly />
                                                </div>
                                            </>
                                        )}

                                        {showFields.showArrestDtTm && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Arrest Date From</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.ArrestDtTm || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showArrestDtTmTo && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Arrest Date To</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.ArrestDtTmTo || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showIncidentNumber && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Incident Number</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.IncidentNumber || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showNameIDNumber && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>MNI</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.NameIDNumber || ''} readOnly />
                                                </div>
                                            </>
                                        )}


                                        {showFields.showLastName && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Last Name</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.LastName || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showFirstName && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>First Name</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.FirstName || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showMiddleName && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Middle Name</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.MiddleName || ''} readOnly />
                                                </div>
                                            </>
                                        )}

                                        {showFields.showDateOfBirthFrom && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Age From </label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.DateOfBirthFrom || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showDateOfBirthTo && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Age To </label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.DateOfBirthTo || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showLocation && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Location</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.Location || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showSSN && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>SSN </label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.SSN || ''} readOnly />
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </fieldset>
                            </div>
                            <div className="container mt-1" style={{ pageBreakAfter: 'always' }}>
                                <div className="col-12">

                                    {
                                        masterReportData?.Charge?.length > 0 ?
                                            <>
                                                {
                                                    masterReportData?.Charge?.map((obj) => (
                                                        <>
                                                            <h5 className=" text-white text-bold bg-green py-1 px-3"> Arrest Number:- {obj.ArrestNumber}</h5>

                                                            {/* name */}
                                                            <div className="col-12  ">

                                                                <div className="container " style={{ border: '1px solid #80808085', }}>
                                                                    <h6 className=' text-dark mt-2'>Name Information</h6>

                                                                    <div className="col-12 mb-2 ">

                                                                        <div className="row px-3 ">
                                                                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.NameIDnumber}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>NameIDnumber</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 col-md-8 col-lg-8 mt-2 pt-1 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        // value={obj?.LastName && obj?.FirstName && obj?.MiddleName ?
                                                                                        //     obj?.LastName + '  ' + obj?.FirstName + '  ' + obj?.MiddleName
                                                                                        //     :
                                                                                        //     obj?.LastName && obj?.FirstName ?
                                                                                        //         obj?.LastName + '  ' + obj?.FirstName
                                                                                        //         :
                                                                                        //         obj?.LastName && obj?.MiddleName ?
                                                                                        //             obj?.LastName + '  ' + obj?.MiddleName
                                                                                        //             :
                                                                                        //             obj?.LastName ?
                                                                                        //                 obj?.LastName
                                                                                        //                 :
                                                                                        //                 ""}
                                                                                        value={obj?.Arrestee_Name
                                                                                        }

                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Name:</label>

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.Suffix}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Suffix</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.DateOfBirth && getShowingWithOutTime(obj?.DateOfBirth)}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>DOB</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.AgeFrom}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Age</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.AgeUnit_Decsription}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Age Unit</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 col-md-6col-lg-6 mt-2 pt-1  ">
                                                                                <div className=" text-field">
                                                                                    <textarea rows={1} type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.Address} style={{ resize: 'none' }}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Address</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-6 col-md-6 col-lg-6 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.Race_Description}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Race</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2 col-md-2 col-lg-2 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.Gender}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Gender</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2 col-md-2 col-lg-2 mt-4">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DOB' required readOnly
                                                                                        value={obj?.HeightFrom != null || obj?.HeightTo != null
                                                                                            ? `${obj.HeightFrom || ''}-${obj.HeightTo || ''}`.replace(/-$/, '')
                                                                                            : ''}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Height</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2 col-md-2 col-lg-2 mt-4">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DOB' required readOnly
                                                                                        value={obj?.WeightFrom != null || obj?.WeightTo != null
                                                                                            ? `${obj.WeightFrom || ''}-${obj.WeightTo || ''}`.replace(/-$/, '')
                                                                                            : ''}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Weight</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.EyeColor_Description}

                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Eye Color</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.HairColor_Description
                                                                                        }

                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Hair Color</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.Resident_Description}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Resident</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.SSN}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>SSN</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.Ethnicity_Description}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Ethnicity</label>
                                                                                </div>
                                                                            </div>



                                                                            <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.DLNumber}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Dl Number</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={JSON.parse(obj?.State)[0]?.IdentificationNumber}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>State Number</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        // value={item?.DLNumber}
                                                                                        value={JSON.parse(obj?.Jacket)[0]?.IdentificationNumber}

                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Jacket Number</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={JSON.parse(obj?.FBI)[0]?.IdentificationNumber}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>FBI Number</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.BirthPlace}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Birth Place</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.CountryName
                                                                                        }
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Nationality</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        // value={item?.DLNumber}
                                                                                        value={obj?.MaritalStatus_Description}

                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Maritial Status</label>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="col-2 col-md-2 col-lg-2 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        // value={item?.DLNumber}
                                                                                        value={obj?.DLNumber}

                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Scar/Mark/Tattoo</label>
                                                                                </div>
                                                                            </div> */}


                                                                            {/* <div className="col-4 col-md-4 col-lg-4 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        // value={item?.Contact}
                                                                                        value={obj?.Contact}

                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Contact</label>
                                                                                </div>
                                                                            </div> */}
                                                                            {/* <div className="col-2 col-md-2 col-lg-2 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.HeightFrom}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Height From</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2 col-md-2 col-lg-2 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.HeightTo}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Height To</label>
                                                                                </div>
                                                                            </div> */}
                                                                            {/* <div className="col-2 col-md-2 col-lg-2 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.WeightFrom}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Weight From</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2 col-md-2 col-lg-2 mt-4 ">
                                                                                <div className="text-field">
                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                        value={obj?.WeightTo}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Weight To</label>
                                                                                </div>
                                                                            </div> */}


                                                                        </div>


                                                                    </div>

                                                                </div>


                                                            </div >
                                                            {/* arrest */}
                                                            <div className="col-12  ">
                                                                <div className="container " style={{ border: '1px solid #80808085', }}>
                                                                    <div className="col-12 mb-2">
                                                                        <h6 className=' text-dark mt-2 bb'>Arrest Information</h6>
                                                                        <div className="row px-3" >
                                                                            {/* <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='text' required readOnly
                                                                                        value={obj?.ArrestNumber}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Arrest Number</label>
                                                                                </div>
                                                                            </div> */}
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2 ">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.ArrestDtTm ? getShowingDateText(obj?.ArrestDtTm) : ''}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Arrest Date/Time</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2 ">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.Arrestee_Name}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Arrestee</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.Agency_Name}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Arrest Agency</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-4">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.ArrestType_Description}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Arrest Type</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-4">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.IncidentNumber}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Incident Number</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-4">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        // value={obj?.DateOfBirth ? getShowingDateText(obj?.DateOfBirth) : ''}
                                                                                        // value={JSON.parse(obj?.Charge)[0]?.ReportedDate ? getShowingDateText(obj?.ReportedDate) : ''}
                                                                                        // value={JSON.parse(obj?.Charge)[0]?.ReportedDate ? getShowingDateText(JSON.parse(obj?.Charge)[0]?.ReportedDate) : ''}
                                                                                        value={obj?.ReportedDate ? getShowingDateText(obj?.ReportedDate) : ''}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Reported Date/Time</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-md-3 col-lg-3 mt-4">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.Supervisor_Name}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Supervisor</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-9 col-md-9 col-lg-9 mt-4">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.Address}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Incident Location</label>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="col-6 col-md-6 col-lg-6 mt-4">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.PrimaryOfficer_Name
                                                                                        }
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Arresting Officer</label>
                                                                                </div>
                                                                            </div> */}


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Rights-info */}
                                                            <div className="col-12  ">
                                                                <div className="container " style={{ border: '1px solid #80808085', }}>
                                                                    <h6 className=' text-dark mt-2 bb'>Rights Information</h6>
                                                                    <div className="col-12 ">
                                                                        <div className="row bb px-3 mb-2">
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.RightsGiven}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Rights Given</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.GivenBy_Name}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Given By</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.PrimaryOfficer_Name}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Primary Officer</label>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Juvenile Disposition-info */}
                                                            <div className="col-12  ">
                                                                <div className="container " style={{ border: '1px solid #80808085', }}>
                                                                    <h6 className=' text-dark mt-2 bb'>Juvenile Disposition</h6>
                                                                    <div className="col-12 ">
                                                                        <div className="row bb px-3 mb-2">
                                                                            <div className="col-8 col-md-8 col-lg-10 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.JuvenileDisposition_Desc}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Disposition</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-2 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.PhoneNo}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Phone No.</label>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Alias Name Information */}
                                                            {/* <div className="col-12  ">
                                                                {
                                                                    JSON.parse(obj?.Charge)?.length > 0 ?
                                                                        <>
                                                                            <div className="container bb">
                                                                                <h6 className=' text-dark mt-2'>Alias Name Information:</h6>
                                                                                <div className="col-12 ">
                                                                                    {
                                                                                        JSON.parse(obj?.NameAliases)?.map((item, key) => (
                                                                                            <table className="table table-bordered">
                                                                                                <thead className='text-dark master-table'>
                                                                                                    <tr>
                                                                                                        <th className=''>Alias Name</th>
                                                                                                        <th className=''>Alias DOB</th>
                                                                                                        <th className=''>Alias SSN</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody className='master-tbody'>
                                                                                                    {
                                                                                                        JSON.parse(obj?.NameAliases)?.map((item, key) => (
                                                                                                            <>
                                                                                                                <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                                    <td>{JSON.parse(obj?.NameAliases)[0]?.LastName}</td>
                                                                                                                    <td>{getShowingDateText(JSON.DOB)}</td>
                                                                                                                    <td>{JSON.parse(obj?.NameAliases)[0]?.AliasSSN}</td>
                                                                                                                </tr>
                                                                                                            </>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div> */}


                                                            <div className="col-12" >
                                                                {
                                                                    JSON.parse(obj?.Charge)?.length > 0 && JSON.parse(obj?.NameAliases)?.length > 0 ? (
                                                                        <div className="container bb" style={{ border: '1px solid #80808085', }}>
                                                                            <h6 className='text-dark mt-2'>Alias Name Information:</h6>
                                                                            <div className="col-12">
                                                                                <table className="table table-bordered">
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className=''>Alias Name</th>
                                                                                            <th className=''>Alias DOB</th>
                                                                                            <th className=''>Alias SSN</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='master-tbody'>
                                                                                        {
                                                                                            JSON.parse(obj?.NameAliases)?.map((item, key) => (
                                                                                                <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                    <td>{item.LastName}</td>
                                                                                                    <td>{item.DOB ? getShowingDateText(item.DOB) : ''}</td>
                                                                                                    <td>{item.AliasSSN}</td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                            </div>






                                                            {/* court */}
                                                            < div className="col-12  " >
                                                                {

                                                                    JSON.parse(obj?.ArrestCourtInformation)?.length > 0 ?
                                                                        <>
                                                                            <div className="container bb" style={{ border: '1px solid #80808085', }}>
                                                                                <h6 className=' text-dark mt-2'>Court Information</h6>
                                                                                <div className="col-12 ">
                                                                                    {
                                                                                        JSON.parse(obj?.ArrestCourtInformation)?.map((item, key) => (
                                                                                            <div className="row  px-3 bb mb-2" >
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.Name}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Name</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.DocketID}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Docket ID</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.CourtName}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Court Name</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.StateName}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Court State</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.CityName}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Court City</label>
                                                                                                    </div>
                                                                                                </div>


                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.JudgeName}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Judge Name</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.PleaDescription}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Plea </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item?.PleaDateTime ? getShowingDateText(item?.PleaDateTime) : ''}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Plea Date/Time</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item?.Prosecutor}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Prosecutor</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 col-lg-4  mt-2 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item?.Attorney}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Attorney</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item?.AppearDateTime ? getShowingDateText(item?.AppearDateTime) : ''}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Appear Date/Time</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.CourtAppearDescription}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Court Appear Reason</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-3 col-md-3 col-lg-3 mt-2">
                                                                                                    <div className=''>
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name=""
                                                                                                            id=""
                                                                                                            checked={item && Object.keys(item).length > 0 ? item.IsRescheduled : false}
                                                                                                            disabled={!item || Object.keys(item).length === 0}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary pl-2'>Rescheduled</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-3 col-md-3 col-lg-3 mt-2">
                                                                                                    <div className=''>
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name=""
                                                                                                            id=""
                                                                                                            checked={item && Object.keys(item).length > 0 ? item.IsContinued : false}
                                                                                                            disabled={!item || Object.keys(item).length === 0}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary pl-2'>Continued</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-3 col-md-3 col-lg-3 mt-2">
                                                                                                    <div className=''>
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name=""
                                                                                                            id=""
                                                                                                            checked={item && Object.keys(item).length > 0 ? item.IsAppearRequired : false}
                                                                                                            disabled={!item || Object.keys(item).length === 0}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary pl-2'>Appear Required</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-3 col-md-3 col-lg-3 mt-2">
                                                                                                    <div className=''>
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name=""
                                                                                                            id=""
                                                                                                            checked={item && Object.keys(item).length > 0 ? item.IsDismissed : false}
                                                                                                            disabled={!item || Object.keys(item).length === 0}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary pl-2'>Dismissed</label>

                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>
                                                            {/* Property   */}
                                                            {/* <div className="table-responsive" style={{ border: '1px solid #ddd' }}>
                                                                {
                                                                    JSON.parse(obj?.Charge)?.length > 0 ?
                                                                        <>
                                                                            <div className="container bb">
                                                                                <h5 className=' text-dark mt-2' >Property Information:</h5>

                                                                                <table className="table table-bordered">
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className=''>Property Number</th>
                                                                                            <th className=''>Type</th>
                                                                                            <th className=''>Value</th>
                                                                                            <th className=''>Reason</th>
                                                                                            <th className=''>Classification</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='master-tbody'>
                                                                                        {
                                                                                            JSON.parse(obj?.ArrestProperty)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key} >
                                                                                                        <td>{item.Officer_Name}</td>
                                                                                                        <td>{item.ArrPoliceForce_Description}</td>
                                                                                                        <td>{item.ArrPoliceForce_Description}</td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div > */}


                                                            <div className="col-12" >

                                                                <div className="table-responsive">
                                                                    {
                                                                        JSON.parse(obj?.Charge)?.length > 0 && JSON.parse(obj?.ArrestProperty)?.length > 0 ? (
                                                                            <>
                                                                                <div className="container bb" style={{ border: '1px solid #ddd' }}>
                                                                                    <h5 className='text-dark mt-2'>Property Information:</h5>

                                                                                    <table className="table table-bordered">
                                                                                        <thead className='text-dark master-table'>
                                                                                            <tr>
                                                                                                <th className=''>Property Number</th>
                                                                                                <th className=''>Type</th>
                                                                                                <th className=''>Classification</th>
                                                                                                <th className=''>Value</th>
                                                                                                <th className=''>Reason</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody className='master-tbody'>
                                                                                            {
                                                                                                JSON.parse(obj?.ArrestProperty)?.map((item, key) => (
                                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                        <td>{item.PropertyNumber}</td>
                                                                                                        <td>{item.PropertyType_Description}</td>
                                                                                                        <td>{item.Classification_Desc}</td>
                                                                                                        <td>{item.Value}</td>
                                                                                                        <td>{item.PropertyLossCode_Description}</td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>


                                                            {/* ------------------------VEHICLE --------------------*/}
                                                            <div className="col-12" >

                                                                < div className="table-responsive" >
                                                                    {/* {
                                                                    JSON.parse(obj?.Charge)?.length > 0 ?
                                                                        <> */}
                                                                    {
                                                                        JSON.parse(obj?.Charge)?.length > 0 && JSON.parse(obj?.ArrestVehicle)?.length > 0 ? (
                                                                            <>
                                                                                <div className="container bb" style={{ border: '1px solid #ddd' }}>
                                                                                    <h5 className=' text-dark mt-2' >Vehicle Information:</h5>
                                                                                    <table className="table table-bordered">
                                                                                        <thead className='text-dark master-table'>
                                                                                            <tr>
                                                                                                <th className=''>Vehicle Number</th>
                                                                                                <th className=''>Category</th>
                                                                                                <th className=''>Classification</th>
                                                                                                <th className=''>Value</th>
                                                                                                <th className=''>Reason</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody className='master-tbody'>
                                                                                            {
                                                                                                JSON.parse(obj?.ArrestVehicle)?.map((item, key) => (
                                                                                                    <>
                                                                                                        <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                            <td>{item.PropertyNumber}</td>
                                                                                                            <td>{item.Category_Description}</td>
                                                                                                            <td>{item.Classification_Description}</td>
                                                                                                            <td>{item.Value}</td>
                                                                                                            <td>{item.PropertyLossCode_Description}</td>

                                                                                                        </tr>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            {/* ArrestCriminalActivity */}
                                                            < div className="col-12  " >
                                                                {

                                                                    JSON.parse(obj?.ArrestCriminalActivity)?.length > 0 ?
                                                                        <>
                                                                            <div className="container bb" style={{ border: '1px solid #80808085', }}>
                                                                                <h6 className=' text-dark mt-2'>Criminal Activity</h6>
                                                                                <div className="col-12 ">
                                                                                    {
                                                                                        JSON.parse(obj?.ArrestCriminalActivity)?.map((item, key) => (
                                                                                            <div className="row  px-3 bb mb-2" >
                                                                                                <div className="col-12 col-md-12 col-lg-12 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.CriminalActivity_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Criminal Activity</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>


                                                            {/* naartive */}
                                                            <div className="col-12  " >
                                                                {
                                                                    JSON.parse(obj?.ArrestNarrative)?.length > 0 ?
                                                                        <>
                                                                            {
                                                                                JSON.parse(obj?.ArrestNarrative)?.map((item, key) => (
                                                                                    <div className="container bb" style={{ border: '1px solid #ddd' }}>
                                                                                        <div className="col-12">
                                                                                            <h6 className=' text-dark mt-2'>Narratives</h6>
                                                                                            <div className="row  mb-2 px-3" >
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.ReportedBy_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Reported By</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.Narrative_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Narrative Type/Report Type</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.NarrativeDtTm ? getShowingDateText(obj.NarrativeDtTm) : ''}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Date/Time</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 col-md-12 col-lg-12 mt-2">
                                                                                                    <div>
                                                                                                        <label htmlFor="" className='new-summary'>Narrative</label>
                                                                                                        <div
                                                                                                            className="readonlyColor text-justify"
                                                                                                            style={{
                                                                                                                border: '1px solid #ccc',
                                                                                                                borderRadius: '4px',
                                                                                                                padding: '10px',
                                                                                                                minHeight: '100px',
                                                                                                                backgroundColor: '#f9f9f9', // Light background for readability
                                                                                                                overflowY: 'auto', // Allows scrolling if content overflows
                                                                                                            }}
                                                                                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.CommentsDoc) }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }

                                                                {/* <div className="table-responsive mt-3">
                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                        <p className="p-0 m-0 d-flex align-items-center">SMT Image:</p>
                                                                    </div>
                                                                    <table className="table table-bordered" >
                                                                        <tbody className=''>
                                                                            <div className="row">
                                                                                {obj.SmtPhoto ? (
                                                                                    JSON.parse(obj.SmtPhoto).map((photo, index) => (
                                                                                        <img key={index} src={photo.Path} className='picture' style={{ marginTop: '4px', width: '150px' }} />
                                                                                    ))
                                                                                ) : (
                                                                                    null
                                                                                )}
                                                                            </div>
                                                                        </tbody>
                                                                    </table>
                                                                </div> */}
                                                                {
                                                                    JSON.parse(obj?.Smt)?.length > 0 ?
                                                                        <>
                                                                            <div className="container bb" style={{ border: '1px solid #ddd' }}>
                                                                                <h6 className='text-dark mt-2'>SMT Info:</h6>
                                                                                <div className="col-12">
                                                                                    {
                                                                                        JSON.parse(obj?.Smt)?.map((item, key) => (
                                                                                            <div key={key} className="row bb mb-2">
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            className='readonlyColor'
                                                                                                            name='DocFileName'
                                                                                                            required
                                                                                                            readOnly
                                                                                                            value={item.SMTType_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>SMT Type</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            className='readonlyColor'
                                                                                                            name='DocFileName'
                                                                                                            required
                                                                                                            readOnly
                                                                                                            value={item.SMTLocation_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>SMT Location</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            className='readonlyColor'
                                                                                                            name='DocFileName'
                                                                                                            required
                                                                                                            readOnly
                                                                                                            value={item.SMT_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Description:</label>
                                                                                                    </div>
                                                                                                </div>


                                                                                                <div className="col-12">
                                                                                                    <div className="row mt-1">
                                                                                                        {
                                                                                                            JSON.parse(item.SMTPhoto)?.map((photo, index) => (
                                                                                                                <div key={index} className="col-3 mb-3">
                                                                                                                    <img
                                                                                                                        src={photo.Path}
                                                                                                                        className=''
                                                                                                                        alt={`Image ${index}`}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }

                                                                {
                                                                    JSON.parse(obj?.ArrestPhoto)?.length > 0 ?
                                                                        <>
                                                                            <div className="container bb" style={{ border: '1px solid #ddd' }}>
                                                                                <h6 className=' text-dark mt-2'>Mug Shot Images:</h6>
                                                                                <div className="col-12 mb-2">
                                                                                    <div className="row">
                                                                                        {/* {obj.ArrestPhoto ? (
                                                                                                JSON.parse(obj.ArrestPhoto).map((Photo, index) => (
                                                                                                    <img key={index} src={Photo.Path} className='picture' style={{ marginTop: '4px', width: '150px' }} />
                                                                                                ))
                                                                                            ) : (
                                                                                                null
                                                                                            )} */}
                                                                                        {obj.ArrestPhoto ? (
                                                                                            JSON.parse(obj.ArrestPhoto).map((Photo, index) => (
                                                                                                <div key={index} className="col-3 mb-3">
                                                                                                    <img
                                                                                                        src={Photo.Photo} className=''
                                                                                                        alt={`Mug shot ${index + 1}`}
                                                                                                    />
                                                                                                </div>
                                                                                            ))
                                                                                        ) : null}
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>
                                                            {/* juvenile  */}
                                                            <div className="col-12  " >
                                                                {

                                                                    JSON.parse(obj?.ArrestJuvenile)?.length > 0 ?
                                                                        <>
                                                                            <div className="container bb" style={{ border: '1px solid #80808085', }}>
                                                                                <h6 className=' text-dark mt-2'>Juvenile Information</h6>
                                                                                <div className="col-12 ">
                                                                                    {
                                                                                        JSON.parse(obj?.ArrestJuvenile)?.map((item, key) => (
                                                                                            <div className="row  px-3 bb mb-2" >
                                                                                                <div className="col-6 col-md-6 col-lg-6 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.ParentName}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Parent/Guardian</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-6 col-md-6 col-lg-6 mt-2 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item?.ParentContactDtTm ? getShowingDateText(item?.ParentContactDtTm) : ''}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'> Parent Contacted Date</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-6 col-md-6 col-lg-6 mt-2 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.ContactBy}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Contacted By</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-6 col-md-6 col-lg-6 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <textarea rows={1} type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.ParentAddress} style={{ resize: 'none' }}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Parent Address</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>


                                                            {/* police */}
                                                            <div className="col-12  " >
                                                                {
                                                                    // JSON.parse(obj?.ArrestPoliceForce)?.map((item, key) => (
                                                                    JSON.parse(obj?.Charge)?.length > 0 && JSON.parse(obj?.ArrestPoliceForce)?.length > 0 ? (
                                                                        <div className="container bb" style={{ border: '1px solid #80808085', }}>
                                                                            <h6 className=' text-dark mt-2'>Police Force:</h6>
                                                                            <div className="col-12 ">
                                                                                <table className="table table-bordered">
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className=''>Officer Name</th>
                                                                                            <th className=''>Description</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='master-tbody'>
                                                                                        {
                                                                                            JSON.parse(obj?.ArrestPoliceForce)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                        <td>{item.Officer_Name}</td>
                                                                                                        <td>{item.ArrPoliceForce_Description}</td>

                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>

                                                                            </div>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                            </div>

                                                            {/* charge */}
                                                            <div className="col-12  ">
                                                                {

                                                                    JSON.parse(obj?.Charge)?.length > 0 ?
                                                                        <>
                                                                            <div className="container bb" style={{ border: '1px solid #ddd' }}>
                                                                                <h6 className=' text-dark mt-2'>Charge Information</h6>
                                                                                <div className="col-12 ">
                                                                                    {
                                                                                        JSON.parse(obj?.Charge)?.map((item, key) => (
                                                                                            <div className="row  px-3 bb mb-2" >
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.Name}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Name</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.IncidentNumber}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Incident #</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.ArrestNumber}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Arrest #</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-6 col-md-6 col-lg-6 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.NIBRS_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>NIBRS Code</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-6 col-md-6 col-lg-6 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.ChargeCode_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Charge Code/Description</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-6 col-md-6 col-lg-6 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.UCRClear_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>UCR Clear</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-6 col-md-6 col-lg-6 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.Count}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Count</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                {/* <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Court Disposition</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-1"></div>
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-4 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item?.DispositionDtTm ? getShowingDateText(item?.DispositionDtTm) : ''}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Disposition Date/Time</label>
                                                                                                    </div>
                                                                                                </div> */}


                                                                                                {/* <div className="col-12 col-md-12 col-lg-12 mt-4 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.Comments}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Comments</label>
                                                                                                    </div>
                                                                                                </div> */}

                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>

                                                            {/* Penalties-info */}
                                                            <div className="col-12  ">
                                                                <div className="container " style={{ border: '1px solid #80808085', }}>
                                                                    <h6 className=' text-dark mt-2 bb'>Penalties</h6>
                                                                    <div className="col-12 ">
                                                                        <div className="row bb px-3 mb-2">
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.Fine}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Fine</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.CourtCost}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Court Cost</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.OtherCost}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Other Cost</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.FTADate ? getShowingDateText(obj?.FTADate) : ''}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>FTA Date</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.FTAAmount}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>FTA Amount</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.LitigationTax}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Litigation Tax</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.TotalPenalty}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Total Penalty</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-md-12 col-lg-12 mt-2">
                                                                                <div className='text-field'>
                                                                                    <textarea rows={1} type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.Comments} style={{ resize: 'none' }}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Comments</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.Years}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Years</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.Months}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Months</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                <div className='text-field'>
                                                                                    <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                        value={obj?.Days}
                                                                                    />
                                                                                    <label htmlFor="" className='new-summary'>Days</label>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* ChargeCourtDisposition  */}
                                                            <div className="col-12  " >
                                                                {

                                                                    JSON.parse(obj?.ChargeCourtDisposition)?.length > 0 ?
                                                                        <>
                                                                            <div className="container bb" style={{ border: '1px solid #80808085', }}>
                                                                                <h6 className=' text-dark mt-2'>Court Disposition Information</h6>
                                                                                <div className="col-12 ">
                                                                                    {
                                                                                        JSON.parse(obj?.ChargeCourtDisposition)?.map((item, key) => (
                                                                                            <div className="row  px-3 bb mb-2" >
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item?.DispositionDtTm ? getShowingDateText(obj?.DispositionDtTm) : ''}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Disposition Date/Time</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-8 col-md-8 col-lg-8 mt-2 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item?.ExceptionalClearance}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Exceptional Clearance</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 col-lg-4 mt-2 ">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.CourtDispostion}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Court Disposition</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-6 col-md-6 col-lg-6 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <textarea rows={1} type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.Comments} style={{ resize: 'none' }}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Comments</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>
                                                            {/* ChargeComment  */}
                                                            < div className="col-12  " >
                                                                {

                                                                    JSON.parse(obj?.ArrestChargeComments)?.length > 0 ?
                                                                        <>
                                                                            <div className="container bb" style={{ border: '1px solid #80808085', }}>
                                                                                <h6 className=' text-dark mt-2'>Comment Information</h6>
                                                                                <div className="col-12 ">
                                                                                    {
                                                                                        JSON.parse(obj?.ArrestChargeComments)?.map((item, key) => (
                                                                                            <div className="row  px-3 bb mb-2" >
                                                                                                <div className="col-12 col-md-6 col-lg-6 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.Officer_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Reported By</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 col-md-12 col-lg-12 mt-2">
                                                                                                    <div>
                                                                                                        <label htmlFor="" className='new-summary'>Notes</label>
                                                                                                        <div
                                                                                                            className="readonlyColor text-justify"
                                                                                                            style={{
                                                                                                                border: '1px solid #ccc',
                                                                                                                borderRadius: '4px',
                                                                                                                padding: '10px',
                                                                                                                minHeight: '100px',
                                                                                                                backgroundColor: '#f9f9f9', // Light background for readability
                                                                                                                overflowY: 'auto', // Allows scrolling if content overflows
                                                                                                            }}
                                                                                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.CommentsDoc) }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>
                                                            {/* ChargeWeapon  */}
                                                            < div className="col-12  " >
                                                                {

                                                                    JSON.parse(obj?.ChargeWeapon)?.length > 0 ?
                                                                        <>
                                                                            <div className="container bb" style={{ border: '1px solid #80808085', }}>
                                                                                <h6 className=' text-dark mt-2'>Weapon Information</h6>
                                                                                <div className="col-12 ">
                                                                                    {
                                                                                        JSON.parse(obj?.ChargeWeapon)?.map((item, key) => (
                                                                                            <div className="row  px-3 bb mb-2" >
                                                                                                <div className="col-12 col-md-12 col-lg-12 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.Weapon_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Weapon</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>
                                                            {/* ChargeOffense  */}
                                                            < div className="col-12  " >
                                                                {

                                                                    JSON.parse(obj?.ChargeOffense)?.length > 0 ?
                                                                        <>
                                                                            <div className="container bb" style={{ border: '1px solid #80808085', }}>
                                                                                <h6 className=' text-dark mt-2'>Offense Information</h6>
                                                                                <div className="col-12 ">
                                                                                    {
                                                                                        JSON.parse(obj?.ChargeOffense)?.map((item, key) => (
                                                                                            <div className="row  px-3 bb mb-2" >
                                                                                                <div className="col-12 col-md-12 col-lg-12 mt-2">
                                                                                                    <div className='text-field'>
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.Offense_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Offense</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>

                                                        </>
                                                    ))
                                                }


                                            </>
                                            :
                                            <>
                                            </>
                                    }






                                </div>
                            </div>
                        </div>
                    </div >
                </>
            }
            {loder && (
                <div className="loader-overlay">
                    <Loader />
                </div>
            )}
        </>
    )
}

export default ArrestMaster

export const changeArrayFormat = (data, type) => {
    if (type === 'zip') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.zipId, label: sponsor.Zipcode })
        )
        return result
    }
    if (type === 'state') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.StateID, label: sponsor.StateName })
        )
        return result
    }
    if (type === 'city') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.CityID, label: sponsor.CityName })
        );
        return result
    }
}
