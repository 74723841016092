import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { CadDashboardTableCustomStyles, getShowingYearMonthDate, stringToBase64 } from '../../../Components/Common/Utility';
import { useNavigate } from 'react-router-dom';
import DateTimeCounter from '../../Common/DateTimeCounter';
import { IncidentContext } from '../../../CADContext/Incident';
import CommentsModal from '../../CommentsModal';
import { compareStrings } from '../../../CADUtils/functions/common';
import Tooltip from '../../Common/Tooltip';
import MasterTableListServices from '../../../CADServices/APIs/masterTableList'
import MonitorServices from '../../../CADServices/APIs/monitor'
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';


const IncidentTableSection = ({ isIncidentDispatch, incidentViewFilterStatus, incidentTableFilterIncId }) => {
  const { incidentData, assignedIncidentData, unassignedIncidentData } = useContext(IncidentContext);
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [editValue, setEditValue] = useState("");
  const [incidentID, setIncidentID] = useState("");
  const [incidentNumber, setIncidentNumber] = useState("");
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [statusColorData, setStatusColorData] = useState([]);
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [tableData, setTableData] = useState();
  const [loginPinID, setLoginPinID,] = useState('');

  const navigate = useNavigate();

  // useEffect(() => {
  //   navigate(`/cad/dashboard-page?IncId=${stringToBase64(incidentID)}&IncNo=${incidentNumber}&isResourceView=${isResourceView}&IncSta=true`);
  // }, [incidentID, incidentNumber, isResourceView, editValue]);

  // const conditionalRowStyles = [
  //   {
  //     when: (row) => row.IncidentID === incidentID,
  //     style: {
  //       backgroundColor: '#001f3fbd',
  //       color: 'white',
  //       cursor: 'pointer',
  //       '&:hover': {
  //         backgroundColor: '#001f3fbd',
  //         color: 'white',
  //       },
  //     },
  //   },
  // ];
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 670);

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID);
      setLoginPinID(localStoreData?.PINID)
    }
  }, [localStoreData]);


  const getPriorityKey = `/CAD/MasterPriority/GetMasterPriority`;
  const { data: priorityList, isSuccess: isFetchPriorityList } = useQuery(
    [getPriorityKey, {
      IsActive: 1,
      AgencyID: loginAgencyID,
    }],
    MasterTableListServices.getMasterPriority,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
  useEffect(() => {
    if (isFetchPriorityList && priorityList) {
      const res = JSON.parse(priorityList?.data?.data);
      const data = res?.Table
      setStatusColorData(data || [])
    } else {
      setStatusColorData([])
    }
  }, [isFetchPriorityList, priorityList])

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1400);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getStatusColors = (statusCode) => {
    const statusItem = statusColorData.find(item => item.PriorityCode === statusCode);
    return statusItem
      ? { backgroundColor: statusItem.BackColor }
      : {};
  };
  const conditionalRowStyles = [
    {
      when: () => true,
      style: (row) => getStatusColors(row.PriorityCode),
    },
  ];

  const initialCols = [
    {
      name: <p className='text-center' style={{ position: 'absolute', top: '7px' }} >Comments</p>,
      cell: (row) => (
        <>
          <span
            className="btn btn-sm text-white p-1 py-0 mr-2"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            <button className="d-flex justify-content-end btn btn-sm px-1 py-0" data-toggle="modal"
              data-target="#CommentModal" onClick={() => { setOpenCommentModal(true); setIncidentID(row?.IncidentID); setIncidentNumber(row?.CADIncidentNumber);  navigate(`/cad/dashboard-page?IncId=${stringToBase64(row?.IncidentID)}&IncNo=${row?.CADIncidentNumber}&isResourceView=false&IncSta=true`);}} >
              <i className="fa fa-comment"></i>
            </button>
          </span>

        </>
      ),
      width: isSmallScreen ? "90px" : "90px",
    },
    {
      name: <p className='text-center' style={{ position: 'absolute', top: '7px' }} >View</p>,
      cell: (row) => (
        <>

          <span
            className="btn btn-sm text-white p-1 py-0"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            <button className="d-flex justify-content-end btn btn-sm px-1 py-0" data-toggle="modal"
              data-target="#CommentModal" onClick={() => navigate(`/cad/dispatcher?IncId=${stringToBase64(row?.IncidentID)}&IncNo=${row?.CADIncidentNumber}&IncSta=true`)
              } >
              <i className="fa fa-eye"></i>
            </button>
          </span>
        </>
      ),
      width: isSmallScreen ? "60px" : "60px",
    },
    {
      name: 'CAD Event #',
      selector: (row) => row?.CADIncidentNumber || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CADIncidentNumber, rowB.CADIncidentNumber),
      width: isSmallScreen ? "130px" : "130px",
    },
    {
      name: 'RMS Incident #',
      selector: (row) => row?.IncidentNumber || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.IncidentNumber, rowB.IncidentNumber),
      width: isSmallScreen ? "130px" : "130px",
    },
    {
      name: 'Location',
      selector: (row) => row?.CrimeLocation || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CrimeLocation, rowB.CrimeLocation),
      width: isSmallScreen ? "350px" : "350px",
    },
    {
      name: 'Incident DT/TM',
      selector: (row) => row?.ReportedDate ? getShowingYearMonthDate(row?.ReportedDate) : '',
      // sortable: true,
      // sortFunction: (rowA, rowB) => compareStrings(rowA.ReportedDate, rowB.ReportedDate),
      width: isSmallScreen ? "190px" : "190px",
    },
    {
      name: 'CFS Code',
      selector: (row) => row?.CFSCODE || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CFSCODE, rowB.CFSCODE),
      width: isSmallScreen ? "100px" : "100px",
    },
    {
      name: 'CFS Description',
      selector: (row) => row?.CFSCodeDescription ? row?.CFSCodeDescription : '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CFSCodeDescription, rowB.CFSCodeDescription),
      width: isSmallScreen ? "140px" : "140px",
      cell: (row) => (
        <Tooltip text={row?.CFSCodeDescription || ''} maxLength={15} />
      ),
    },
    {
      name: 'Priority',
      selector: (row) => row?.PriorityCode || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.PriorityCode, rowB.PriorityCode),
      width: isSmallScreen ? "100px" : "100px",
    },
    {
      name: 'Resources#',
      // selector: (row) => row?.Resources || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Resources, rowB.Resources),
      width: isSmallScreen ? "190px" : "190px",
      cell: (row) => {
        const text = row?.Resources || '';
        const truncatedText = text.length > 25 ? text.substring(0, 25) + '...' : text;
        return (
          <div
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            title={text.length > 25 ? text : ''}
          >
            {truncatedText}
          </div>
        );
      },
    },
    {
      name: 'E Timer',
      selector: (row) => row.ReportedDate ? <DateTimeCounter data={row.ReportedDate} /> : '',
      sortable: true,
      width: isSmallScreen ? "90px" : "90px",
    },
    {
      name: 'Primary Resource',
      selector: (row) => row?.PrimaryResourceName || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.PrimaryResourceName, rowB.PrimaryResourceName),
      width: isSmallScreen ? "145px" : "145px",
    },

    {
      name: 'Source',
      selector: (row) => row?.Source || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Source, rowB.Source),
      // width: "12%"
      width: isSmallScreen ? "85px" : undefined,
    },
    {
      name: 'Operator',
      selector: (row) => row?.OperatorName  || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.OperatorName , rowB.OperatorName ),
      width: isSmallScreen ? "105px" : undefined,
    },
    {
      name: 'Apt#',
      selector: (row) => row?.ApartmentNo || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ApartmentNo, rowB.ApartmentNo),
      width: isSmallScreen ? "105px" : undefined,
    },
    {
      name: 'Zone',
      selector: (row) => row?.ZoneDescription || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ZoneDescription, rowB.ZoneDescription),
      width: isSmallScreen ? "105px" : undefined,
    },
    // {
    //   name: 'Agency Code',
    //   selector: (row) => row?.AgencyCode || '',
    //   sortable: true,
    //   sortFunction: (rowA, rowB) => compareStrings(rowA.AgencyCode, rowB.AgencyCode),
    //   width: isSmallScreen ? "100px" : undefined,
    // }
  ]

  const onIncidentRowClick = row => {
    setIncidentID(row?.IncidentID)
    setIncidentNumber(row?.CADIncidentNumber)
    setEditValue(row);
    navigate(`/cad/dashboard-page?IncId=${stringToBase64(row?.IncidentID)}&IncNo=${row?.CADIncidentNumber}&isResourceView=false&IncSta=true`);

  }

  const [columns, setColumns] = useState([]);
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(null);
  const [draggingColumnIndex, setDraggingColumnIndex] = useState(null);

  // Sanitize columns for saving to localStorage
  const sanitizeColumns = (columns) => {
    return columns?.map((col) => ({
      name: typeof col.name === "string" ? col.name : col.name.props?.children || col.name,
      selector: col.selector,
      sortable: col.sortable,
      width: col.width,
    }));
  };

  // Restore columns from saved state
  const restoreColumns = (savedColumns) => {
    return savedColumns?.map((col) => {
      const matchingColumn = initialCols.find((initialCol) => {
        const colName =
          typeof initialCol.name === "string"
            ? initialCol.name
            : initialCol.name.props?.children;
        return colName === col.name;
      });

      return {
        ...col,
        name: matchingColumn?.name || col.name,
        selector: matchingColumn?.selector || col.selector,
        cell: matchingColumn?.cell || col.cell,
      };
    });
  };

  // Save columns to localStorage
  const saveColumnsToLocalStorage = async (columns) => {
    const sanitizedColumns = sanitizeColumns(columns);
    // localStorage.setItem("tableColumns", JSON.stringify(sanitizedColumns));
    const data = {
      Description: JSON.stringify(sanitizedColumns),
      UserID: loginPinID,
      AgencyID: loginAgencyID,
      CreatedByUserFK: loginPinID
    }
    const response = await MonitorServices.insertUserTable(data);
  };

  // Restore columns from localStorage or use initialCols


  const getUserTableKey = `/CAD/UserTableColumns/GetData_UserTable/${loginPinID}`;
  const { data: getDataUserTable, isSuccess: isFetchUserTable } = useQuery(
    [getUserTableKey, {
      UserID: loginPinID,
    }],
    MonitorServices.getDataUserTable,
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: !!loginPinID,
    }
  );

  useEffect(() => {
    // const initializeColumns = async () => {
    //   if (loginPinID) {
    //     const savedColumns = localStorage.getItem("tableColumns");
    //     const data = { UserID: loginPinID };

    //     try {
    //       const response = await MonitorServices.getDataUserTable(data);
    //       console.log("response", response);
    //       const descriptionData = JSON.parse(response?.data?.data)?.Table?.[0]?.Description
    //       console.log("descriptionData", descriptionData)

    //       if (descriptionData) {
    //         // setColumns(restoreColumns(JSON.parse(savedColumns)));
    //         setColumns(restoreColumns(descriptionData));
    //       }
    //       // else {
    //       //   setColumns(initialCols);
    //       // }
    //     } catch (error) {
    //       console.error("Error fetching user table data:", error);
    //       setColumns(initialCols); // Fallback to initial columns in case of an error
    //     }
    //   }
    // };

    // // initializeColumns();

    if (getDataUserTable && isFetchUserTable) {
      const descriptionData = JSON.parse(getDataUserTable?.data?.data)?.Table?.[0]?.Description
      // console.log("descriptionData", descriptionData)
      // setColumns(restoreColumns(descriptionData));
    } else {
      setColumns(initialCols);
    }
  }, [getDataUserTable, isFetchUserTable]);


  // Handle column selection
  const handleColumnSelect = (index) => {
    setSelectedColumnIndex(index);
  };

  // Handle column drag start
  const handleColumnDragStart = (e, index) => {
    setDraggingColumnIndex(index);
    e.dataTransfer.setData("text/plain", index);
  };

  // Handle column drop
  const handleColumnDrop = (e, index) => {
    e.preventDefault();
    const draggedIndex = parseInt(e.dataTransfer.getData("text/plain"), 10);

    if (draggedIndex !== index) {
      const updatedColumns = [...columns];
      const [draggedColumn] = updatedColumns.splice(draggedIndex, 1);
      updatedColumns.splice(index, 0, draggedColumn);

      setColumns(updatedColumns);
      saveColumnsToLocalStorage(updatedColumns); // Persist column order
    }

    setDraggingColumnIndex(null);
  };

  // Handle drag over
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    const getFilteredData = () => {
      let sourceData;

      switch (incidentViewFilterStatus) {
        case "Assigned":
          sourceData = assignedIncidentData;
          break;
        case "Unassigned":
          sourceData = unassignedIncidentData;
          break;
        default:
          sourceData = incidentData;
      }

      if (incidentTableFilterIncId) {
        return sourceData.filter((i) => i?.CADIncidentNumber === incidentTableFilterIncId);
      }
      return sourceData;
    };

    setTableData(getFilteredData());
  }, [incidentTableFilterIncId, assignedIncidentData, unassignedIncidentData, incidentData, incidentViewFilterStatus]);

  return (
    <>
      <div className="table-container">
        <DataTable
          dense
          columns={columns?.map((column, index) => ({
            ...column,
            name: (
              <div
                className={selectedColumnIndex === index ? "selected-column" : ""}
                draggable
                onDragStart={(e) => handleColumnDragStart(e, index)}
                onDragOver={handleDragOver}
                onDrop={(e) => handleColumnDrop(e, index)}
                onClick={() => handleColumnSelect(index)}
                style={{
                  cursor: "move",
                  opacity: draggingColumnIndex === index ? 0.5 : 1,
                }}
              >
                {typeof column.name === "string" ? column.name : column.name.props?.children}
              </div>
            ),
          }))}
          // data={incidentViewFilterStatus === "Assigned" ? assignedIncidentData : incidentViewFilterStatus === "Unassigned" ? unassignedIncidentData : incidentData}
          data={tableData}
          onRowClicked={onIncidentRowClick}
          customStyles={CadDashboardTableCustomStyles}
          conditionalRowStyles={conditionalRowStyles}
          // conditionalRowStyles={conditionalRowStyles}
          pagination
          paginationPerPage={'50'}
          paginationRowsPerPageOptions={[50, 100, 150, 200, 500]}
          showPaginationBottom={50}
          responsive
          striped
          highlightOnHover
          fixedHeader
          persistTableHead={true}
        />
      </div>
      <CommentsModal
        {...{
          openCommentModal,
          setOpenCommentModal,
          incidentID,
          incidentNumber
        }}
      />
    </>
  );
};

export default IncidentTableSection;