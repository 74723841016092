import { toastifyError } from '../../Components/Common/AlertMsg';
import { Comman_changeArrayFormat } from '../../Components/Common/ChangeArrayFormat';
import { ScreenPermision, fetchPostData } from '../../Components/hooks/Api';
import { Agency_OfficerDrp_Data, INC_NO_Exist_Status, INC_NO_Genrate_Status, Screen_Permissions } from '../actionTypes';
import * as api from '../api'

// IncidentID
// AgencyID
export const get_AgencyOfficer_Data = (ID, IncID) => async (dispatch) => {
    const val = { AgencyID: ID, IncidentID: IncID ? IncID : 0 };
    fetchPostData('DropDown/GetData_HeadOfAgency', val).then((data) => {
        if (data) {
            dispatch({ type: Agency_OfficerDrp_Data, payload: Comman_changeArrayFormat(data, 'PINID', 'HeadOfAgency') });
        } else {
            dispatch({ type: Agency_OfficerDrp_Data, payload: [] });
        }
    })
};


export const get_ScreenPermissions_Data = (code, LoginAgencyID, PinID) => async (dispatch) => { 
    ScreenPermision(code, LoginAgencyID, PinID).then(res => {
        if (res) {
            dispatch({ type: Screen_Permissions, payload: res });
        } else {
            dispatch({ type: Screen_Permissions, payload: [] });
        }
    });
};
