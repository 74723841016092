import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import Dropdown from 'react-bootstrap/Dropdown';
import { Filter, SendIcon } from '../../../Components/Filter/Filter';
import { tableCustomStyles } from '../../../Components/Common/Utility';
import { coloredStyle_Select, colorLessStyle_Select } from '../../Utility/CustomStylesForReact';
import MasterTableListServices from "../../../CADServices/APIs/masterTableList";
import '../section.css';
import useObjState from '../../../CADHook/useObjState';
import { useQuery } from 'react-query';
import { toastifySuccess } from '../../../Components/Common/AlertMsg';
import CADConfirmModal from '../../Common/CADConfirmModal';
import { useSelector } from 'react-redux';
import { isEmpty, compareStrings } from '../../../CADUtils/functions/common';
const CFSAgencyCallFilterSection = () => {
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [filterTypeDescOption, setFilterTypeDescOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");
  const [csfDropDown, setCsfDropDown] = useState([])
  const [agencyCodeDropDown, setAgencyCodeDropDown] = useState([])
  const [filterListData, setFilterListData] = useState([]);
  const [listData, setListData] = useState([]);
  const [searchValue1, setSearchValue1] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [confirmType, setConfirmType] = useState('');
  const [isActive, setIsActive] = useState('');
  const [activeInactiveData, setActiveInactiveData] = useState({})
  const [loginPinID, setLoginPinID] = useState('');

  const [
    agencyCallState,
    setAgencyCallState,
    handleAgencyCallState,
    clearAgencyCallState,
  ] = useObjState({
    serviceCode: "",
    description: "",
    law: false,
    fire: false,
    ems: false,
    other: false,
    agencyCode: "",
    agencyCode2: "",
    agencyCode3: "",
    agencyCode4: "",
    agencyCode5: "",
    IsActive: 1
  })
  const [
    errorAgencyCallState,
    _setErrorAgencyCallState,
    handleErrorAgencyCallState,
    clearStateAgencyCallState,
  ] = useObjState({
    serviceCode: false,
    description: false,
    agencyType: false,
    agencyCode: false,
  });

  const GetResourceTypeKey = `/CAD/MasterCallforServiceCode/InsertCallforServiceCode`;
  const { data: getCFSData, isSuccess: isFetchCFSData } = useQuery(
    [GetResourceTypeKey, { Action: "GET", IsActive: 1 },],
    MasterTableListServices.getCFS,
    {
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(localStoreData?.PINID);
    }
  }, [localStoreData]);

  useEffect(() => {
    if (getCFSData && isFetchCFSData) {
      const data = JSON.parse(getCFSData?.data?.data);
      setCsfDropDown(data?.Table)
    }
  }, [getCFSData, isFetchCFSData])

  const getAgencyCodeKey = `/CAD/MasterAgency/MasterAgencyCode`;
  const { data: agencyCodeData, isSuccess: isFetchAgencyCodeData } = useQuery(
    [getAgencyCodeKey, {},],
    MasterTableListServices.getAgencyCode,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isFetchAgencyCodeData && agencyCodeData) {
      const data = JSON.parse(agencyCodeData?.data?.data);
      setAgencyCodeDropDown(data?.Table || [])
    }
  }, [isFetchAgencyCodeData, agencyCodeData]);

  const getCFSAgencyCallFilterKey = `/CAD/MasterCFSagencycallfilter/GET_CFSagencycallfilter`;
  const { data: CFSAgencyCallFilterData, isSuccess: isFetchCFSAgencyCallFilter, refetch, isError: isNoData } = useQuery(
    [getCFSAgencyCallFilterKey, { Action: "GET", IsActive: parseInt(pageStatus) },],
    MasterTableListServices.getCFSAgencyCallFilter,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  useEffect(() => {
    if (isFetchCFSAgencyCallFilter && CFSAgencyCallFilterData) {
      const data = JSON.parse(CFSAgencyCallFilterData?.data?.data);
      setFilterListData(data?.Table)
      setListData(data?.Table)
    } else {
      setFilterListData([])
      setListData([])
    }
  }, [CFSAgencyCallFilterData, isFetchCFSAgencyCallFilter])

  async function handelActiveInactive() {
    const data = {
      CadeCFSAgencyID: activeInactiveData?.CadeCFSagencyID,
      DeletedByUserFK: loginPinID,
      IsActive: isActive,
    }
    const response = await MasterTableListServices.changeStatusAgencyCallFilter(data);
    if (response?.status === 200) {
      const data = JSON.parse(response?.data?.data)?.Table?.[0];
      toastifySuccess(data?.Message);
      refetch();
    }
    setShowModal(false);
  }

  const columns = [
    {
      name: 'CFS Code',
      selector: row => csfDropDown?.find((i) => i?.CallforServiceID === row?.CallforServiceID)?.CFSCODE || "",
      sortable: true,
      style: {
        position: "static",
      },
    },
    {
      name: 'Description',
      selector: row => row.Description,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Description, rowB.Description),
      style: {
        position: "static",
      },
    },
    {
      name: 'Agency Type',
      selector: row => {
        let result = [];
        if (row?.LAW === true) result.push("L");
        if (row?.FIRE === true) result.push("F");
        if (row?.EMERGENCYMEDICALSERVICE === true) result.push("E");
        if (row?.OTHER === true) result.push("O");
        return result.join(", ") || "";
      },
      sortable: true,
      style: {
        position: "static",
      },
    },
    {
      name: 'Agency Code',
      selector: row => [
        agencyCodeDropDown?.find(i => i?.AgencyID === row.AgencyID1)?.ShortName,
        agencyCodeDropDown?.find(i => i?.AgencyID === row.AgencyID2)?.ShortName,
        agencyCodeDropDown?.find(i => i?.AgencyID === row.AgencyID3)?.ShortName,
        agencyCodeDropDown?.find(i => i?.AgencyID === row.AgencyID4)?.ShortName,
        agencyCodeDropDown?.find(i => i?.AgencyID === row.AgencyID5)?.ShortName
      ]
        .filter(Boolean)
        .join(', '),
      sortable: true,
      style: {
        position: "static",
      },
    },
    {
      name:
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {'Status'}
        </div>,
      cell: (row, index) =>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <span
            className="btn btn-sm text-white px-1 py-0 mr-1"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            {row?.IsActive ? (
              <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(0); setConfirmType("InActive") }}></i>
            ) : (
              <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(1); setConfirmType("Active"); }}></i>
            )}
          </span>
        </div>,
      width: "70px",
      style: {
        position: "static",
      },
    },
  ];

  function handelCancel() {
    clearAgencyCallState();
    setAgencyCallState({
      serviceCode: "",
      description: "",
      law: false,
      fire: false,
      ems: false,
      other: false,
      agencyCode: "",
      agencyCode2: "",
      agencyCode3: "",
      agencyCode4: "",
      agencyCode5: "",
      IsActive: 1
    })
    clearStateAgencyCallState();
  }
  const validateForm = () => {
    let isError = false;
    const keys = Object.keys(errorAgencyCallState);
    keys.map((field) => {
      if (
        field === "serviceCode" &&
        isEmpty(agencyCallState[field])) {
        handleErrorAgencyCallState(field, true);
        isError = true;
      } else if (field === "description" && isEmpty(agencyCallState[field])) {
        handleErrorAgencyCallState(field, true);
        isError = true;
      } else if (field === "agencyCode" && isEmpty(agencyCallState[field])) {
        handleErrorAgencyCallState(field, true);
        isError = true;
      } else if (field === "agencyType" && (agencyCallState.law === false && agencyCallState.fire === false && agencyCallState.ems === false && agencyCallState.other === false)) {
        handleErrorAgencyCallState(field, true);
        isError = true;
      } else {
        handleErrorAgencyCallState(field, false);
      }
    });
    return !isError;
  };

  async function handelSave() {
    if (!validateForm()) return
    const isUpdate = Boolean(agencyCallState?.CallforServiceID);
    const data = {
      Action: isUpdate ? "UPDATE" : "ADD",
      CadeCFSagencyID: isUpdate ? agencyCallState?.CadeCFSagencyID : undefined, // Only include for update
      CallforServiceID: agencyCallState?.serviceCode,
      Description: agencyCallState?.description,
      LAW: agencyCallState?.law ? 1 : 0,
      FIRE: agencyCallState?.fire ? 1 : 0,
      EMERGENCYMEDICALSERVICE: agencyCallState?.ems ? 1 : 0,
      AgencyID1: agencyCallState?.agencyCode,
      AgencyID2: agencyCallState?.agencyCode2,
      AgencyID3: agencyCallState?.agencyCode3,
      AgencyID4: agencyCallState?.agencyCode4,
      AgencyID5: agencyCallState?.agencyCode5,
      IsActive: agencyCallState?.IsActive ? 1 : 0,
      OTHER: agencyCallState?.other ? 1 : 0
    };
    const response = await MasterTableListServices.insertCFSAgencyCallFilter(data);
    if (response?.status === 200) {
      toastifySuccess(isUpdate ? "Data Updated Successfully" : "Data Saved Successfully");
      handelCancel();
      refetch();
    }
    handelCancel()
  }


  const getFilteredOptions = (selectedKey) => {
    const selectedAgencies = [
      agencyCallState?.agencyCode,
      agencyCallState?.agencyCode2,
      agencyCallState?.agencyCode3,
      agencyCallState?.agencyCode4,
      agencyCallState?.agencyCode5,
    ].filter(Boolean);

    return agencyCodeDropDown.filter(
      (officer) =>
        !selectedAgencies.includes(officer?.AgencyID) || selectedKey === officer?.AgencyID
    );
  };

  function handelSetEditData(data) {
    setAgencyCallState({
      CallforServiceID: data?.CadeCFSagencyID,
      serviceCode: data?.CallforServiceID,
      description: data?.Description,
      law: data?.LAW,
      fire: data?.FIRE,
      ems: data?.EMERGENCYMEDICALSERVICE,
      law: data?.LAW,
      agencyCode: data?.AgencyID1,
      agencyCode2: data?.AgencyID2,
      agencyCode3: data?.AgencyID3,
      agencyCode4: data?.AgencyID4,
      agencyCode5: data?.AgencyID5,
      IsActive: data?.IsActive
    })
  }


  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 incident-tab ">
              <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); setSearchValue1(""); setSearchValue2(""); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); setSearchValue1(""); setSearchValue2(""); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
              </ul>
            </div>
            {
              pageStatus === '1' ?
                <>
                  <div className='utilities-tab-content-form-main'>
                    {/* line 1 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Call For Service Code{errorAgencyCallState.serviceCode && isEmpty(agencyCallState.serviceCode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Service Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-3 d-flex align-self-center justify-content-end">
                        <Select
                          styles={coloredStyle_Select}
                          placeholder="Select"
                          options={csfDropDown}
                          isClearable
                          getOptionLabel={(v) => `${v?.CFSCODE} | ${v?.CFSCodeDescription}`}
                          getOptionValue={(v) => v?.CallforServiceID}
                          value={agencyCallState.serviceCode ? csfDropDown?.find((i) => i?.CallforServiceID === agencyCallState.serviceCode) : ""}
                          formatOptionLabel={(option, { context }) => {
                            return context === 'menu'
                              ? `${option?.CFSCODE} | ${option?.CFSCodeDescription}`
                              : option?.CFSCODE;
                          }}
                          onChange={(e) => { handleAgencyCallState("serviceCode", e?.CallforServiceID); handleAgencyCallState("description", e.CFSCodeDescription || "") }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Description{errorAgencyCallState.description && isEmpty(agencyCallState.description) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Description"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-4 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Description'
                          value={agencyCallState.description}
                          onChange={(e) => { handleAgencyCallState("description", e.target.value) }}
                        />
                      </div>
                    </div>
                    {/* line 2 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-start justify-content-end">
                        <label for="" className="tab-form-label" style={{ marginTop: "10px" }}>
                          Required Agency Types{errorAgencyCallState.agencyType && agencyCallState.law === false && agencyCallState.fire === false && agencyCallState.ems === false && agencyCallState.other === false && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Agency Type"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-7 d-flex align-self-center justify-content-end">
                        <div className='agency-types-checkbox-container'>
                          {/* L : Law */}
                          <div className="agency-checkbox-item">
                            <input type="checkbox" checked={agencyCallState.law} onChange={(e) => { handleAgencyCallState("law", e.target.checked) }} />
                            <div className="agency-checkbox-text-container tab-form-label">
                              <span>L</span>
                              <span>|</span>
                              <span>Law</span>
                            </div>
                          </div>
                          {/* F : Fire */}
                          <div className="agency-checkbox-item">
                            <input type="checkbox" checked={agencyCallState.fire} onChange={(e) => { handleAgencyCallState("fire", e.target.checked) }} />
                            <div className="agency-checkbox-text-container tab-form-label">
                              <span>F</span>
                              <span>|</span>
                              <span>Fire</span>
                            </div>
                          </div>
                          {/* E : Emergency Medical Service */}
                          <div className="agency-checkbox-item">
                            <input type="checkbox" checked={agencyCallState.ems} onChange={(e) => { handleAgencyCallState("ems", e.target.checked) }} />
                            <div className="agency-checkbox-text-container tab-form-label">
                              <span>E</span>
                              <span>|</span>
                              <span>Emergency Medical Service </span>
                            </div>
                          </div>
                          {/* O : Law */}
                          <div className="agency-checkbox-item">
                            <input type="checkbox" checked={agencyCallState.other} onChange={(e) => { handleAgencyCallState("other", e.target.checked) }} />
                            <div className="agency-checkbox-text-container tab-form-label">
                              <span>O</span>
                              <span>|</span>
                              <span>Other</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* line 3 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-start justify-content-end">
                        <label for="" className="tab-form-label" style={{ marginTop: "10px" }}>
                          Agency Code{errorAgencyCallState.agencyCode && isEmpty(agencyCallState.agencyCode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Agency Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={coloredStyle_Select}
                          placeholder="Select"
                          isClearable
                          options={getFilteredOptions(agencyCallState?.agencyCode)}
                          value={agencyCallState?.agencyCode ? agencyCodeDropDown?.find((i) => i?.AgencyID === agencyCallState?.agencyCode) : ""}
                          getOptionLabel={(v) => v?.ShortName}
                          getOptionValue={(v) => v?.AgencyID}
                          onChange={(e) => { handleAgencyCallState("agencyCode", e?.AgencyID) }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          isClearable
                          options={getFilteredOptions(agencyCallState?.agencyCode2)}
                          value={agencyCallState?.agencyCode2 ? agencyCodeDropDown?.find((i) => i?.AgencyID === agencyCallState?.agencyCode2) : ""}
                          getOptionLabel={(v) => v?.ShortName}
                          getOptionValue={(v) => v?.AgencyID}
                          onChange={(e) => { handleAgencyCallState("agencyCode2", e?.AgencyID) }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          isClearable
                          options={getFilteredOptions(agencyCallState?.agencyCode3)}
                          value={agencyCallState?.agencyCode3 ? agencyCodeDropDown?.find((i) => i?.AgencyID === agencyCallState?.agencyCode3) : ""}
                          getOptionLabel={(v) => v?.ShortName}
                          getOptionValue={(v) => v?.AgencyID}
                          onChange={(e) => { handleAgencyCallState("agencyCode3", e?.AgencyID) }}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          isClearable
                          options={getFilteredOptions(agencyCallState?.agencyCode4)}
                          value={agencyCallState?.agencyCode4 ? agencyCodeDropDown?.find((i) => i?.AgencyID === agencyCallState?.agencyCode4) : ""}
                          getOptionLabel={(v) => v?.ShortName}
                          getOptionValue={(v) => v?.AgencyID}
                          onChange={(e) => { handleAgencyCallState("agencyCode4", e?.AgencyID) }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                </>
            }
          </div>
        </div>
        <div className='utilities-tab-content-table-container'>
          <div className="row">
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Code...'
                value={searchValue1}
                onChange={(e) => {
                  setSearchValue1(e.target.value);
                  const result = Filter(listData, e.target.value, searchValue2, filterTypeIdOption, 'CallforServiceID', 'Description')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end">
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                value={searchValue2}
                placeholder='Search By Description...'
                onChange={(e) => {
                  setSearchValue2(e.target.value);
                  const result = Filter(listData, searchValue1, e.target.value, filterTypeDescOption, 'CallforServiceID', 'Description')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end">
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeDescOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="table-responsive">
            <DataTable
              dense
              columns={columns}
              data={filterListData}
              customStyles={tableCustomStyles}
              pagination
              responsive
              persistTableHead={true}
              striped
              highlightOnHover
              noDataComponent={isNoData ? "There are no data to display" : 'There are no data to display'}
              fixedHeader
              fixedHeaderScrollHeight="360px"
              onRowClicked={(row) => {
                handelSetEditData(row);
              }}
            />
          </div>
          {parseInt(pageStatus) === 1 &&
            <div className="utilities-tab-content-button-container" >
              <button type="button" className="btn btn-sm btn-success" onClick={() => handelCancel()}>New</button>
              <button type="button" className="btn btn-sm btn-success" onClick={() => handelSave()}>{agencyCallState?.CallforServiceID ? "Update" : "Save"}</button>
            </div>}
        </div>
      </div>
      <CADConfirmModal showModal={showModal} setShowModal={setShowModal} handleConfirm={handelActiveInactive} confirmType={confirmType} />

    </>
  );
};

export default CFSAgencyCallFilterSection;