import React, { memo } from 'react';
import NameTab from '../../../../Components/Pages/Name/NameTab';

const NameTabSectionModal = () => {

    return (
        <>
            <NameTab isCad />
        </>
    );
};

export default memo(NameTabSectionModal);
