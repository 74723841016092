export const OffenderUseError_N = '{207} Mutually exclusive values for offenderusing';
export const OffenderUseError_Other = '{204} Offender Using must be A,C,D or N';
export const BiasError = '{204} Biascode invalid';
export const WeaponError = '{207} Mutually exclusive values for Weapon Code';
export const MethodEntryError = '{204} Method of Entry is invalid';
export const MethodEntryError2 = '{253} Method of Entry Must be present when offense code is 220';
export const Bias_90C_Error = 'Bias Must be ‘None’ when the Nibrs code is 09C - Justifiable Homicide.';
export const LocationError = '{257} Number Of Premises Must be present Only with an offense code of 220 and a location type of 14 or 19';
export const NotApplicableError = 'After selecting Not Applicable then the user should not be able to select the other values';
export const MethodOFEntryMandataryError = "When UCR Offense Code of '220-Burglary/Breaking & Entering' has been entered Method of Entry Must be present";
export const CrimeActivitySelectNoneError = "Select None/Unknown in Criminal Activity";
export const CrimeActivitySelectSuitableCodesError = "Select suitable nibrs Codes for selecting Criminal Activities";

export const ErrorTooltip = ({ ErrorStr }) => (<span className='hovertext' style={{ marginLeft: '15px' }} data-hover={ErrorStr} ><i className='fa fa-exclamation-circle'></i></span>);

export const ErrorShow = ({ ErrorStr }) => (<span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{ErrorStr}</span>);

// bias 90C
export const ErrorStyle_NIBRS_09C = (code) => {
    const colour_Styles_NIBRS = {
        control: (styles) => ({
            ...styles,
            backgroundColor: code === '09C' ? "rgb(255 202 194)" : "#fce9bf",
            minHeight: 58,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };
    return colour_Styles_NIBRS;
};

// CriminalActivity
export const ErrorStyle_CriminalActivity = (status) => {
    const colour_Styles_CriminalActivity = {
        control: (styles) => ({
            ...styles,
            backgroundColor: status ? "rgb(255 202 194)" : "#fce9bf",
            minHeight: 58,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };
    return colour_Styles_CriminalActivity;
};

// not use in any components
export const ErrorStyleNIBRS = (code) => {
    const colour_Styles_NIBRS = {
        control: (styles) => ({
            ...styles,
            backgroundColor: code === '09C' ? "rgb(255 202 194)" : "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };
    return colour_Styles_NIBRS;
};
// not use in any components
export const ErrorStyleLocation = (code, nibrsCode, premisesentered) => {
    const colour_Styles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: (code === '14' || code === '19') && nibrsCode === '220' && premisesentered == '0' ? "rgb(255 202 194)" : '#ffff',
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };
    return colour_Styles;
};
//  use in any BasicInfo 
export const ErrorStyleWeapon = (code) => {
    const colour_Styles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: code === '99' ? "rgb(255 202 194)" : '#fff',
            minHeight: 60,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };
    return colour_Styles;
};
//  use in assaultInjuryCom
export const ErrorStyleOffenderUse = (data) => {
    const res = data?.filter((obj) => {
        if (obj.code === 'N') return 'N'
        else if (obj.code === 'A' || obj.code === 'C' || obj.code === 'D') { return obj.code }
        else return "Not"
    })
    return res[0]?.code;
};
// not use in any components
export const ErrorStyleBias = (data) => {
    const arr = []
    const res = data?.map((obj) => {
        if (obj.code === "11" || obj.code === "12" || obj.code === "13" || obj.code === "14" || obj.code === "15" || obj.code === "21" || obj.code === "22" || obj.code === "23" || obj.code === "24" || obj.code === "25" || obj.code === "26" || obj.code === "27" || obj.code === "32" || obj.code === "33" || obj.code === "41" || obj.code === "42" || obj.code === "43" || obj.code === "44" || obj.code === "45" || obj.code === "51" || obj.code === "52" || obj.code === "88" || obj.code === "99") {
            return obj.code
        } else arr.push({ code: 'N' })
    })
    if (arr.length > 0) return 'N'
    else return 'y';
};

// multiUseFunction for Nibrs Error

//offence BasicInformation
export const checkWeaponTypeIsRequire = (code) => {
    if (
        (
            code === '09A' ||
            code === '09B' ||
            code === '09C' ||
            code === '100' ||
            code === '11A' ||
            code === '11B' ||
            code === '11C' ||
            code === '11D' ||
            code === '120' ||
            code === '13A' ||
            code === '13B' ||
            code === '210' ||
            code === '520' ||
            code === '521' ||
            code === '522' ||
            code === '526' ||
            code === '64A' ||
            code === '64B'
        ) && process.env.REACT_APP_NIBRS_STATE === 'Rajasthan'
    ) { return true; }
    else return false;
}

export const checkMethodOfEntryIsRequire = (code) => {
    if (code === '220' && process.env.REACT_APP_NIBRS_STATE === 'Rajasthan') { return true; }
    else { return false; }
}

export const checkCriminalActivityIsRequire = (code) => {
    if (
        (
            code === '250' ||
            code === '280' ||
            code === '30C' ||
            code === '35A' ||
            code === '35B' ||
            code === '39C' ||
            code === '370' ||
            code === '49A' ||
            code === '520' ||
            code === '521' ||
            code === '522' ||
            code === '526' ||
            code === '58A' ||
            code === '58B' ||
            code === '61A' ||
            code === '61B' ||
            code === '620' ||
            code === '720'
        ) && process.env.REACT_APP_NIBRS_STATE === 'Rajasthan'
    ) { return true; }
    else return false;
}

export const checkCrimeActiSuitableCode = (nibrsCode, crimeActSelectedCodeArray) => {
    const crimeActivityCodeArray = ["B", "C", "D", "E", "O", "P", "T", "U"]

    const crimeActivityCodeArraySet = new Set(crimeActivityCodeArray);
    const crimeActivityCommanCodes = crimeActSelectedCodeArray?.filter(value => crimeActivityCodeArraySet.has(value));

    if (crimeActivityCommanCodes?.length > 0 && (nibrsCode === '250 ' || nibrsCode === '280' || nibrsCode === '30C' || nibrsCode === '35A' || nibrsCode === '35B' || nibrsCode === '39C' ||
        nibrsCode === '370' || nibrsCode === '49A' || nibrsCode === '520' || nibrsCode === '521' || nibrsCode === '522' || nibrsCode === '526' ||
        nibrsCode === '58A' || nibrsCode === '58B' || nibrsCode === '61A' || nibrsCode === '61B' || nibrsCode === '620') && process.env.REACT_APP_NIBRS_STATE === 'Rajasthan') {

        return false;

    } else {
        return true;
    }
}
