import React, { useContext, useEffect, useState, useRef } from 'react';
import Select from "react-select";
import SubTab from '../../../../Utility/Tab/SubTab';
import { VictimTabs } from '../../../../Utility/Tab/TabsArray';
import Offense from './VictimTab/Offense/Offense';
import Relationship from './VictimTab/Relationship/Relationship';
import InjuryType from './VictimTab/InjuryType/InjuryType';
import JustifiableHomicide from './VictimTab/JustifiableHomicide/JustifiableHomicide';
import AssaultType from './VictimTab/AssaultType/AssaultType';
import Property from './VictimTab/Property/Property';
import Officer from './VictimTab/Officer/Officer';
import { Decrypt_Id_Name } from '../../../../Common/Utility';
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api';
import { Comman_changeArrayFormat, threeColArray } from '../../../../Common/ChangeArrayFormat';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import FindListDropDown from '../../../../Common/FindListDropDown';
import { NaVictimListDropDownArray } from '../../../../Utility/ListDropDownArray/ListDropArray';
import { RequiredFieldIncident } from '../../../Utility/Personnel/Validation';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { VictimError } from '../../../../NIBRSError';
import IdentifyFieldColor from '../../../../Common/IdentifyFieldColor';
import Ori from './VictimTab/ORI/Ori';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Home from './VictimTab/Home/Home';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';
import NameListing from '../../../ShowAllList/NameListing';
import { get_ScreenPermissions_Data } from '../../../../../redux/actions/IncidentAction';
import ChangesModal from '../../../../Common/ChangesModal';
import { ErrorTooltip, SocietyPublicError, vectimTypeError, victimNibrsErrors } from '../../NameNibrsErrors';


const Victim = (props) => {

    const { ListData, DecNameID, DecMasterNameID, DecIncID } = props

    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';

    const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);

    const { nameSingleData, setChangesStatus, get_Name_Count, setVictimCount, setNameVictimCount } = useContext(AgencyContext);

    const SelectedValue = useRef();
    const [showPage, setShowPage] = useState('home');
    const [victimTypeDrp, setVictimTypeDrp] = useState([]);
    const [bodyArmorDrp, setBodyArmorDrp] = useState([]);
    const [callTypeDrp, setCallTypeDrp] = useState([]);
    const [additionalJustiDrp, setAdditionalJustiDrp] = useState([]);
    const [assignmentTypeDrp, setAssignmentTypeDrp] = useState([]);
    const [editval, setEditval] = useState();
    const [victimStatus, setVictimStatus] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const [victimID, setVictimID] = useState();

    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID,] = useState('');
    const [nameID, setNameID] = useState();
    const [incidentID, setIncidentID] = useState('');
    const [statesChangeStatus, setStatesChangeStatus] = useState(false);
    //NibrsErrors
    const [offenceCodes, setOffenceCodes] = useState([]);
    const [isCrimeAgainsPerson, setIsCrimeAgainsPerson] = useState();
    const [isCrimeAgainstProperty, setIsCrimeAgainstProperty] = useState();
    const [isCrimeAgainstSociety, setIsCrimeAgainstSociety] = useState();
    const [raceSexAgeStatus, setRaceSexAgeStatus] = useState(false);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    let MstPage = query?.get('page');

    const [value, setValue] = useState({
        'VictimTypeID': '', 'BodyArmorID': '', 'CallTypeID': '', 'AdditionalJustificationID': '', 'AssignmentTypeID': '',
        'CreatedByUserFK': '', 'NameID': '', 'ModifiedByUserFK': '', 'VictimID': '', 'VictemTypeCode': '',
        'IsMaster': MstPage === "MST-Name-Dash" ? true : false, 'MasterNameID': ''
    });

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
            dispatch(get_ScreenPermissions_Data("N057", localStoreData?.AgencyID, localStoreData?.PINID));
        }
    }, [localStoreData]);

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (DecNameID && DecMasterNameID) {
            setValue(pre => { return { ...pre, 'CreatedByUserFK': loginPinID, 'NameID': DecNameID, 'VictemTypeCode': null, 'MasterNameID': DecMasterNameID, 'IsMaster': MstPage === "MST-Name-Dash" ? true : false } });
        }
    }, [DecNameID, DecMasterNameID, loginPinID]);

    const [errors, setErrors] = useState({
        'VictimTypeIDErrors': '', 'CallTypeError': '', 'AssignmentTypeIDError': '',
    })

    useEffect(() => {
        setValue(pre => { return { ...pre, 'CreatedByUserFK': loginPinID, 'NameID': DecNameID, 'VictemTypeCode': null, 'MasterNameID': DecMasterNameID, 'IsMaster': MstPage === "MST-Name-Dash" ? true : false } });
        GetSingleData(DecNameID);

    }, [DecNameID, loginPinID])

    const GetSingleData = (DecMasterNameID) => {
        const val = { 'NameID': DecMasterNameID }
        fetchPostData('Victim/GetData_Victim', val).then((res) => {
            if (res.length != 0) {
                // console.log(res[0]?.Offense)
                const offenceCodesArr = res[0]?.Offense?.length > 0 ? res[0]?.Offense?.map((item) => { return item?.FBICode }) : []
                setOffenceCodes(offenceCodesArr);

                const IsCrimeAgains_Person = res[0]?.Offense?.length > 0 ? res[0]?.Offense?.map((item) => { if (item?.IsCrimeAgains_Person) { return true } }) : []
                // console.log('IsCrimeAgains_Person', IsCrimeAgains_Person?.length > 0)
                setIsCrimeAgainsPerson(IsCrimeAgains_Person?.length > 0)

                const IsCrimeAgainstProperty = res[0]?.Offense?.length > 0 ? res[0]?.Offense?.filter((item) => { if (item?.IsCrimeAgainstProperty) { return true } }) : []
                // console.log('IsCrimeAgainstProperty', IsCrimeAgainstProperty?.length > 0)
                setIsCrimeAgainstProperty(IsCrimeAgainstProperty?.length > 0)

                const IsCrimeAgainstSociety = res[0]?.Offense?.length > 0 ? res[0]?.Offense?.map((item) => { if (item?.IsCrimeAgainstSociety) { return true } }) : []
                // console.log('IsCrimeAgainstSociety', IsCrimeAgainstProperty?.length > 0)
                setIsCrimeAgainstSociety(IsCrimeAgainstSociety?.length > 0)

                setEditval(res);
                setVictimStatus(true);
                setUpdateCount(updateCount + 1);

            } else { setEditval([]); setVictimStatus(false); }
        })
    }

    useEffect(() => {
        if (editval) {
            setValue({
                ...value,
                'VictimTypeID': editval[0]?.VictimTypeID,
                'BodyArmorID': editval[0]?.BodyArmorID,
                'CallTypeID': editval[0]?.CallTypeID,
                'AdditionalJustificationID': editval[0]?.AdditionalJustificationID,
                'AssignmentTypeID': editval[0]?.AssignmentTypeID,
                'VictimID': editval[0]?.VictimID,
                'ModifiedByUserFK': loginPinID,
                'VictemTypeCode': editval[0]?.VictimCode
            })
            setVictimID(editval[0]?.VictimID)
        } else {
            reset_Value_Data();
        }
    }, [editval, updateCount])

    const check_Validation_Error = (e) => {
        const VictimTypeIDErr = RequiredFieldIncident(value.VictimTypeID);
        const CallTypeErr = process.env.REACT_APP_NIBRS_STATE === 'Rajasthan' ? value?.VictemTypeCode == 'L' ? RequiredFieldIncident(value.CallTypeID) : 'true' : 'true'
        const AssignmentTypeIDErr = process.env.REACT_APP_NIBRS_STATE === 'Rajasthan' ? value?.VictemTypeCode == 'L' ? RequiredFieldIncident(value.AssignmentTypeID) : 'true' : 'true'

        setErrors(prevValues => {
            return {
                ...prevValues,
                ['VictimTypeIDErrors']: VictimTypeIDErr || prevValues['VictimTypeIDErrors'],
                ['CallTypeError']: CallTypeErr || prevValues['CallTypeError'],
                ['AssignmentTypeIDError']: AssignmentTypeIDErr || prevValues['AssignmentTypeIDError'],
            }
        })

        // RequiredFieldIncident(value.VictimTypeID) && setErrors(prevValues => { return { ...prevValues, ['VictimTypeIDErrors']: RequiredFieldIncident(value.VictimTypeID) } })
    }

    const { VictimTypeIDErrors, CallTypeError, AssignmentTypeIDError } = errors

    useEffect(() => {
        if (VictimTypeIDErrors === 'true' && CallTypeError === 'true' && AssignmentTypeIDError === 'true') {
            if (victimStatus) { UpdateVictim(); } else { AddVictim(); }
        }
    }, [VictimTypeIDErrors, CallTypeError, AssignmentTypeIDError])

    useEffect(() => {
        if (loginAgencyID) {
            get_Victim_Type_Data(loginAgencyID); get_Body_Armor_Data(loginAgencyID); get_Call_Type_Data(loginAgencyID); get_Additional_justificaion_Data(loginAgencyID); get_AssignmentType_Data(loginAgencyID);
        }
    }, [loginAgencyID])

    const get_AssignmentType_Data = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('VictimAssignmentType/GetDataDropDown_VictimAssignmentType', val).then((data) => {
            if (data) {
                setAssignmentTypeDrp(Comman_changeArrayFormat(data, 'VictimAssignmentTypeID', 'Description'))
            } else {
                setAssignmentTypeDrp([]);
            }
        })
    }

    const get_Additional_justificaion_Data = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('AdditionalJustification/GetDataDropDown_AdditionalJustification', val).then((data) => {
            if (data) {
                setAdditionalJustiDrp(Comman_changeArrayFormat(data, 'AdditionalJustificationID', 'Description'))
            } else {
                setAdditionalJustiDrp([]);
            }
        })
    }

    const get_Call_Type_Data = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('VictimCallType/GetDataDropDown_VictimCallType', val).then((data) => {
            if (data) {
                setCallTypeDrp(Comman_changeArrayFormat(data, 'VictimCallTypeID', 'Description'))
            } else {
                setCallTypeDrp([]);
            }
        })
    }

    const get_Body_Armor_Data = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('BodyArmor/GetDataDropDown_BodyArmor', val).then((data) => {
            if (data) {
                setBodyArmorDrp(Comman_changeArrayFormat(data, 'BodyArmorID', 'Description'))
            } else {
                setBodyArmorDrp([]);
            }
        })
    }

    const get_Victim_Type_Data = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('VictimType/GetDataDropDown_VictimType', val).then((data) => {
            if (data) {
                setVictimTypeDrp(threeColArray(data, 'VictimTypeID', 'Description', 'VictimCode'))
            } else {
                setVictimTypeDrp([]);
            }
        })
    }

    const ChangeDropDown = (e, name) => {
        setStatesChangeStatus(true); setChangesStatus(true)
        if (e) {
            if (name === 'VictimTypeID') {
                // console.log(nameSingleData)
                // setValue({ ...value, [name]: e.value, ['VictemTypeCode']: e.id });
                // setErrors({ ...errors, 'CallTypeError': '', 'AssignmentTypeIDError': '' });

                console.log(e.id);
                if (e.id == 'L') {
                    setValue({ ...value, [name]: e.value, ['VictemTypeCode']: e.id });
                    setErrors({ ...errors, 'CallTypeError': '', 'AssignmentTypeIDError': '' });

                    if (!nameSingleData[0]?.Gender_Code || !nameSingleData[0]?.Race_Code || !nameSingleData[0]?.AgeFrom) {
                        setRaceSexAgeStatus(true)
                    } else {
                        setRaceSexAgeStatus(false);
                    }
                } else {
                    setValue({ ...value, [name]: e.value, ['VictemTypeCode']: e.id, 'AssignmentTypeID': '', });
                    setErrors({ ...errors, 'CallTypeError': '', 'AssignmentTypeIDError': '' });
                }
            } else {
                setValue({ ...value, [name]: e.value });
            }
        } else {
            if (name === 'VictimTypeID') {
                // console.log(e);
                setValue({ ...value, [name]: '', 'VictemTypeCode': '' });
                setErrors({ ...errors, 'CallTypeError': '', 'AssignmentTypeIDError': '' });
            } else {
                setValue({ ...value, [name]: null, });
            };
        }
    }

    const AddVictim = () => {
        AddDeleteUpadate('Victim/Insert_Victim', value).then((res) => {
            if (res) {
                if (res.VictimID) {
                    setChangesStatus(false);
                    GetSingleData(DecNameID)
                    get_Name_Count(DecNameID, DecMasterNameID);
                    setVictimCount(true);
                }
                toastifySuccess(res.Message);
                setStatesChangeStatus(false);
                setErrors({
                    ...errors,
                    ['VictimTypeIDErrors']: '',
                });
            }
        })
    }

    const UpdateVictim = () => {
        AddDeleteUpadate('Victim/Update_Victim', value).then((res) => {
            if (res) {
                const parsedData = JSON.parse(res.data);
                const message = parsedData.Table[0].Message;
                setChangesStatus(false);
                toastifySuccess(message);
                setVictimCount(true);
                get_Name_Count(DecNameID, DecMasterNameID);
                setStatesChangeStatus(false);
                setErrors({
                    ...errors,
                    ['VictimTypeIDErrors']: '',
                });
            }
        })
    }

    const DeletePin = () => {
        const val = {
            'VictimID': victimID,
            'DeletedByUserFK': loginPinID,
        }
        AddDeleteUpadate('Victim/Delete_Victim', val).then((res) => {
            if (res) {
                const parsedData = JSON.parse(res.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message); setValue({ ...value, ['NameID']: '' }); reset_Value_Data();
                setVictimStatus(false); setErrors('')
                get_Name_Count(DecNameID, DecMasterNameID);
                setNameVictimCount('');
                setVictimCount(false);
                setShowPage('home');
            } else console.log("Somthing Wrong");
        })
    }

    const reset_Value_Data = () => {
        setValue({
            ...value,
            'VictimTypeID': '',
            'BodyArmorID': '',
            'CallTypeID': '',
            'AdditionalJustificationID': '',
            'AssignmentTypeID': '',
            'ModifiedByUserFK': ''
        });
        setStatesChangeStatus(false);
        setVictimStatus(false)
    }

    const ColorStyle = {
        control: base => ({
            ...base,
            backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const ErrorColorStyle = {
        control: base => ({
            ...base,
            backgroundColor: "rgb(255 202 194)",
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    return (
        <>
            <NameListing  {...{ ListData }} />
            <div className="col-12">
                <div className="row">
                    <div className="col-3 col-md-3 col-lg-2 mt-3">
                        <Link to={'/ListManagement?page=Type%20of%20Victim&call=/Name-Home'} className='new-link'>
                            Victim Type {
                                process.env.REACT_APP_NIBRS_STATE === 'Rajasthan' ?
                                    raceSexAgeStatus && value?.VictemTypeCode == 'L' ?
                                        <>
                                            {
                                                // ErrorTooltip(SocietyPublicError)
                                                <ErrorTooltip ErrorStr={SocietyPublicError} />
                                            }
                                        </>
                                        :
                                        victimNibrsErrors(value?.VictemTypeCode, offenceCodes, 'tool', isCrimeAgainsPerson, isCrimeAgainstProperty, isCrimeAgainstSociety, nameSingleData)
                                    :
                                    <></>
                            }
                            {errors.VictimTypeIDErrors !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.VictimTypeIDErrors}</p>
                            ) : null}
                        </Link>
                    </div>
                    <div className="col-3 col-md-3 col-lg-4  mt-2" >
                        <Select
                            name='VictimTypeID'
                            value={victimTypeDrp?.filter((obj) => obj.value === value?.VictimTypeID)}
                            styles={
                                process.env.REACT_APP_NIBRS_STATE === 'Rajasthan' ?
                                    raceSexAgeStatus && value?.VictemTypeCode == 'L' ?
                                        { ErrorColorStyle }
                                        :
                                        victimNibrsErrors(value?.VictemTypeCode, offenceCodes, 'Color', isCrimeAgainsPerson, isCrimeAgainstProperty, isCrimeAgainstSociety, nameSingleData)
                                    :
                                    ColorStyle
                            }
                            isClearable
                            options={victimTypeDrp}
                            onChange={(e) => { ChangeDropDown(e, 'VictimTypeID'); }}
                            placeholder="Select.."
                            ref={SelectedValue}
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-3">
                        <Link to={'/ListManagement?page=Body%20Armor&call=/Name-Home'} className='new-link'>
                            Body Armor
                        </Link>
                    </div>
                    <div className="col-3 col-md-3 col-lg-4  mt-2" >
                        <Select
                            name='BodyArmorID'
                            value={bodyArmorDrp?.filter((obj) => obj.value === value?.BodyArmorID)}
                            styles={customStylesWithOutColor}
                            isClearable
                            options={bodyArmorDrp}
                            onChange={(e) => { ChangeDropDown(e, 'BodyArmorID'); }}
                            placeholder="Select.."
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-3">
                        <Link to={'/ListManagement?page=Victim%20Call%20Type&call=/Name-Home'} className='new-link'>
                            Call Type
                            {errors.CallTypeError !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.CallTypeError}</p>
                            ) : null}
                        </Link>
                    </div>
                    <div className="col-3 col-md-3 col-lg-4  mt-2" >
                        <Select
                            name='CallTypeID'
                            value={callTypeDrp?.filter((obj) => obj.value === value?.CallTypeID)}
                            styles={
                                process.env.REACT_APP_NIBRS_STATE === 'Rajasthan'
                                    ?
                                    value?.VictemTypeCode === 'L'
                                        ?
                                        ColorStyle
                                        :
                                        customStylesWithOutColor
                                    :
                                    customStylesWithOutColor
                            }
                            // styles={customStylesWithOutColor}
                            isClearable
                            options={callTypeDrp}
                            onChange={(e) => { ChangeDropDown(e, 'CallTypeID'); }}
                            placeholder="Select.."
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-3">
                        <Link to={'/ListManagement?page=Additional%20Justification&call=/Name-Home'} className='new-link'>
                            Additional Justification
                        </Link>
                    </div>
                    <div className="col-3 col-md-3 col-lg-4  mt-2" >
                        <Select
                            name='AdditionalJustificationID'
                            value={additionalJustiDrp?.filter((obj) => obj.value === value?.AdditionalJustificationID)}
                            styles={customStylesWithOutColor}
                            isClearable
                            options={additionalJustiDrp}
                            onChange={(e) => { ChangeDropDown(e, 'AdditionalJustificationID'); }}
                            placeholder="Select.."
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-3">
                        <Link to={'/ListManagement?page=Officer%20Assignment%20Type&call=/Name-Home'} className='new-link'>
                            Assignment Type {errors.AssignmentTypeIDError !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.AssignmentTypeIDError}</p>
                            ) : null}
                        </Link>
                    </div>
                    <div className="col-3 col-md-3 col-lg-4  mt-2" >
                        <Select
                            name='AssignmentTypeID'
                            value={assignmentTypeDrp?.filter((obj) => obj.value === value?.AssignmentTypeID)}
                            styles={
                                process.env.REACT_APP_NIBRS_STATE === 'Rajasthan'
                                    ?
                                    value?.VictemTypeCode === 'L'
                                        ?
                                        ColorStyle
                                        :
                                        customStylesWithOutColor
                                    :
                                    customStylesWithOutColor
                            }
                            // styles={customStylesWithOutColor}
                            isDisabled={
                                process.env.REACT_APP_NIBRS_STATE === 'Rajasthan'
                                    ?
                                    value?.VictemTypeCode === 'L'
                                        ?
                                        false
                                        :
                                        true
                                    :
                                    false
                            }
                            isClearable
                            options={assignmentTypeDrp}
                            onChange={(e) => { ChangeDropDown(e, 'AssignmentTypeID'); }}
                            placeholder="Select.."
                        />
                    </div>
                    <div className="col-6 col-md-6 col-lg-6 text-right  mt-3 pt-2">
                        {
                            victimStatus ?
                                effectiveScreenPermission ?
                                    effectiveScreenPermission[0]?.Changeok ?
                                        <>
                                            <button type="button" className="btn btn-sm btn-success mr-1" disabled={!statesChangeStatus} onClick={(e) => { check_Validation_Error(); }}>Update</button>
                                            {
                                                effectiveScreenPermission ?
                                                    effectiveScreenPermission[0]?.DeleteOK ?
                                                        <button type="button" className="btn btn-sm btn-success  mr-1" data-toggle="modal" data-target="#DeleteModal" >Delete</button>
                                                        : <></>
                                                    : <button type="button" className="btn btn-sm btn-success  mr-1" data-toggle="modal" data-target="#DeleteModal" >Delete</button>
                                            }
                                        </>
                                        :
                                        <>
                                        </>
                                    :
                                    <>
                                        <button type="button" className="btn btn-sm btn-success mr-1" disabled={!statesChangeStatus} onClick={(e) => { check_Validation_Error(); }}>Update</button>
                                        {
                                            effectiveScreenPermission ?
                                                effectiveScreenPermission[0]?.DeleteOK ?
                                                    <button type="button" className="btn btn-sm btn-success  mr-1" data-toggle="modal" data-target="#DeleteModal" >Delete</button>
                                                    : <></>
                                                : <button type="button" className="btn btn-sm btn-success  mr-1" data-toggle="modal" data-target="#DeleteModal" >Delete</button>
                                        }
                                    </>
                                :
                                effectiveScreenPermission ?
                                    effectiveScreenPermission[0]?.AddOK ?
                                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>
                                        :
                                        <>
                                        </>
                                    :
                                    <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>
                        }
                    </div>
                </div>
            </div>
            <div className={`col-12 col-md-12`}>
                <div className="row px-0">
                    <div className="col-12 mt-3">
                        <SubTab tabs={VictimTabs} showPage={showPage} setShowPage={setShowPage} status={victimStatus} />
                    </div>
                </div>
            </div>
            {
                showPage === 'home' && victimStatus ?
                    <Home {...{ victimID, DecNameID, loginPinID, DecIncID, loginAgencyID }} />
                    :
                    showPage === 'offense' && victimStatus ?
                        <Offense {...{ victimID, DecNameID, loginPinID, DecIncID, loginAgencyID }} />
                        :
                        showPage === 'relationship' ?
                            <Relationship {...{ victimID, DecNameID, loginPinID, DecIncID, loginAgencyID, nameSingleData }} />
                            :
                            showPage === 'InjuryType' && victimStatus ?
                                <InjuryType {...{ victimID, nameID, loginPinID, incidentID, loginAgencyID, }} />
                                :
                                showPage === 'JustifiableHomicide' && victimStatus ?
                                    <JustifiableHomicide {...{ victimID, nameID, loginPinID, incidentID, loginAgencyID, }} />
                                    :
                                    showPage === 'AssaultType' && victimStatus ?
                                        <AssaultType {...{ victimID, nameID, loginPinID, incidentID, loginAgencyID, }} />
                                        :
                                        showPage === 'Officer' && victimStatus ?
                                            <Officer {...{ victimID, nameID, loginPinID, incidentID, loginAgencyID, }} />
                                            :
                                            showPage === 'Property' && victimStatus ?
                                                <Property {...{ victimID, nameID, loginPinID, incidentID, loginAgencyID, }} />
                                                :
                                                showPage === 'ORI' && victimStatus ?
                                                    <Ori {...{ victimID, nameID, loginPinID, incidentID, loginAgencyID, }} />
                                                    :
                                                    <></>
            }
            <IdentifyFieldColor />
            <DeletePopUpModal func={DeletePin} />
            <ChangesModal func={check_Validation_Error} />
        </>
    )
}

export default Victim