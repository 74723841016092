import React, { memo, useContext, useEffect, useState } from "react";
import Select from "react-select";
import { dropDownDataModel, isEmptyObject } from "../../CADUtils/functions/common";
import MasterTableListServices from "../../CADServices/APIs/masterTableList";
import MonitorServices from "../../CADServices/APIs/monitor";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import useArrState from "../../CADHook/useArrState";
import { toastifySuccess } from "../../Components/Common/AlertMsg";
import { IncidentContext } from "../../CADContext/Incident";
import { faL } from "@fortawesome/free-solid-svg-icons";


const OnOffDutyModal = (props) => {
  const defaultRow = {
    resource: null,
    shift: null,
    zone: null,
    resourceType: null,
    primaryOfficer: null,
    primaryOfficerTo: null,
    isDisabledResource: false,
    isDisabledType: false,
  };

  const { openOnOffDutyModal, setOnOffDutyModal } = props;
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [number, setNumber] = useState("")
  const { resourceRefetch, incidentRefetch, resourceData: resources } = useContext(IncidentContext);
  const [loginAgencyID, setLoginAgencyID] = useState();
  const [zoneDropDown, setZoneDropDown] = useState([])
  const [primaryOfficerDropDown, setPrimaryOfficerDropDown] = useState([])
  const [shiftDropDown, setShiftDropDown] = useState([])
  const [resourceData, setResourceData] = useState([])
  const [errors, setErrors] = useState([]);
  const [onOffStatus, setOnOffStatus] = useState("OnDuty")
  const [rowsState, setRowsState, addRowState, _updateRow, removeRow] = useArrState(
    []
  );
  const getMasterOnOfDutyConfigurationKey = `/CAD/MasterOnOfDutyConfiguration/GetMasterOnOfDutyConfiguration`;
  const { data: getOnOffDutyConfData, isSuccess: isFetchGetOnOffDutyConf, refetch: refetchOnOffDuty } = useQuery(
    [getMasterOnOfDutyConfigurationKey, { AgencyID: loginAgencyID },],
    MasterTableListServices.getMasterOnOfDutyConfiguration,
    {
      refetchOnWindowFocus: false,
      enabled: !!openOnOffDutyModal
    }
  );

  useEffect(() => {
    if (getOnOffDutyConfData && isFetchGetOnOffDutyConf) {
      const data = JSON.parse(getOnOffDutyConfData?.data?.data);
      setRowsState(Array(data?.Table[0]?.OnOfDutyconfigurationNO).fill(defaultRow))
    }
  }, [openOnOffDutyModal, getOnOffDutyConfData, isFetchGetOnOffDutyConf])


  const getResourcesKey = `/CAD/MonitorOnOffDuty/GetOnOffDuty/${onOffStatus === "OnDuty" ? 1 : 0}`;
  const { data: getResourcesData, isSuccess, refetch } = useQuery(
    [getResourcesKey, { OnOffDuty: onOffStatus === "OnDuty" ? 1 : 0 },],
    MonitorServices.getOnOffDuty,
    {
      refetchOnWindowFocus: false,
      enabled: !!openOnOffDutyModal
    }
  );
  useEffect(() => {
    if (isSuccess && getResourcesData) {
      const data = JSON.parse(getResourcesData?.data?.data || [])?.Table
      setResourceData(data)
    } else {
      setResourceData([])
    }
  }, [getResourcesData, isSuccess, onOffStatus])

  const getZoneKey = `/CAD/GeoPetrolZone/GetData_Zone`;
  const { data: getZoneData, isSuccess: isFetchGeoZoneData } = useQuery(
    [getZoneKey, { IsActive: 1 },],
    MasterTableListServices.getDataZone,
    {
      refetchOnWindowFocus: false,
    }
  );

  const GetResourceTypeKey = `/CAD/MasterResourceType/GetResourceType`;
  const { data: resourceTypeData } = useQuery(
    [GetResourceTypeKey, { Action: "GET", IsActive: 1 },],
    MasterTableListServices.getResourceType,
    {
      refetchOnWindowFocus: false,
    }
  );

  const shiftDataKey = `/CAD/MasterResourceShift/GetData_Shift`;
  const { data: shiftData, isSuccess: isFetchShiftData } = useQuery(
    [shiftDataKey, { AgencyID: loginAgencyID },],
    MasterTableListServices.getShift,
    {
      refetchOnWindowFocus: false,
      enabled: !!loginAgencyID
    }
  );

  const GetPrimaryOfficerKey = `CAD/MasterPrimaryOfficer/PrimaryOfficer`;
  const { data: getPrimaryOfficerData, isSuccess: isFetchPrimaryOfficers } = useQuery(
    [GetPrimaryOfficerKey, { AgencyID: loginAgencyID },],
    MasterTableListServices.getPrimaryOfficer,
    {
      refetchOnWindowFocus: false,
    }
  );


  useEffect(() => {
    if (onOffStatus === "OffDuty") {
      const available = resources.filter(item => item.Status === "AV");

      const updatedRows = available.map(item => {
        const selectedResource = resourceData?.find(res => res.ResourceID === item?.ResourceID);
        const [primaryOfficerID, primaryOfficerToID] =
          (selectedResource?.OfficerIDs || "")
            .split(',')
            .map(id => parseInt(id.trim()));

        return {
          resource: selectedResource,
          shift: shiftDropDown?.find(shift => shift.ShiftId === selectedResource?.ShiftID) || null,
          zone: zoneDropDown?.find(zone => zone.value === selectedResource?.ZoneID) || null,
          resourceType: resourceTypeData?.data?.data?.Table?.find((i) => i?.ResourceTypeID === item?.ResourceTypeID),
          primaryOfficer: primaryOfficerDropDown?.find(officer => officer?.PINID === primaryOfficerID) || null,
          primaryOfficerTo: primaryOfficerDropDown?.find(officer => officer?.PINID === primaryOfficerToID) || null,
          isDisabledType: item?.ResourceTypeID ? true : false,
          isDisabledResource: selectedResource ? true : false
        };
      });

      setRowsState(updatedRows);
    }
  }, [onOffStatus, primaryOfficerDropDown, resourceTypeData, resourceData, resources, setRowsState, shiftDropDown, zoneDropDown]);


  function handelAdd() {
    addRowState({
      resource: null,
      shift: null,
      zone: null,
      resourceType: null,
      primaryOfficer: null,
      primaryOfficerTo: null,
    })
  }

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID)
    }
  }, [localStoreData]);

  useEffect(() => {
    if (isFetchGeoZoneData && getZoneData) {
      const data = JSON.parse(getZoneData?.data?.data)?.Table || [];
      setZoneDropDown(dropDownDataModel(data, "ZoneID", "ZoneDescription"));
    }
  }, [isFetchGeoZoneData, getZoneData]);

  useEffect(() => {
    if (getPrimaryOfficerData && isFetchPrimaryOfficers) {
      const data = JSON.parse(getPrimaryOfficerData?.data?.data);
      setPrimaryOfficerDropDown(data?.Table || [])
    }
  }, [getPrimaryOfficerData, isFetchPrimaryOfficers])

  useEffect(() => {
    if (shiftData && isFetchShiftData) {
      const data = JSON.parse(shiftData?.data?.data);
      setShiftDropDown(data?.Table || [])
    }
  }, [shiftData, isFetchShiftData])

  const updateRowState = (rowIndex, key, value) => {
    setRowsState((prevState) => {
      const updatedRows = prevState.map((row, index) => {
        if (index === rowIndex) {
          const updatedRow = { ...row, [key]: value };
          if (key === 'resource') {
            const selectedResource = resourceData.find(res => res.ResourceID === value?.ResourceID);
            if (selectedResource) {
              updatedRow.shift = shiftDropDown.find(shift => shift.ShiftId === selectedResource.ShiftID) || null;
              updatedRow.zone = zoneDropDown.find(zone => zone.value === selectedResource.ZoneID) || null;
              // updatedRow.resourceType = resourceTypeData?.data?.data?.Table?.find(type => type?.ResourceTypeID === selectedResource.ResourceTypeID) || null;
              if (selectedResource?.OfficerIDs) {
                const [primaryOfficerID, primaryOfficerToID] = selectedResource?.OfficerIDs?.split(',').map(id => parseInt(id.trim()));
                updatedRow.primaryOfficer = primaryOfficerDropDown?.find(officer => officer?.PINID === primaryOfficerID) || null;
                updatedRow.primaryOfficerTo = primaryOfficerDropDown?.find(officer => officer?.PINID === primaryOfficerToID) || null;
              }
            } else {
              updatedRow.shift = null;
              updatedRow.zone = null;
              // updatedRow.resourceType = null;
              updatedRow.primaryOfficer = null;
              updatedRow.primaryOfficerTo = null;
            }
          }
          return updatedRow;
        }
        return row;
      });
      return updatedRows;
    });
  };

  const filterOptions = (options, selectedValues) => {
    return options.filter((option) => !selectedValues.includes(option));
  };
  const getSelectedValues = (key) => {
    return rowsState.map((row) => row[key]);
  };

  // const resetRowsState = () => {
  //   setRowsState(
  //     Array(8).fill({
  //       resource: null,
  //       shift: null,
  //       zone: null,
  //       resourceType: null,
  //       primaryOfficer: null,
  //       primaryOfficerTo: null,
  //     })
  //   );
  // };

  const customStylesWithOutColor = {
    control: (base) => ({
      ...base,
      height: 20,
      minHeight: 37,
      fontSize: 14,
      marginTop: 2,
      boxShadow: 0,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
    }),
  };

  const filterOptionsPersonnel = (options, selectedValues, rowKey) => {
    return options.filter((option) => !selectedValues.includes(option[rowKey]));
  };

  const getSelectedValuesPersonnel = (key) => {
    return rowsState.reduce((acc, row) => {
      if (row[key]) {
        acc.push(row[key]?.PINID);
      }
      return acc;
    }, []);
  };

  const getSelectedResources = () => {
    return rowsState.reduce((acc, row) => {
      if (row.resource) {
        acc.push(row.resource.ResourceID); // Store the ResourceID of each selected resource
      }
      return acc;
    }, []);
  };

  const validateRowFields = (values, rowIndex) => {
    const row = values[rowIndex];
    const rowErrors = {};

    // Only make fields mandatory if 'Resource Type' is selected
    if (row.resourceType) {
      rowErrors.resource = row.resource ? '' : 'required';
      rowErrors.shift = !isEmptyObject(row.shift) ? '' : 'required';
      rowErrors.zone = !isEmptyObject(row.zone) ? '' : 'required';
      rowErrors.personnel = !isEmptyObject(row.primaryOfficer) ? '' : 'required';
    }

    return rowErrors;
  };

  async function handelSave() {
    const newErrors = {};

    let isValid = true;
    rowsState.forEach((row, rowIndex) => {
      const rowErrors = validateRowFields(rowsState, rowIndex);
      if (Object.values(rowErrors).some((error) => error)) {
        isValid = false;
      }
      newErrors[rowIndex] = rowErrors; // Store errors for this row
    });

    setErrors(newErrors); // Update errors state in one go
    console.log("Validation Errors", newErrors, isValid);

    if (isValid) {
      const payload = rowsState
        .filter(row => row.resource && row.resource.ResourceID)
        .map(row => ({
          ResourceID: row.resource.ResourceID,
          ResourceTypeID: row.resource.ResourceTypeID1,
          OfficerIDs: [row.resource.OfficerIDs] || '',
          OnOffDuty: onOffStatus === "OnDuty" ? '1' : '0',
          ShiftID: row.shift ? row.shift.ShiftId : '',
          ZoneID: row.zone ? row.zone.value : ''
        }));
      const jsonPayload = JSON.stringify({ data: payload });
      const finalPayload = {
        jsonPayload: jsonPayload
      };
      const response = await MonitorServices.changeOnOffDuty(finalPayload);
      if (response?.status === 200) {
        toastifySuccess("Data Saved Successfully");
        setOnOffDutyModal(false);
        refetchOnOffDuty();
        incidentRefetch();
        setErrors([]); // Clear errors after successful save
        resourceRefetch();
      }
    }
  }


  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 37,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
    }),
  };


  return (
    <>
      {openOnOffDutyModal ? (
        <>
          <dialog
            className="modal fade"
            style={{ background: "rgba(0,0,0, 0.5)", zIndex: "200" }}
            id="OnOffDutyModal"
            tabIndex="-1"
            aria-hidden="true"
            data-backdrop="false"
          >
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content modal-content-cad">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 p-0 pb-2">
                      <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                        <p
                          className="p-0 m-0 font-weight-medium"
                          style={{
                            fontSize: 18,
                            fontWeight: 500,
                            letterSpacing: 0.5,
                          }}
                        >
                          {'On/Off Duty'}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Form Section */}
                  <div className="m-1">
                    <fieldset style={{ border: "1px solid gray" }}>
                      {/* Line 1 */}
                      <div className="col-12 d-flex justify-content-start align-items-center mt-4 mb-1" style={{ gap: "50px" }}>
                        <div className="form-check ">
                          <input className="form-check-input" style={{ marginTop: "6px" }} type="radio" value="Attempted" name="AttemptComplete" id="flexRadioDefault1" checked={onOffStatus === 'OnDuty'} onChange={(e) => {
                            setOnOffStatus("OnDuty"); refetchOnOffDuty()
                          }} />
                          <label className="form-check-label " htmlFor="flexRadioDefault1" >
                            On Duty
                          </label>
                        </div>
                        <div className="form-check ">
                          <input className="form-check-input" style={{ marginTop: "6px" }} type="radio" value="Attempted" name="AttemptComplete" id="flexRadioDefault12" checked={onOffStatus === 'OffDuty'} onChange={(e) => {
                            setOnOffStatus("OffDuty");
                          }} />
                          <label className="form-check-label " htmlFor="flexRadioDefault12">
                            Off Duty
                          </label>
                        </div>
                      </div>
                      {rowsState.map((row, index) => (
                        <div key={index} className="row">
                          <div className="tab-form-container">
                            <div className="d-flex align-items-center" style={{ gap: "6px" }}>
                              <label htmlFor="" className="new-label text-nowrap mb-0">
                                Resource Type
                              </label>
                              <Select
                                isClearable
                                placeholder="Select..."
                                options={resourceTypeData?.data?.data?.Table}
                                getOptionLabel={(v) => v?.ResourceTypeCode + " | " + v?.description}
                                getOptionValue={(v) => v?.ResourceTypeID}
                                formatOptionLabel={(option, { context }) => {
                                  return context === 'menu'
                                    ? `${option?.ResourceTypeCode} | ${option?.description}`
                                    : option?.ResourceTypeCode;
                                }}
                                value={row?.resourceType}
                                onChange={(selectedOption) => updateRowState(index, 'resourceType', selectedOption)}
                                styles={customStylesWithOutColor}
                                isDisabled={row?.isDisabledType}
                                className="w-100"
                                name="CFSLDetails" />
                              <label htmlFor="" className="new-label text-nowrap mb-0">
                                Resource # {errors[index]?.resource && <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors[index].resource}</p>}
                              </label>
                              <Select
                                isClearable
                                placeholder="Select..."
                                // options={filterOptions(resourceData?.filter((i) => i?.ResourceTypeID === row?.resourceType?.ResourceTypeID), getSelectedValues("resource"))}
                                options={resourceData
                                  .filter((i) => i?.ResourceTypeID === row?.resourceType?.ResourceTypeID) // Match resource type
                                  .filter((i) => !getSelectedResources().includes(i?.ResourceID)) // Exclude already selected resources
                                }
                                // options={resourceData?.filter((i) => i?.ResourceTypeID === row?.resourceType?.ResourceTypeID)}
                                styles={row?.resourceType ? colourStyles : customStylesWithOutColor}
                                className="w-100"
                                value={row.resource}
                                onChange={(selectedOption) => updateRowState(index, 'resource', selectedOption)}
                                getOptionLabel={(v) => v?.ResourceNumber}
                                getOptionValue={(v) => v?.ResourceID}
                                isDisabled={row?.isDisabledResource}
                                onInputChange={(inputValue, actionMeta) => {
                                  if (inputValue.length > 12) {
                                    return inputValue.slice(0, 12);
                                  }
                                  return inputValue;
                                }}
                                isSearchable={true}
                              />
                              <label htmlFor="" className="new-label text-nowrap mb-0">
                                Shift  {errors[index]?.shift && <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors[index].shift}</p>}
                              </label>
                              <Select
                                isClearable
                                options={shiftDropDown}
                                getOptionLabel={(v) => v?.ShiftCode + " | " + v?.ShiftDescription}
                                getOptionValue={(v) => v?.ShiftId}
                                value={row.shift}
                                onChange={(selectedOption) => updateRowState(index, 'shift', selectedOption)}
                                formatOptionLabel={(option, { context }) => {
                                  return context === 'menu'
                                    ? `${option?.ShiftCode} | ${option?.ShiftDescription}`
                                    : option?.ShiftCode;
                                }}
                                placeholder="Select..."
                                isDisabled={onOffStatus === 'OffDuty'}
                                styles={row?.resourceType && onOffStatus === 'OnDuty' ? colourStyles : customStylesWithOutColor}
                                className="w-75"
                                name="CFSLDetails" />
                              <label htmlFor="" className="new-label text-nowrap mb-0">
                                Zone {errors[index]?.zone && <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors[index].zone}</p>}
                              </label>
                              <Select
                                isClearable
                                placeholder="Select..."
                                options={zoneDropDown}
                                styles={row?.resourceType && onOffStatus === 'OnDuty' ? colourStyles : customStylesWithOutColor}
                                isDisabled={onOffStatus === 'OffDuty'}
                                value={row?.zone}
                                onChange={(selectedOption) => updateRowState(index, 'zone', selectedOption)}
                                className="w-100"
                                name="CFSLDetails" />
                              <label htmlFor="" className="new-label text-nowrap mb-0">
                                Personnel ID 1 {errors[index]?.personnel && <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors[index].personnel}</p>}
                              </label>
                              <Select
                                isClearable
                                placeholder="Select..."
                                styles={row?.resourceType && onOffStatus === 'OnDuty' ? colourStyles : customStylesWithOutColor}
                                options={filterOptionsPersonnel(primaryOfficerDropDown, [...getSelectedValuesPersonnel('primaryOfficer'), ...getSelectedValuesPersonnel('primaryOfficerTo')], "PINID")}
                                getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                                value={row?.primaryOfficer}
                                getOptionValue={(v) => v?.PINID}
                                onChange={(selectedOption) => updateRowState(index, 'primaryOfficer', selectedOption)}
                                isDisabled={onOffStatus === 'OffDuty'}
                                className="w-100"
                                name="CFSLDetails" />
                              <label htmlFor="" className="new-label text-nowrap mb-0">
                                Personnel ID 2
                              </label>
                              <Select
                                isClearable
                                placeholder="Select..."
                                styles={customStylesWithOutColor}
                                options={filterOptionsPersonnel(primaryOfficerDropDown, [...getSelectedValuesPersonnel('primaryOfficer'), ...getSelectedValuesPersonnel('primaryOfficerTo')], "PINID")}
                                value={row?.primaryOfficerTo}
                                getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                                onChange={(selectedOption) => updateRowState(index, 'primaryOfficerTo', selectedOption)}
                                isDisabled={onOffStatus === 'OffDuty'}
                                getOptionValue={(v) => v?.PINID}
                                className="w-100"
                                name="CFSLDetails" />
                              <button onClick={() => removeRow(index)} className="btn btn-sm text-white" style={{ backgroundColor: "red" }}> <i className="fa fa-trash"></i></button>
                            </div>
                          </div>
                          {/* Render other dropdowns for Zone, ResourceType, PrimaryOfficer, etc. */}
                        </div>
                      ))}

                      <div className="float-right mt-2">
                        <button onClick={() => handelAdd()} className="btn btn-primary">
                          Add Row
                        </button>
                      </div>
                    </fieldset>
                  </div>
                  {/* Buttons Section */}
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                        <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                          <button
                            type="button"
                            className="save-button ml-2"
                            onClick={() => handelSave()}
                          >
                            {'Save'}
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="cancel-button"
                            onClick={() => { setOnOffDutyModal(false); refetchOnOffDuty(); setErrors([]) }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </dialog >
        </>
      ) : (
        <> </>
      )
      }
    </>
  );
};

export default memo(OnOffDutyModal);
