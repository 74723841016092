import React, { useContext, useEffect, useState } from "react";
import { base64ToString } from "../../../../Components/Common/Utility";
import ResourcesTableSection from "../../ResourcesTableSection";
import { IncidentContext } from "../../../../CADContext/Incident";
import { useLocation } from "react-router-dom";

const ResourceStatusTabSection = () => {
  const { resourceData } = useContext(IncidentContext);
  const [resources, setResources] = useState([])

  const useRouteQuery = () => {
    const params = new URLSearchParams(useLocation().search);
    return {
      get: (param) => params.get(param)
    };
  };

  const query = useRouteQuery();
  let IncID = query?.get("IncId");
  if (!IncID) IncID = 0;
  else IncID = parseInt(base64ToString(IncID));

  useEffect(() => {
    if (resourceData?.length > 0) {
      const filteredData = resourceData.filter((item) => item.IncidentID === IncID);
      setResources(filteredData);
    }
  }, [resourceData, IncID]);


  return (
    <>
      <ResourcesTableSection resources={resources} isResourceStatusTab />
    </>
  );
};

export default ResourceStatusTabSection;
