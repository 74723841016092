import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown';
import { Filter, SendIcon } from '../../../Components/Filter/Filter';
import { tableCustomStyles } from '../../../Components/Common/Utility';
import CADConfirmModal from '../../Common/CADConfirmModal';
import { compareStrings, isEmpty } from '../../../CADUtils/functions/common';
import Select from "react-select";
import { coloredStyle_Select, colorLessStyle_Select } from '../../Utility/CustomStylesForReact';
import useObjState from '../../../CADHook/useObjState';
import MasterTableListServices from "../../../CADServices/APIs/masterTableList";
import { toastifySuccess } from '../../../Components/Common/AlertMsg';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';



function DispositionSection() {
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");
  const [filterTypeDescOption, setFilterTypeDescOption] = useState('Contains');
  const [searchValue1, setSearchValue1] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [filterListData, setFilterListData] = useState([]);
  const [listData, setListData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isSuperadmin, setIsSuperadmin] = useState(1);
  const [agencyID, setAgencyID] = useState(0);
  const [PINID, setPINID] = useState(0);
  const [isActive, setIsActive] = useState('');
  const [activeInactiveData, setActiveInactiveData] = useState({})
  const [agencyCodeDropDown, setAgencyCodeDropDown] = useState([])
  const [confirmType, setConfirmType] = useState('');
  const [loginPinID, setLoginPinID] = useState(1);

  const [
    dispositionState,
    setDispositionState,
    handleDispositionState,
    clearDispositionState,
  ] = useObjState({
    code: "",
    agencyCode: "",
    description: "",
    Agency: "",
    RMSIncident: false,
    IsEditable: false,
    IsCADDisposition: false,
    IsActive: 1
  })
  const [
    errorDispositionState,
    _setErrorDispositionState,
    handleErrorDispositionState,
    clearErrorDispositionState, ,
  ] = useObjState({
    code: false,
    description: false,
  });

  const columns = [
    {
      name: 'Code',
      selector: row => row.DispositionCode,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.DispositionCode, rowB.DispositionCode),
      style: {
        position: "static",
      },
    },
    {
      name: 'Agency Code',
      selector: row => row.AgencyCode,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.AgencyCode, rowB.AgencyCode),
      style: {
        position: "static",
      },
    },
    {
      name: 'Description',
      selector: row => row.Description,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Description, rowB.Description),
      width: "20%",
      style: {
        position: "static",
      },
    },
    {
      name: 'RMS Incident#',
      selector: row => row.CaseRequired ? "Yes" : "No",
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CaseRequired, rowB.CaseRequired),
      style: {
        position: "static",
      },
    },
    // {
    //   name: 'Is Editable',
    //   selector: row => row.codedescription,
    //   sortable: true,
    //   sortFunction: (rowA, rowB) => compareStrings(rowA.codedescription, rowB.codedescription),
    //   style: {
    //     position: "static",
    //   },
    // },
    // {
    //   name: 'Is CAD Disposition',
    //   selector: row => row.codedescription,
    //   sortable: true,
    //   sortFunction: (rowA, rowB) => compareStrings(rowA.codedescription, rowB.codedescription),
    //   style: {
    //     position: "static",
    //   },
    // },
    {
      name:
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {'Status'}
        </div>,
      cell: (row, index) =>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <span
            className="btn btn-sm text-white px-1 py-0 mr-1"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            {row?.IsActive ? (
              <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(0); setConfirmType("InActive"); onCancel() }}></i>
            ) : (
              <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(1); setConfirmType("Active"); onCancel() }}></i>
            )}
          </span>
        </div>,
      width: "70px",
      style: {
        position: "static",
      },
    },
  ];

  const getAgencyCodeKey = `/CAD/MasterAgency/MasterAgencyCode`;
  const { data: getAgencyCodeData, isSuccess: isFetchAgencyCode } = useQuery(
    [getAgencyCodeKey, {},],
    MasterTableListServices.getAgencyCode,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getDispositionskey = `/CAD/MasterIncidentDispositions/GetData_IncidentDispositions`;
  const { data: getDispositionsData, isSuccess: isFetchDispositions, refetch } = useQuery(
    [getDispositionskey, {
      "IsActive": parseInt(pageStatus),
      "IsSuperAdmin": isSuperadmin,
      "AgencyID": agencyID,
      "PINID": PINID
    },],
    MasterTableListServices.getIncidentDispositions,
    {
      refetchOnWindowFocus: false,
      enabled: !!isSuperadmin
    }
  );
  useEffect(() => {
    if (getDispositionsData && isFetchDispositions) {
      const data = JSON.parse(getDispositionsData?.data?.data);
      setFilterListData(data?.Table)
      setListData(data?.Table)
    } else {
      setFilterListData([])
      setListData([])
    }
  }, [getDispositionsData, isFetchDispositions])

  useEffect(() => {
    if (isFetchAgencyCode && getAgencyCodeData) {
      const data = JSON.parse(getAgencyCodeData?.data?.data);
      setAgencyCodeDropDown(data?.Table || [])
    }
  }, [isFetchAgencyCode, getAgencyCodeData]);

  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(localStoreData?.PINID);
      setAgencyID(localStoreData?.AgencyID)
      setPINID(localStoreData?.PINID)
    }
  }, [localStoreData]);

  function onCancel() {
    clearDispositionState();
  }

  async function handelActiveInactive() {
    const data = {
      "Action": "DeleteIncidentDispositions",
      "IncidentDispositionsID": activeInactiveData?.IncidentDispositionsID,
      "IsActive": isActive,
      "DeletedByUserFK": loginPinID,
    }
    const response = await MasterTableListServices.deleteIncidentDispositions(data);
    if (response?.status === 200) {
      const data = JSON.parse(response?.data?.data)?.Table?.[0];
      toastifySuccess(data?.Message);
      refetch();
    }
    setShowModal(false);
    onCancel();
  }

  const validation = () => {
    let isError = false;
    const keys = Object.keys(errorDispositionState);
    keys.map((field) => {
      if (
        field === "code" &&
        isEmpty(dispositionState[field])) {
        handleErrorDispositionState(field, true);
        isError = true;
      } else if (field === "description" && isEmpty(dispositionState[field])) {
        handleErrorDispositionState(field, true);
        isError = true;
      } else {
        handleErrorDispositionState(field, false);
      }
    });
    return !isError;
  };

  async function handleSave() {
    if (!validation()) return
    const isUpdate = !!dispositionState?.IncidentDispositionsID;
    const data = {
      "Action": isUpdate ? "UpdateIncidentDispositions" : "InsertIncidentDispositions",
      "IncidentDispositionsID": isUpdate ? dispositionState?.IncidentDispositionsID : undefined,
      "DispositionCode": dispositionState?.code,
      "Description": dispositionState?.description,
      "CreatedByUserFK": loginPinID,
      "AgencyID": dispositionState?.agencyCode,
      "CaseRequired": dispositionState?.RMSIncident ? 1 : 0,
      "IsActive": dispositionState?.IsActive
    };

    let response;
    if (isUpdate) {
      response = await MasterTableListServices.updateMasterDisposition(data);
    } else {
      response = await MasterTableListServices.insertMasterDisposition(data);
    }

    if (response?.status === 200) {
      toastifySuccess(isUpdate ? "Data Updated Successfully" : "Data Saved Successfully");
      onCancel();
      refetch();
    }
  }


  function handelSetEditData(data) {
    console.log("data", data)
    setDispositionState({
      IncidentDispositionsID: data?.IncidentDispositionsID,
      code: data?.DispositionCode,
      agencyCode: data?.AgencyID,
      description: data?.Description,
      RMSIncident: data?.CaseRequired,
      IsActive: data?.IsActive
    })
  }

  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 incident-tab ">
              <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); setSearchValue1(""); setSearchValue2(""); onCancel() }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); setSearchValue1(""); setSearchValue2(""); onCancel() }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
              </ul>
            </div>    {
              pageStatus === '1' ?
                <>
                  <div className='utilities-tab-content-form-main'>
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Code
                          {errorDispositionState.code && isEmpty(dispositionState?.code) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <input
                          name="Code"
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Code'
                          value={dispositionState?.code}
                          onChange={(e) => handleDispositionState("code", e.target.value)}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Agency Code
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          isClearable
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          value={dispositionState?.agencyCode ? agencyCodeDropDown?.find((i) => i?.AgencyID === parseInt(dispositionState?.agencyCode)) : ""}
                          options={agencyCodeDropDown}
                          getOptionLabel={(v) => v?.ShortName}
                          getOptionValue={(v) => v?.AgencyID}
                          onChange={(e) => { handleDispositionState("agencyCode", e?.AgencyID) }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Description
                          {errorDispositionState.description && isEmpty(dispositionState?.description) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Description"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-4 d-flex align-self-center justify-content-end">
                        <input
                          name="description"
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Description'
                          value={dispositionState?.description}
                          onChange={(e) => handleDispositionState("description", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      {/* <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Agency
                        </label>
                      </div>
                      <div className="col-5 d-flex align-self-center justify-content-end">
                        <Select
                          isClearable
                          styles={coloredStyle_Select}
                          placeholder="Select"
                          value={dispositionState?.Agency}
                          options={agencyCodeDropDown}
                          getOptionLabel={(v) => v?.ShortName}
                          getOptionValue={(v) => v?.AgencyID}
                          onChange={(e) => { handleDispositionState("Agency", e) }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div> */}
                      <div className="col-4 agency-checkbox-item offset-2">
                        <input
                          type="checkbox"
                          name="RMSIncident"
                          checked={dispositionState.RMSIncident}
                          onChange={(e) => { handleDispositionState("RMSIncident", e.target.checked) }}
                        />
                        <div className="agency-checkbox-text-container tab-form-label">
                          <span>Generate RMS Incident#</span>
                        </div>
                      </div>
                      {/* <div className="col-2 agency-checkbox-item">
                        <input
                          type="checkbox"
                          name="CaseRequired"
                        // checked={CFSCodeState.CaseRequired}
                        // onChange={(e) => { handleCFSCodeState("CaseRequired", e.target.checked) }}
                        />
                        <div className="agency-checkbox-text-container tab-form-label">
                          <span>Is Editable</span>
                        </div>
                      </div> */}
                    </div>
                    {/* <div className="row">
                      <div className="col-5 offset-2 agency-checkbox-item">
                        <input
                          type="checkbox"
                          name="CaseRequired"
                        // checked={CFSCodeState.CaseRequired}
                        // onChange={(e) => { handleCFSCodeState("CaseRequired", e.target.checked) }}
                        />
                        <div className="agency-checkbox-text-container tab-form-label">
                          <span>Is CAD Disposition</span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </>
                :
                <>
                </>
            } </div>

        </div>

        <div className='utilities-tab-content-table-container'>
          <div className="row mb-2">
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Code...'
                value={searchValue1}
                onChange={(e) => {
                  setSearchValue1(e.target.value);
                  const result = Filter(listData, e.target.value, searchValue2, filterTypeIdOption, 'DispositionCode', 'Description')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" >
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Description...'
                value={searchValue2}
                onChange={(e) => {
                  setSearchValue2(e.target.value);
                  const result = Filter(listData, searchValue1, e.target.value, filterTypeDescOption, 'DispositionCode', 'Description')
                  setFilterListData(result)
                }}
              />
            </div>

            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '1', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeDescOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="table-responsive">
            <DataTable
              dense
              columns={columns}
              data={filterListData}
              customStyles={tableCustomStyles}
              pagination
              responsive
              striped
              persistTableHead={true}
              highlightOnHover
              fixedHeader
              onRowClicked={(row) => {
                handelSetEditData(row);
              }}
            />
          </div>
          {parseInt(pageStatus) === 1 &&
            <div className="utilities-tab-content-button-container" >
              <button type="button" className="btn btn-sm btn-success" onClick={() => onCancel()} >New</button>
              <button type="button" className="btn btn-sm btn-success" onClick={() => handleSave()} >{!!dispositionState?.IncidentDispositionsID ? "Update" : "Save"}</button>
            </div>}
        </div>
      </div>
      <CADConfirmModal showModal={showModal} setShowModal={setShowModal} handleConfirm={handelActiveInactive} confirmType={confirmType} />
    </>
  )
}

export default DispositionSection