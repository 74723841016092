import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { CadDashboardTableCustomStyles, getShowingYearMonthDate, stringToBase64 } from '../../../Components/Common/Utility';
import DateTimeCounter from '../../Common/DateTimeCounter';
import { useNavigate } from 'react-router-dom';
import { IncidentContext } from '../../../CADContext/Incident';
import {
  compareStrings
} from "../../../CADUtils/functions/common";
import ResourcesStatusServices from "../../../CADServices/APIs/resourcesStatus";
import { useSelector } from 'react-redux';
import { toastifySuccess } from '../../../Components/Common/AlertMsg';
import MasterTableListServices from '../../../CADServices/APIs/masterTableList'
import { useQuery } from 'react-query';

const ResourcesTableSection = (props) => {
  const { resources, isResourceStatusTab = false, isCADMap = false } = props
  const { resourceRefetch } = useContext(IncidentContext);
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [editValue, setEditValue] = useState("");
  const [incidentID, setIncidentID] = useState("");
  const [loginAgencyID, setLoginAgencyID] = useState();
  const [incidentNumber, setIncidentNumber] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 670);
  const [statusData, setStatusData] = useState({}); // Holds the status for each row
  const [resourceStatusColorData, setResourceStatusColorData] = useState([]);

  const getFilteredOptions = (status) => {
    let validStatusCodes = [];
    if (status === 'DP') {
      validStatusCodes = ['AV', 'ER', 'EH', 'AR', 'AH', 'DP'];
    } else if (status === 'ER') {
      validStatusCodes = ['AV', 'EH', 'AR', 'AH', 'ER'];
    } else if (status === 'EH') {
      validStatusCodes = ['AV', 'ER', 'AR', 'AH', 'EH'];
    } else if (status === 'AR') {
      validStatusCodes = ['AV', 'AH', 'AR'];
    } else {
      validStatusCodes = resourceStatusColorData.map(option => option.ResourceStatusCode);
    }
    return resourceStatusColorData.filter(option => validStatusCodes.includes(option.ResourceStatusCode));
  };

  // const getFilteredOptions = (status) => {
  //   switch (status) {
  //     case 'DP': return stateDropDown.filter(option => ['ER', 'EH', 'AR', 'AH', 'AV'].includes(option.label));
  //     case 'ER': return stateDropDown.filter(option => ['EH', 'AR', 'AH', 'AV'].includes(option.label));
  //     case 'EH': return stateDropDown.filter(option => ['ER', 'AR', 'AH', 'AV'].includes(option.label));
  //     case 'AR': return stateDropDown.filter(option => ['AH', 'AV'].includes(option.label));
  //     default: return stateDropDown;
  //   }
  // };

  const getResourceStatusColorKey = `/CAD/ResourceStatusColor/GetResourceStatusColor`;
  const { data: resourceStatusColorList, isSuccess: isFetchResourceStatusColorList } = useQuery(
    [getResourceStatusColorKey, {
      IsActive: 1
    }],
    MasterTableListServices.getResourceStatusColor,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
  useEffect(() => {
    if (isFetchResourceStatusColorList && resourceStatusColorList) {
      const res = JSON.parse(resourceStatusColorList?.data?.data);
      const data = res?.Table
      setResourceStatusColorData(data || [])
    } else {
      setResourceStatusColorData([])
    }
  }, [isFetchResourceStatusColorList, resourceStatusColorList])

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID)
    }
  }, [localStoreData]);

  const handleStatusChange = async (row, newValue) => {
    const data = {
      Status: newValue,
      IncidentID: row?.IncidentID,
      Resources: row?.ResourceID,
      CreatedByUserFK: loginAgencyID,
    };

    try {
      const response = await ResourcesStatusServices.incidentRecourseStatus(data);
      if (response?.status === 200) {
        resourceRefetch();
      } else {
        console.error("Failed to update status:", response);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  useEffect(() => {
    const initialStatusData = {};
    resources.forEach(row => {
      initialStatusData[row.IncidentID] = row.Status;
    });
    setStatusData(initialStatusData);
  }, [resources]);

  const getStatusColors = (statusCode) => {
    const statusItem = resourceStatusColorData.find(item => item.ResourceStatusCode === statusCode);
    return statusItem
      ? { backgroundColor: statusItem.BackColor, color: statusItem.ForeColor }
      : {}; // Default to empty if no match found
  };

  const columns = [
    {
      name: 'Resource Type',
      selector: (row) => row.ResourceTypeCode,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ResourceTypeCode, rowB.ResourceTypeCode),
      width: isSmallScreen ? "130px" : "130px",
    },
    {
      name: 'Resource #',
      selector: (row) => {
        return (<>{row.ResourceNumber} {row.IsPrimaryResource ? <span
          style={{
            backgroundColor: "#3358ff", color: "#ffffff",
            padding: '4px 4px',
            borderRadius: '4px',
            display: 'inline-block',
            minWidth: '22px',
            textAlign: 'center'
          }}
        >
          {"P"}
        </span> : ""} </>)
      },
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ResourceNumber, rowB.ResourceNumber),
      width: isSmallScreen ? "160px" : "160px",
    },
    {
      name: 'Status',
      selector: (row) => {
        const colors = getStatusColors(row.Status); // Get colors based on Status
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                ...colors,
                padding: '4px 8px',
                borderRadius: '4px',
                display: 'inline-block',
                minWidth: '50px',
                textAlign: 'center'
              }}
            >
              {row.Status}
            </span>
            {(row.Status !== "AV") && (
              <select
                onChange={(e) => handleStatusChange(row, e.target.value)}
                value={
                  getFilteredOptions(row.Status)?.find((i) => i?.ResourceStatusCode === row.Status)?.ResourceStatusCode ||
                  getFilteredOptions(row.Status)?.[0]?.ResourceStatusCode
                }
                className="form-select status-dropdown"
                style={{
                  width: '20px',
                  padding: '2px',
                  fontSize: '16px',
                  marginLeft: '5px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                  appearance: 'auto',
                }}
              >
                {getFilteredOptions(row.Status)?.map((option, index) => (
                  <option key={index} value={option.ResourceStatusCode}>
                    {`${option.ResourceStatusCode} | ${option.Description}`}&nbsp;
                  </option>
                ))}
              </select>
            )
            }
          </div>
        );
      },
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Status, rowB.Status),
      width: isSmallScreen ? "120px" : "120px",
    },
    {
      name: 'Status Date & Time',
      selector: (row) => (row.StatusDT ? getShowingYearMonthDate(row.StatusDT) : ""),
      sortable: true,
      width: isSmallScreen ? "170px" : "170px",

    },
    {
      name: 'E Timer',
      selector: (row) => (row.StatusDT && row.Status !== "AV" ? <DateTimeCounter data={row.StatusDT} /> : ""),
      sortable: true,
      width: isSmallScreen ? "100px" : "100px",

    },
    {
      name: 'CAD Event #',
      selector: (row) => row.CADIncidentNumber,
      sortable: true,
      width: isSmallScreen ? "100px" : "120px",

    },
    {
      name: 'Incident Recvd DT&TM',
      selector: (row) => (row.IncidentDtTm ? getShowingYearMonthDate(row.IncidentDtTm) : ""),
      sortable: true,
      width: isSmallScreen ? "180px" : "180px",

    },
    {
      name: 'RMS Incident#',
      selector: (row) => row.IncidentNumber,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.IncidentNumber, rowB.IncidentNumber),
      width: isSmallScreen ? "170px" : "170px",
    },
    {
      name: 'CFS Code',
      selector: (row) => row.CFSCODE,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CFSCODE, rowB.CFSCODE),
      width: isSmallScreen ? "170px" : "170px",
    },
    {
      name: 'CFS Description',
      selector: (row) => row.CFSCodeDescription,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CFSCodeDescription, rowB.CFSCodeDescription),
      width: isSmallScreen ? "190px" : "190px",
    },
    {
      name: 'Zone',
      selector: (row) => row.ZoneCode,
      sortable: true,
      width: isSmallScreen ? "100px" : "100px",
    },
    {
      name: 'Officer 1',
      selector: (row) => row.Officer1,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Officer1, rowB.Officer1),
      width: isSmallScreen ? "110px" : "110px",
    },
    {
      name: 'Officer 2',
      selector: (row) => row.Officer2,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Officer2, rowB.Officer2),
      width: isSmallScreen ? "110px" : "110px",

    },
  ];

  const ResourceStatusColumns = [
    {
      name: 'Resource Type',
      selector: (row) => row.ResourceTypeCode,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ResourceTypeCode, rowB.ResourceTypeCode),
      width: "20%",
    },
    {
      name: 'Resource #',
      selector: (row) => row.ResourceNumber,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ResourceNumber, rowB.ResourceNumber),
      width: "20%",
    },
    {
      name: 'Incident #',
      selector: (row) => row.CADIncidentNumber,
      sortable: true,
      width: "20%",
    },
    {
      name: 'Status',
      selector: (row) => {
        const colors = getStatusColors(row.Status); // Get colors based on Status
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                ...colors,
                padding: '4px 8px',
                borderRadius: '4px',
                display: 'inline-block',
                minWidth: '50px',
                textAlign: 'center'
              }}
            >
              {row.Status}
            </span>
            {row.Status !== "AV" && (
              <select
                onChange={(e) => handleStatusChange(row, e.target.value)}
                className="form-select status-dropdown"
                style={{
                  width: '20px',
                  padding: '2px',
                  fontSize: '16px',
                  marginLeft: '5px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                  appearance: 'auto'
                }}
              >
                {resourceStatusColorData.map((option, index) => (
                  <option key={index} value={option.ResourceStatusCode}>
                    {`${option.ResourceStatusCode} | ${option.Description}`}&nbsp;
                  </option>
                ))}
              </select>
            )}
          </div>
        );
      },
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Status, rowB.Status),
      width: "20%",
    },
    {
      name: 'Status Date & Time',
      selector: (row) => (row.StatusDT ? getShowingYearMonthDate(row.StatusDT) : ""),
      sortable: true,
      width: "20%",
    },
  ];

  const MapResourceStatusColumns = [
    // {
    //   name: 'Resource Type',
    //   selector: (row) => row.ResourceTypeCode,
    //   sortable: true,
    //   sortFunction: (rowA, rowB) => compareStrings(rowA.ResourceTypeCode, rowB.ResourceTypeCode),
    //   width: "50%",
    // },
    {
      name: 'Resource #',
      selector: (row) => {
        const colors = getStatusColors(row.Status); // Get colors based on Status

        return (<>{row.ResourceNumber} {row.PrimaryResourceName ? <span
          style={{
            backgroundColor: "#3358ff", color: "#ffffff",
            padding: '4px 4px',
            borderRadius: '4px',
            display: 'inline-block',
            minWidth: '22px',
            textAlign: 'center'
          }}
        >
          P
        </span> : ""} </>)
      },
      width: "50%",
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ResourceNumber, rowB.ResourceNumber),
    },
    {
      name: 'Status',
      selector: (row) => {
        const colors = getStatusColors(row.Status); // Get colors based on Status
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                ...colors,
                padding: '4px 8px',
                borderRadius: '4px',
                display: 'inline-block',
                minWidth: '50px',
                textAlign: 'center'
              }}
            >
              {row.Status}
            </span>
            {row.Status !== "AV" && (
              <select
                onChange={(e) => handleStatusChange(row, e.target.value)}
                className="form-select status-dropdown"
                style={{
                  width: '20px',
                  padding: '2px',
                  fontSize: '16px',
                  marginLeft: '5px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                  appearance: 'auto'
                }}
              >
                {resourceStatusColorData.map((option, index) => (
                  <option key={index} value={option.ResourceStatusCode}>
                    {`${option.ResourceStatusCode} | ${option.Description}`}&nbsp;
                  </option>
                ))}
              </select>
            )}
          </div>
        );
      },
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Status, rowB.Status),
      width: "50%",

    },

  ];

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1400);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!isResourceStatusTab) {navigate(`/cad/dashboard-page?IncId=${stringToBase64(incidentID)}&IncNo=${incidentNumber}&IncSta=true`); }
  // }, [incidentID, incidentNumber]);

  const conditionalRowStyles = [
    {
      when: row => row === editValue,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#001f3fbd',
          color: 'white',
        },
      },
    }
  ];
  const onIncidentRowClick = row => {
    setIncidentID(row?.IncidentID)
    setIncidentNumber(row?.CADIncidentNumber)
    setEditValue(row);
    if (!isResourceStatusTab) {
      navigate(`/cad/dashboard-page?IncId=${stringToBase64(row?.IncidentID)}&IncNo=${row?.CADIncidentNumber}&resourceID=${row?.ResourceID}&isResourceView=true&IncSta=true`);
    }
  }

  return (
    <>
      <div className='table-container'>
        <DataTable
          dense
          columns={isResourceStatusTab ? ResourceStatusColumns : isCADMap ? MapResourceStatusColumns : columns}
          data={resources}
          onRowClicked={onIncidentRowClick}
          customStyles={CadDashboardTableCustomStyles}
          conditionalRowStyles={conditionalRowStyles}
          pagination
          paginationPerPage={'50'}
          paginationRowsPerPageOptions={[50, 100, 150, 200, 500]}
          showPaginationBottom={50}
          responsive
          striped
          highlightOnHover
          fixedHeader
          persistTableHead={true}
          fixedHeaderScrollHeight="300px"
        />

      </div>
    </>
  );
};

export default ResourcesTableSection;
