import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AgencyContext } from "../../Context/Agency/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import defualtImage from "../../img/uploadImage.png";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { get_LocalStoreData } from "../../redux/actions/Agency";
import { toastifySuccess } from "../../Components/Common/AlertMsg";
import ThemeSetting from "../../Components/Inc/ThemeSetting";
import { Decrypt_Id_Name } from "../../Components/Common/Utility";
import TreeComponent from "../../Components/Pages/PropertyRoom/PropertyRoomTab/TreeComponent/TreeComponent";
import GEOModal from "../GEOModal";
import CallTakerModal from "../CallTakerModal";
import RIVSModal from "../RIVSModal";
import FreeModal from "../FreeModal";
import DispatcherModal from "../DispatcherModal";
import ClearModal from "../ClearModal";
import ArriveModal from "../ArriveModal";
import EnrouteModal from "../EnrouteModal";
import OnOffDutyModal from "../OnOffDutyModal";
import QueryIncidentModal from "../QueryIncidentModal";
import MiscellaneousModal from "../MiscellaneousModal";
import BoloModal from "../BoloModal";
import { IncidentContext } from "../../CADContext/Incident";



const Header = (props) => {
  const dispatch = useDispatch();
  const [openCallTakerModal, setCallTakerModal] = useState(false);
  const [openEnrouteModal, setEnrouteModal] = useState(false);
  const [openArriveModal, setArriveModal] = useState(false);
  const [openGEOModal, setOpenGEOModal] = useState(false);
  const [openBoloModal, setOpenBoloModal] = useState(false);
  const [openDispatcherModal, setOpenDispatcherModal] = useState(false);
  const [openRIVSModal, setOpenRIVSModal] = useState(false);
  const [openFreeModal, setOpenFreeModal] = useState(false);
  const [openClearModal, setOpenClearModal] = useState(false);
  const [openOnOffDutyModal, setOnOffDutyModal] = useState(false);
  const [openMiscModal, setOpenMiscModal] = useState(false);
  const [openQueryIncidentModal, setQueryIncidentModal] = useState(false);
  const { setIncID } = useContext(IncidentContext);

  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const uniqueId = sessionStorage.getItem("UniqueUserID")
    ? Decrypt_Id_Name(
      sessionStorage.getItem("UniqueUserID"),
      "UForUniqueUserID"
    )
    : "";

  const useQuery = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    return {
      get: (param) => params.get(param),
      pathname: location.pathname,
    };
  };

  const query = useQuery();
  var id = query?.get("id");
  var call = query?.get("call");
  let pathname = query?.pathname

  const { listManagementSideBar, agencySideBar, personnelSideBar, isIncidentDispatch, setIsIncidentDispatch } = props;
  const { setAgencyName, agnecyName, changesStatus, setIsLogout } =
    useContext(AgencyContext);

  // const useQuery = () => new URLSearchParams(useLocation().search);
  // let aId = useQuery().get('id');
  // let goToBack = useQuery().get('call');
  const navigate = useNavigate();

  // Logout User
  const signOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    toastifySuccess("Logout Succesfully !!");
    setIsLogout(true);
    navigate("/");
  };

  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Get the mode preference from localStorage (or default to light mode if not available)
    return sessionStorage.getItem("darkMode") === "true";
  });

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      // Store the new mode preference in localStorage
      sessionStorage.setItem("darkMode", newMode);
      return newMode;
    });
    document.body.classList.toggle("dark-mode");
  };

  // Effect to update body class on initial load
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (!localStoreData?.Agency_Name) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);

  useEffect(() => {
    if (localStoreData) {
      setAgencyName(localStoreData?.Agency_Name);
      setUserName(localStoreData?.UserName);
    }
  }, [localStoreData]);

  return (
    <>
      <div
        id="page_top"
        className={`${listManagementSideBar
          ? "section-body"
          : personnelSideBar
            ? "section-body"
            : agencySideBar
              ? "section-body"
              : "section-body top_dark"
          }`}
        style={{
          padding: `${listManagementSideBar
            ? "0"
            : personnelSideBar
              ? "0"
              : agencySideBar
                ? "0"
                : "20px;"
            }`,
        }}
      >
        <div
          className="container-fluid p-0"
          style={{
            backgroundColor: `${listManagementSideBar
              ? "#001f3f"
              : personnelSideBar
                ? "#001f3f"
                : agencySideBar
                  ? "#001f3f"
                  : ""
              }`,
          }}
        >
          <div className="d-flex justify-content-end py-2">
            <div className="col-10 d-flex justify-content-start tab-form-row-gap">
              <div className="div d-flex header-menu">
                {<button
                  type="button"
                  className="cancel-button-header"
                  onClick={() => {
                    navigate('/dashboard-page');
                  }}
                >
                  RMS
                </button>}
                <Link className=" text-white ml-2">
                  <span>
                    Reports
                  </span>
                </Link>
                {/* <div className="text-white ml-4" style={{ cursor: "pointer" }} role="button" data-toggle="modal"
                  data-target="#BoloModal" onClick={() => setOpenBoloModal(true)}>
                  BOLO
                </div> */}
                <div className="dropdown d-flex">
                  <span className="nav-link icon text-white btn-icon ml-1" data-toggle="dropdown">
                    Utilities
                    <FontAwesomeIcon icon={faCaretDown} className='pl-1' />
                  </span>
                  <div className="dropdown-menu dropdown-menu-left dropdown-menu-arrow mt-2 pt-1">
                    <Link
                      to={`/cad/utilities/bolo_disposition`}
                      className="dropdown-item" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      List Manager
                    </Link>
                  </div>
                </div>
                <Link className=" text-white ml-2">
                  <span>
                    Search
                  </span>
                </Link>
              </div>
            </div>
            {/* <div className="col-3 d-flex justify-content-end tab-form-row-gap">
              {(pathname.startsWith("/cad/utilities") || pathname === "/cad/map" || pathname === "/cad/dispatcher") && <button
                type="button"
                className="cancel-button-header"
                onClick={() => {
                  navigate("/cad/dashboard-page")
                }}
              >
                Monitor
              </button>}
              {<button
                type="button"
                className="cancel-button-header"
                onClick={() => {
                  navigate('/dashboard-page');
                }}
              >
                RMS
              </button>}
            </div> */}
            <div className="notification d-flex justify-content-center align-items-center px-3">
              {/* {
                  listManagementSideBar || agencySideBar || personnelSideBar ?
                    <Link to={listManagementSideBar ? goToBack ? goToBack : '/dashboard-page' : agencySideBar ? `/agency` : personnelSideBar ? `/agencyTab?id=U2FsdGVkX1${aId.split(" ", 3)[0].split("/", 1)[0].substring(10,)}/rbn7XDh9W4GiUkZ4MTV1Vx8pMNVkdjyw=` : '/dashboard-page'}>
                      <button style={{ background: 'inherit', border: 'none', outline: 'none', color: '#e63946' }}
                        onClick={() => {
                          agencySideBar ? setShowPage('home') :
                            personnelSideBar ? setShowPagePersonnel('home') : setInActiveStatus(false);
                        }}
                      >
                        <i className='fa fa-close text-white'></i>
                      </button>
                    </Link>
                    :
                    <></>
                } */}
              <div>
                <button onClick={toggleDarkMode} className="dark-toogle">
                  <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />
                </button>
              </div>
              {
                <div className="dropdown d-flex">
                  <span
                    className="nav-link icon  btn  btn-icon ml-2"
                    data-toggle="dropdown"
                  >
                    <span className="text-uppercase text-white">
                      {userName}&nbsp;
                      <FontAwesomeIcon icon={faCaretDown} className="pl-1" />
                    </span>
                  </span>
                  <div className="dropdown-menu  dropdown-menu-right dropdown-menu-arrow">
                    <div className="header-card">
                      <h6
                        className="text-center mt-2"
                        style={{ fontSize: "15px" }}
                      >
                        {agnecyName ? agnecyName : ""}
                      </h6>
                      <fieldset>
                        <legend style={{ margin: "auto" }}>
                          <div className="header-img mt-3 text-center">
                            <img src={defualtImage} alt="" />
                          </div>
                        </legend>
                      </fieldset>
                      <div className="text-start ml-5 mt-2">
                        <h5
                          className="text-bold "
                          style={{ fontSize: "14px", color: "#001f3f" }}
                        >
                          {userName}
                        </h5>
                        {/* <h6 className="" style={{ fontSize: '12px' }}>Sign In : <span className='pl-1' style={{ fontSize: '14px' }}>{getShowingDateText(new Date())}</span></h6> */}
                      </div>
                      {/* <Link className="dropdown-item bb " to={changesStatus ? "/agency" : "/agency"} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                          <span className="float-right">
                          </span>
                          <i className="fa fa-building-o" aria-hidden="true"></i>&nbsp; Agency
                        </Link> */}
                      <Link
                        className="dropdown-item bb "
                        to={changesStatus ? "#" : "/LockedUser"}
                        data-toggle={changesStatus ? "modal" : ""}
                        data-target={changesStatus ? "#SaveModal" : ""}
                      >
                        <i className="fa fa-lock"></i> &nbsp; Locked User
                      </Link>
                      <Link
                        className="dropdown-item bb"
                        to={changesStatus ? "#" : "/Dictionary"}
                        data-toggle={changesStatus ? "modal" : ""}
                        data-target={changesStatus ? "#SaveModal" : ""}
                      >
                        <i className="fa fa-lock"></i> &nbsp; Data Dictionary
                      </Link>
                      <Link
                        className="dropdown-item bb"
                        to={changesStatus ? "#" : "/Eroor-Log"}
                        data-toggle={changesStatus ? "modal" : ""}
                        data-target={changesStatus ? "#SaveModal" : ""}
                      >
                        <i className="fa fa-lock"></i> &nbsp; Error Log
                      </Link>
                      <Link
                        className="dropdown-item"
                        to={"/"}
                        onClick={() => signOut()}
                      >
                        <i className="fa fa-sign-out"></i>&nbsp; Sign out
                      </Link>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center py-2 text-white button-container">
            {(pathname.startsWith("/cad/utilities") || pathname === "/cad/map" || pathname === "/cad/dispatcher") && <button
              type="button"
              className="btn btn-sm btn-light custom-button"
              onClick={() => {
                navigate("/cad/dashboard-page");
                setIncID(null);
              }}
            >
              Monitor
            </button>}
            <button
              className="btn btn-sm btn-light custom-button "
              data-toggle="modal"
              data-target="#CallTakerModal"
              // style={{ width: "8%" }}
              onClick={() => {
                setCallTakerModal(true);
              }}
            >
              Call Taker
            </button>
            <button

              className="btn btn-sm btn-light custom-button position-relative"
              data-toggle="modal"
              data-target="#BoloModal"
              // style={{ width: "8%" }}
              onClick={() => {
                setOpenBoloModal(true);
              }}
            >
              BOLO
              <span class="badge badge-light" style={{ position: "absolute", right: -8, top: -15, backgroundColor: "#B9D9EB" }}>0</span>
            </button>
            <button
              className="btn btn-sm btn-light custom-button"
              data-toggle="modal"
              data-target="#DispatcherModal"
              // style={{ width: "8%" }}
              onClick={() => {
                setOpenDispatcherModal(true);
              }}
            >
              Dispatch
            </button>
            <button
              className="btn btn-sm btn-light custom-button"
              data-toggle="modal"
              data-target="#RIVSModal"
              // style={{ width: "8%" }}
              onClick={() => {
                // setOpenRIVSModal(true);
              }}
            >
              RI/VS
            </button>
            <button
              className="btn btn-sm btn-light custom-button"
              data-toggle="modal"
              data-target="#EnrouteModal"
              // style={{ width: "8%" }}
              onClick={() => {
                setEnrouteModal(true);
              }}
            >
              Enroute
            </button>
            <button
              className="btn btn-sm btn-light custom-button"
              data-toggle="modal"
              data-target="#ArriveModal"
              // style={{ width: "8%" }}
              onClick={() => {
                setArriveModal(true);
              }}
            >
              Arrive
            </button>
            <button
              className="btn btn-sm btn-light custom-button"
              data-toggle="modal"
              data-target="#freeModal"
              // style={{ width: "8%" }}
              onClick={() => {
                setOpenFreeModal(true);
              }}
            >
              Free
            </button>
            <button
              className="btn btn-sm btn-light custom-button"
              data-toggle="modal"
              data-target="#clearModal"
              // style={{ width: "8%" }}
              onClick={() => {
                setOpenClearModal(true);
              }}
            >
              Clear
            </button>
            <button
              className="btn btn-sm btn-light custom-button"
              data-toggle="modal"
              data-target="#miscModal"
              // style={{ width: "8%" }}
              onClick={() => {
                setOpenMiscModal(true);
              }}
            >
              Misc.
            </button>
            <button
              className="btn btn-sm btn-light custom-button"
              data-toggle="modal"
              data-target="#GEOModal"
              // style={{ width: "8%" }}
              onClick={() => {
                setOpenGEOModal(true);
              }}
            >
              GEO
            </button>
            <button
              className="btn btn-sm btn-light custom-button"
              data-toggle="modal"
              data-target="#OnOffDutyModal"
              // style={{ width: "8%" }}
              onClick={() => {
                setOnOffDutyModal(true);
              }}
            >
              On/Off duty
            </button>
            <button
              className="btn btn-sm btn-light custom-button"
              data-toggle="modal"
              data-target="#QueryIncidentModal"
              // style={{ width: "9%" }}
              onClick={() => {
                // setQueryIncidentModal(true);
              }}
            >
              Query Incident
            </button>
            <Link
              className="btn btn-sm btn-light custom-button"
              to={`/cad/map?AgencyID=${localStoreData?.AgencyID}`}
              // style={{ width: "8%" }}
              target="_self"
            >
              Map
            </Link>
          </div>
        </div>
      </div>
      <TreeComponent />
      <ThemeSetting />
      <CallTakerModal
        {...{
          openCallTakerModal,
          setCallTakerModal,
          isIncidentDispatch,
          setIsIncidentDispatch
        }}
      />
      <GEOModal {...{ openGEOModal, setOpenGEOModal }} />
      <BoloModal {...{ openBoloModal, setOpenBoloModal }} />
      <DispatcherModal {...{ openDispatcherModal, setOpenDispatcherModal }} />
      <RIVSModal {...{ openRIVSModal, setOpenRIVSModal }} />
      <FreeModal {...{ openFreeModal, setOpenFreeModal }} />
      <ClearModal {...{ openClearModal, setOpenClearModal }} />
      <ArriveModal {...{ openArriveModal, setArriveModal }} />
      <EnrouteModal {...{ openEnrouteModal, setEnrouteModal }} />
      <OnOffDutyModal {...{ openOnOffDutyModal, setOnOffDutyModal }} />
      <QueryIncidentModal {...{ openQueryIncidentModal, setQueryIncidentModal }} />
      <MiscellaneousModal {...{ openMiscModal, setOpenMiscModal }} />


    </>
  );
};

export default Header;
