import React, { useState, useEffect } from 'react';
import moment from 'moment';

const dateTimer = (date) => {
    const pastDate = moment(date);
    const currentDate = moment();
    const duration = moment.duration(currentDate.diff(pastDate));

    const hours = Math.floor(duration.asHours()).toString().padStart(2, '0');
    const minutes = Math.floor(duration.asMinutes() % 60).toString().padStart(2, '0');
    const seconds = Math.floor(duration.asSeconds() % 60).toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
};


const DateTimeCounter = ({ data }) => {
    const [time, setTime] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            const newTime = data ? dateTimer(data) : "";
            setTime(newTime);
        }, 1000);

        return () => clearInterval(interval);
    }, [data]);

    return (
        <div>
            {time}
        </div>
    );
};

export default DateTimeCounter;
