// Name -----> Victem -----------
export const NameVictimError = '{404} Invalid Victim Type ';
export const NameVictimOffenses = '{401} Atleast one UCR Offense Code must be present - Mandatory field ';


export const VictimGovermentError = 'Type of Victim must be G = Government for offenses: 26H, 521, 522, 526, 58A, 58B, 61A, 61B, 620';
export const policeOfficerError = '(Type of Victim) cannot be L = Police Officer UNLESS Victim Connected to UCR Offense Code is one of the following';
export const SocietyPublicError = 'Age of Victim , Sex of Victim and Race of Victim must be entered. WHEN Type of Victim is Police Officer';
export const StatutoryRapeError = '(Age of Victim) should be under 18 WHEN (Victim Connected to UCR Offense Code) is 36B = Statutory Rape.';
export const ResidentStatusError = 'WHEN (Resident Status of Victim) is entered.THEN should be entered value from (Type of Victim) Person or Police Officer';
export const VectimOffenderSpouceError = '(Age of Offender) cannot be less than 13 years old when (Relationship of Victim to Offender) contains a relationship of SE = Spouse.   ';


export const CrimeAgainstSocietyError = 'Crimes Against Society. When this is so Type of Victim must be Society/Public';
export const CrimeAgainstPersonError = 'Type of Victim Must have a value of Person or Police Officer WHEN Victim Connected to UCR Offense Code contains a Crime Against Person';
export const CrimeAgainstPropertyError = "WHEN Victim Connected to UCR Offense Code contains any offense code related to Crime Against Property THEN Type of Victim Cannot have a value of  Society/Public"


// export const ErrorTooltip = (ErrorStr) => (<span className='hovertext' style={{ marginLeft: '15px' }} data-hover={ErrorStr ? ErrorStr : ''} ><i className='fa fa-exclamation-circle'></i></span>);
export const ErrorTooltip = ({ ErrorStr }) => (<span className='hovertext' style={{ marginLeft: '15px' }} data-hover={ErrorStr} ><i className='fa fa-exclamation-circle'></i></span>);


export const ErrorShow = ({ ErrorStr }) => (<span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{ErrorStr ? ErrorStr : ''}</span>);

export const ErrorStyle = (type) => {
    const colour_Styles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: type ? "rgb(255 202 194)" : "#fce9bf",
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };
    return colour_Styles;
};

export const victimNibrsErrors = (victimCode, offenceCodes, type, isCrimeAgainsPerson, isCrimeAgainstProperty, isCrimeAgainstSociety, nameSingleData) => {
    // export const victimNibrsErrors = (victimCode, offence, type, isCrimeAgainsPerson, isCrimeAgainstProperty, isCrimeAgainstSociety, nameSingleData) => {
    // console.log('nameSingleData', nameSingleData)
    // console.log('isCrimeAgainsPerson', isCrimeAgainsPerson)
    // console.log('isCrimeAgainstProperty', isCrimeAgainstProperty)
    // console.log('isCrimeAgainstSociety', isCrimeAgainstSociety)
    // console.log(offenceCodes)

    // console.log(type)
    // let offenceCodes = ['36B', '36A']

    if (isCrimeAgainsPerson) {
        if (victimCode == 'L') {
            return type === 'Color' ? ErrorStyle(false) : <></>;
        } else {
            return type === 'Color' ? ErrorStyle(true) : ErrorTooltip(CrimeAgainstPersonError);
        }
    }

    if (isCrimeAgainstProperty) {
        if (victimCode != 'S') {
            return type === 'Color' ? ErrorStyle(false) : <></>;
        } else {
            return type === 'Color' ? ErrorStyle(true) : ErrorTooltip(CrimeAgainstPropertyError);
        }
    }

    if (isCrimeAgainstSociety) {
        if (victimCode == 'S') {
            return type === 'Color' ? ErrorStyle(false) : <></>;
        } else {
            return type === 'Color' ? ErrorStyle(true) : ErrorTooltip(CrimeAgainstSocietyError);
        }
    }

    // Goverment
    const GovermentCodeArr = ['26H', '521', '522', '526', '58A', '58B', '61A', '61B', '620'];
    const GovermentCodeSet = new Set(GovermentCodeArr);
    const GovermentCommanCodes = offenceCodes.filter(value => GovermentCodeSet.has(value));

    if (GovermentCommanCodes.length > 0) {
        if (victimCode === 'G') {
            return type === 'Color' ? ErrorStyle(false) : <></>;
        } else {
            return type === 'Color' ? ErrorStyle(true) : ErrorTooltip(VictimGovermentError);
        }
    } else {
        if (victimCode === 'G') {
            // return type === 'Color' ? ErrorStyle(true) : ErrorTooltip(VictimGovermentError);
        }
    }

    // policeOfficer
    const policeOfficerCodeArr = ['09A', '13B', '13B', '13C'];
    const policeOfficerCodeSet = new Set(policeOfficerCodeArr);
    const PoliceOfficerCommanCodes = offenceCodes.filter(value => policeOfficerCodeSet.has(value));

    if (PoliceOfficerCommanCodes?.length > 0) {
        if (victimCode === 'L') {
            return type === 'Color' ? ErrorStyle(false) : <></>;
        } else {
            return type === 'Color' ? ErrorStyle(true) : ErrorTooltip(policeOfficerError);
        }
    } else {
        // if (victimCode === 'L' ) {
        // return type === 'Color' ? ErrorStyle(true) : ErrorTooltip(policeOfficerError);
        // }
    }

    if (victimCode === 'L' || victimCode === 'I') {
        if (nameSingleData[0]?.Address) {
            if (victimCode === 'L' || victimCode === 'I') {
                return type === 'Color' ? ErrorStyle(false) : <></>;
            } else {
                return type === 'Color' ? ErrorStyle(true) : ErrorTooltip(ResidentStatusError);
            }
        } else {
            if (victimCode === 'L' || victimCode === 'I') {
                return type === 'Color' ? ErrorStyle(true) : ErrorTooltip(ResidentStatusError);
            }
        }
    }

    // StatutoryRape
    const StatutoryRapeCodeArr = ['36B'];
    const StatutoryRapeCodeSet = new Set(StatutoryRapeCodeArr);
    const StatutoryRapeCommanCodes = offenceCodes.filter(value => StatutoryRapeCodeSet.has(value));

    if (StatutoryRapeCommanCodes?.length > 0) {
        if (nameSingleData[0]?.AgeFrom < 18) {
            return type === 'Color' ? ErrorStyle(false) : <></>;
        } else {
            return type === 'Color' ? ErrorStyle(true) : ErrorTooltip(StatutoryRapeError);
        }
    } else {
        if (nameSingleData[0]?.AgeFrom < 18) {
            // return type === 'Color' ? ErrorStyle(true) : ErrorTooltip(policeOfficerError);
        }
    }


}

export const vectimTypeError = (victimCode, array, type = 'Color') => {
    const givencodeArr = ['45', '21', '2'];
    switch (victimCode) {
        case 'S': {
            const codeArr = ['26H', '521', '522', '526', '58A', '58B', '61A', '61B', '620'];
            const codeSet = new Set(codeArr);
            const commonValues = givencodeArr.filter(value => codeSet.has(value));
            if (commonValues.length > 0) {
                console.log('Common values found:', commonValues);
                return type === 'Color' ? ErrorStyle(false) : '';
            } else {
                console.log('No common values found.');
                return type === 'Color' ? ErrorStyle(true) : ErrorTooltip(NameVictimError);
            }
            break;
        }
        case 'G': {
            const codeArr = ['26H', '521', '522', '526', '58A', '58B', '61A', '61B', '620'];
            const codeSet = new Set(codeArr);
            const commonValues = givencodeArr.filter(value => codeSet.has(value));
            if (commonValues.length > 0) {
                console.log('Common values found:', commonValues);
                return type === 'Color' ? ErrorStyle(false) : '';
            } else {
                console.log('No common values found.');
                return type === 'Color' ? ErrorStyle(true) : ErrorTooltip(VictimGovermentError);
            }
            break;
        }
        default: return type === 'Color' ? ErrorStyle(false) : <></>;
    }
}
