import React, { memo } from "react";
import Select from "react-select";
import ClassNames from "classnames";

const RIVSModal = (props) => {
    const { openRIVSModal, setOpenRIVSModal } = props;
    const onCloseLocation = () => {
        setOpenRIVSModal(false);
    };


    const customStylesWithOutColor = {
        control: (base) => ({
            ...base,
            height: 20,
            minHeight: 35,
            fontSize: 14,
            marginTop: 2,
            boxShadow: 0,
        }),
    };

    const handleKeyDown = (e) => {
        const charCode = e.keyCode || e.which;
        const charStr = String.fromCharCode(charCode);
        const controlKeys = [8, 9, 13, 27, 37, 38, 39, 40, 46];
        const numpadKeys = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
        const numpadSpecialKeys = [106, 107, 109, 110, 111];
        if (!charStr.match(/^[a-zA-Z]+$/) && !controlKeys.includes(charCode)) {
            e.preventDefault();
        }
        if (
            (charCode >= 48 && charCode <= 57) ||
            numpadKeys.includes(charCode) ||
            numpadSpecialKeys.includes(charCode)
        ) {
            e.preventDefault();
        }
    };

    const handleSpecialKeyDown = (e) => {
        const allowedKeys = /^[a-zA-Z0-9]$/;
        const isBackspace = e.key === "Backspace";

        if (!allowedKeys.test(e.key) && !isBackspace) {
            e.preventDefault();
        }
    };
    const handleNumberKeyDown = (e) => {
        const allowedKeys = /^[0-9]$/; // Only letters and numbers

        if (!allowedKeys.test(e.key)) {
            e.preventDefault();
        }
    };

    const colourStylesEC = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fce9bf",
            // height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    return (
        <>
            {openRIVSModal ? (
                <>
                    <dialog
                        className="modal fade"
                        style={{ background: "rgba(0,0,0, 0.5)", zIndex: "200" }}
                        id="RIVSModal"
                        tabIndex="-1"
                        aria-hidden="true"
                        data-backdrop="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content modal-content-cad">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12 p-0 pb-2">
                                            <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                                                <p
                                                    className="p-0 m-0 font-weight-medium"
                                                    style={{
                                                        fontSize: 18,
                                                        fontWeight: 500,
                                                        letterSpacing: 0.5,
                                                    }}
                                                >
                                                    Resource Initiated Incident
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-1">
                                        <fieldset style={{ border: "1px solid gray" }}>

                                            <div className="tab-form-container">
                                                {/* Line 1 */}
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex align-items-center justify-content-end">
                                                        <label className="tab-form-label text-nowrap">
                                                            Vehicle Stop
                                                        </label>
                                                    </div>
                                                    <div className="col-11 tab-form-row-gap d-flex align-items-center w-100">
                                                        <div className="form-check d-flex align-items-center ">
                                                            <input className="form-check-input mb-1" type="radio" value="TransferLocation" name="AttemptComplete" id="flexRadioDefault4" />
                                                            <label className="tab-form-label" htmlFor="flexRadioDefault4">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="form-check d-flex align-items-center ">
                                                            <input className="form-check-input mb-1" type="radio" value="TransferLocation" name="AttemptComplete" id="flexRadioDefault4" />
                                                            <label className="tab-form-label" htmlFor="flexRadioDefault4">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Line 2 */}
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex align-items-center justify-content-end">
                                                        <label className="tab-form-label text-nowrap">
                                                            Assign Resource
                                                        </label>
                                                    </div>
                                                    <div className="col-11 tab-form-row-gap d-flex align-items-center w-100">
                                                        <div className="form-check d-flex align-items-center ">
                                                            <input className="form-check-input mb-1" type="radio" value="TransferLocation" name="AttemptComplete" id="flexRadioDefault4" />
                                                            <label className="tab-form-label" htmlFor="flexRadioDefault4">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="form-check d-flex align-items-center ">
                                                            <input className="form-check-input mb-1" type="radio" value="TransferLocation" name="AttemptComplete" id="flexRadioDefault4" />
                                                            <label className="tab-form-label" htmlFor="flexRadioDefault4">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Line 3 */}
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Resource #</label>
                                                    </div>
                                                    <div className="col-2">
                                                        <Select
                                                            name="resource"
                                                            styles={colourStylesEC}
                                                            isClearable
                                                            placeholder="Select..."
                                                            className="w-100"
                                                        />
                                                    </div>
                                                </div>

                                                {/* Line 4 */}
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label htmlFor="" className="new-label">
                                                            CFS :
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex tab-form-row-gap">
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            styles={colourStylesEC}
                                                            className="w-100"
                                                            name="CFSL"
                                                        />
                                                    </div>
                                                    <div className="col-6 d-flex tab-form-row-gap">
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            styles={customStylesWithOutColor}
                                                            className="w-100"
                                                            name="CFSLDetails" />
                                                    </div>
                                                    <div className="col-2 d-flex tab-form-row-gap">
                                                        <div className=" d-flex align-self-center justify-content-end">
                                                            <label
                                                                for=""
                                                                class="tab-form-label text-nowrap"
                                                            >
                                                                Priority
                                                            </label>
                                                        </div>
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            styles={customStylesWithOutColor}
                                                            className="w-100"
                                                            name="CFSLPriority"
                                                        />
                                                    </div>
                                                </div>

                                                {/* Line 5*/}
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Location</label>
                                                    </div>
                                                    <div className="col-8">
                                                        <input name="location" type="text"
                                                            className="form-control py-1 new-input" />
                                                    </div>
                                                </div>

                                                {/* Line 6 */}
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Comments</label>
                                                    </div>
                                                    <div className="col-10">
                                                        <textarea name="comments" rows="3"
                                                            className="form-control py-1 new-input"
                                                            style={{ height: "auto", overflow: "hidden" }}
                                                            onChange={(e) => {
                                                                e.target.style.height = "auto"; // Reset height
                                                                e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on scroll height
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                {/* Line 7 */}
                                                <div className="tab-from-row d-flex align-items-center">
                                                    <div className="col-2 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Vehicle Plate</label>
                                                    </div>
                                                    <div className="col-2">
                                                        <input name="location" type="text"
                                                            className="form-control py-1 new-input" />
                                                    </div>
                                                    <div className="col-1 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">State</label>
                                                    </div>
                                                    <div className="col-2">
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            styles={customStylesWithOutColor}
                                                            className="w-100"
                                                            name="CFSLPriority"
                                                        />
                                                    </div>
                                                    <div className="col-1 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Descriptions</label>
                                                    </div>
                                                    <div className="col-4">
                                                        <input name="location" type="text"
                                                            className="form-control py-1 new-input" />
                                                    </div>
                                                </div>
                                                {/* Line 8 */}
                                                <div className="tab-from-row d-flex align-items-center">
                                                    <div className="col-2 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Tag Year</label>
                                                    </div>
                                                    <div className="col-2">
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            styles={customStylesWithOutColor}
                                                            className="w-100"
                                                            name="CFSLPriority"
                                                        />
                                                    </div>
                                                    <div className="col-2 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Vehicle Plate Type</label>
                                                    </div>
                                                    <div className="col-2 d-flex align-items-center">
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            styles={customStylesWithOutColor}
                                                            className="w-100"
                                                            name="CFSLPriority"
                                                        />
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-CADprimary ml-1"
                                                        >
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* Owner's Details */}
                                                <fieldset className="tab-form-container mt-2">
                                                    <legend className="cad-legend">Owner's Details</legend>
                                                </fieldset>
                                                {/* Line 9 */}
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Owner Type</label>
                                                    </div>
                                                    <div className="col-2">
                                                        <Select
                                                            name="resource"
                                                            styles={customStylesWithOutColor}
                                                            isClearable
                                                            placeholder="Select..."
                                                            className="w-100"
                                                        />
                                                    </div>
                                                </div>
                                                {/* Line 10 */}
                                                <div className="tab-form-row">
                                                    <div className="col-12 tab-form-row-gap">
                                                        <div className="d-flex align-items-center justify-content-end">
                                                            <label className="tab-form-label text-nowrap" style={{ marginLeft: "150px" }}>
                                                                Last Name
                                                            </label>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className={ClassNames(
                                                                "form-control py-1 new-input"
                                                            )}
                                                            name="LastName"
                                                            required
                                                            onKeyDown={handleKeyDown}
                                                        />
                                                        <div className="d-flex align-self-center justify-content-end">
                                                            <label className="tab-form-label text-nowrap">
                                                                Middle Name
                                                            </label>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control py-1 new-input"
                                                            onKeyDown={handleKeyDown}
                                                            name="MiddleName"
                                                        />
                                                        <div className="d-flex align-self-center justify-content-end">
                                                            <label className="tab-form-label text-nowrap">
                                                                First Name
                                                            </label>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control py-1 new-input"
                                                            name="FirstName"
                                                            onKeyDown={handleKeyDown}
                                                        />
                                                    </div>
                                                </div>

                                                {/* Line 11 */}
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">License</label>
                                                    </div>
                                                    <div className="col-2 d-flex align-items-center">
                                                        <input name="license"
                                                            className="form-control py-1 new-input" />
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-CADprimary ml-1"
                                                        >
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </div>
                                                    <div className="col-1 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">State</label>
                                                    </div>
                                                    <div className="col-2">
                                                        <Select
                                                            name="resource"
                                                            styles={customStylesWithOutColor}
                                                            isClearable
                                                            placeholder="Select..."
                                                            className="w-100"
                                                        />
                                                    </div>
                                                </div>
                                                {/* Line 12 */}
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Address</label>
                                                    </div>
                                                    <div className="col-7">
                                                        <input name="license"
                                                            className="form-control py-1 new-input" />
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    {/* Buttons */}
                                    <div className="row">
                                        <div className="col-12 p-0">
                                            <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                                                <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                                                    <button
                                                        type="button"
                                                        className="save-button ml-2"
                                                        onClick={onCloseLocation}
                                                    >
                                                        {"Save"}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        data-dismiss="modal"
                                                        className="cancel-button"
                                                        onClick={onCloseLocation}
                                                    >
                                                        Cancel
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dialog >
                </>
            ) : (
                <> </>
            )
            }
        </>
    );
};

export default memo(RIVSModal);
